import React,{useState,useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import axios from 'axios';

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";


function UpdatePharmacyUser(props){
   const [modalShow, setModalShow] = useState(false);
  const [pharmUserDatas,setPharmUserDatas] = useState({
     name: "",
     phone: "",
     email:"",
     qualification :"",
     id: ''
    
    
 })
  
 const [error,setError]=useState({
  email: '',
  phone:"",
 });

  
const [submitMsg, setSubmitMsg]= useState("");
const [submitSuccessMsg, setSubmitSuccessMsg]= useState(""); 

const [rawPhone, setRawPhone] =useState("");
const [dialCode ,setDialCode] =useState('');
const [isValidEmail,setIsValidEmail]=useState(true);
const [isValidPhone,setIsValidPhone]=useState(true);

const {id} = useParams()
  

useEffect(()=>{
  const tokenStr= sessionStorage.getItem('usertoken');
        
        if(tokenStr){
                 
                 const v = tokenStr;
                let v2= v.replace(/["]+/g, '') ;
                 
                
                 
                 
                  
    

       axios.get(`${APIURL}/api/v1/service-provider/pharmacy-user/${id}`, { headers: {
     'Content-Type': 'application/json',
      Authorization : 'Token '+v2}})
    .then(res =>{
            

            if (res.data.status === "success") {
       
          
           setPharmUserDatas(res.data[ 'pharmacy user' ]);
           setRawPhone(res.data[ 'pharmacy user' ].phone)
           setDialCode(res.data[ 'pharmacy user' ].code)
           window.original_value = res.data[ 'pharmacy user' ];
           
          } else if (res.data.status === "error") {
            
            setSubmitMsg(res.data.message)
             
          }else {
            
            setSubmitMsg("Error in fetching data!")
             
          }


            
                
            })
     .catch(err =>{
     setSubmitMsg("Error in fetching data!")
  
  })

   
 
    }
   
},[id])

const SubmitPopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 style={{color:"green"}}> {submitSuccessMsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       {/* <Button variant="secondary" onClick={props.onHide}>Close</Button> */}
        

        <Link to="/pharmacyusers-list"><Button className='btn btn-primary btn-col' > Goto Pharmacy Users List </Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

const getChangedData = (pharmUserDatas) => {
  let objectByKeys = {};
  if (window.original_value) {
    let sda_changed_keys = Object.keys(pharmUserDatas).filter(key => pharmUserDatas[key] !== window.original_value[key]);
    sda_changed_keys.forEach(key => {
      objectByKeys[key] = pharmUserDatas[key]
    });
    delete window.original_value;
  }
  return objectByKeys;
}


	const handleSubmit=()=>{

        const tokenStr= sessionStorage.getItem('usertoken');
     
        if(tokenStr){
                 
                 const v = tokenStr;
                let v2= v.replace(/["]+/g, '') ;
                 
                
                 
                
                let dataToSend = getChangedData(pharmUserDatas);
                 
    


 
       axios.put(`${APIURL}/api/v1/service-provider/pharmacy-user-alter/${id}/`, dataToSend, { headers: {
     'Content-Type': 'application/json',
      Accept: "application/json",
      Authorization : 'Token '+v2}})
    .then(res =>{
            

            if (res.data.status === "success") {
            setSubmitSuccessMsg(res.data.message);
             setModalShow(true);

          } else if (res.data.status === "error") {
         
              setSubmitMsg(res.data.message);
          }
          else {
         
              setSubmitMsg("Error in data update!");
          }
            
                
            })
     .catch(err =>{
   setSubmitMsg("Error in data update!");
   
  })

   
 
    }
    

   

	}

  const handleCheckEmail = (e)=> {
    setIsValidEmail(true);
    if(e.target.value!== ""){
           setError(currentstate=>({
                  ...currentstate,
                email:  ''}));
      e.preventDefault();
      let key=e.target.name;
    
    
        const data = {
                [e.target.name]: e.target.value
            }
         
              axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
         'Content-Type': 'application/json'}})
        .then(res =>{
              
                if(res.data.status === "success"){
                  if(res.data.is_existing_user === true){
                 
                        setError(currentstate=>({
                  ...currentstate,
                [key]: `This ${key} is already registered!`}));
                      setIsValidEmail(false);
                     }
                       else{
            
                  setIsValidEmail(true);
                      
                }
                      
                        
                    }
                else{
            
                  setIsValidEmail(true);
                      
                }
                    
                })
        .catch(err =>{
      
        
      })
    }
  }


  const handleCheckExistingPhone= (e)=>{
    setIsValidPhone(true);
if(e.target.value !==""){
 
  setError(currentstate=>({
             ...currentstate,
           phone: ''}));

 

   const data = {
           code: dialCode,
           mobile_number: rawPhone
       }
      
         axios.post(`${APIURL}/api/v1/account/check-user/`, data, { headers: {
    'Content-Type': 'application/json'}})
   .then(res =>{
        
           if(res.data.status === "success"){
             if(res.data.is_existing_user === true){
              
                 setError(currentstate=>({
             ...currentstate,
           phone: "This Phone Number is already registered!"}));
                  setIsValidPhone(false);
                }
                 else{

             setIsValidPhone(true);
                 
           }
                
                  
                   
               }
           else{

             setIsValidPhone(true);
                 
           }
               
           })
   .catch(err =>{

   
 })

}
 

}

  const handleContinue= async (e)=>{

    e.preventDefault();
   
    if(validate() && isValidEmail && isValidPhone){
      handleSubmit();
    }

}

const handleChange= e=>{
      e.preventDefault();
      const val=e.target.value;
      const key=e.target.name;
    
         setPharmUserDatas(currentstate=>({
            ...currentstate,
            [key]: val
          }));
      
}

const handleQualification=e=>{
  e.preventDefault();
  const val=e.target.value;
      const key=e.target.name;
      let arr=[];
      if(val && val.includes(',')){
        arr = val.split(',');
      }
      else{
      arr.push(val.toString);
      }
      setPharmUserDatas(currentstate=>({
        ...currentstate,
        [key]: arr.toString()
      }));
   
}
const handlePhoneInput= (countryCode,value, data, event, formattedValue)=>{
  
 
      // setRawPhone(value.slice(data.dialCode.length));
      // setDialCode(value.slice(0,data.dialCode.length))
      setPharmUserDatas(currentstate=>({
        ...currentstate,
           phone: value,
           code:countryCode

                       }));
  
  

 }

 const validate = ()=> {

  let input = pharmUserDatas
  let errors = {};
  let isValid = true;
  setError({});

  if (!input["name"] || input["name"]==="" ) {
    isValid = false;
    errors["name"] = "Please enter first name";
  }


  if (!input['qualification'] || input['qualification'].length===0 || input['qualification'][0]==="") {
    isValid = false;
    errors["qualification"] = "Please enter qualification";
  }

  if (!input['phone'] || input['phone']==="") {
    isValid = false;
    errors["phone"] = "Please enter phone number";
  }

  if (typeof input["email"] !== "undefined") {
      
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = "Please enter a valid email address.";
    }
  }

  if (typeof input["name"] !== "undefined") {
    var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
    if (pattern1.test(input["name"])) {
      isValid = false;
      errors["name"] = "Special Characters are not allowed";
    }
  }

  if (!input["email"]) {
    isValid = false;

    errors["email"] = "Please enter email Address.";
  }
  
  if(isValidPhone===false){
    isValid = false;
    errors["phone"] = "Phone number already registered!";
  }
  if(isValidEmail===false){
    isValid = false;
    errors["email"] = "Email already registered!";
  }

  setError(currentstate=>({
    ...currentstate,
    ...errors
  }));


return isValid;

 }
  

	return(<div className="add-dr-page" >



    <div className="add-dr-form-holder">
    
    
     <div className="quick-links">
      <Link to="/pharmacyusers-list"><button type="button" className="btn btn-primary btn-col dr-btn btn-custom"> Goto Pharmacy Users List </button></Link>

       </div>
		<form className="add-dr-form" onSubmit={handleContinue}>
		<h4 className="title-of-page"> Update User details</h4>
    <div className="add-dr-div">
		   <div className="form-group col-lg-6">
           <label > *Full Name: </label>
          <input className="form-control" type="text" maxLength='30' name="name" value={pharmUserDatas.name} onChange={handleChange} />
          {error.name ? <div style={{zIndex:'auto'}} className="error-validation-msg">{error.name}</div> : null}
        </div>
       
        <div className="form-group col-lg-6">
         <label> *Email: </label>
          <input className="form-control " type="email" maxLength='50' value={pharmUserDatas.email} name="email" onChange={handleChange} onBlur={handleCheckEmail} />
          {error.email ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.email}</div>: null}
        </div>
        <div className="form-group col-lg-6">
            <label> *Phone Number: </label>
           {/*  <input className="form-control " type="text" value={doctorDatas.phone} name="phone" onChange={handleChange} required/>
           */}
 {/* value={this.state.phone}  */}

   <PhoneInput
                inputProps={{
                                                  
                                                  
                      }}
                    defaultCountry='in' 
                 countryCode={pharmUserDatas.code}
                  value = { pharmUserDatas.phone}
                  onChange={handlePhoneInput}
                  onBlur={handleCheckExistingPhone}
                 
                                          />
          {error.phone ? <div style={{zIndex:'auto'}} className="error-validation-msg"> {error.phone}</div> : null}                      

        </div>


        
        <div className="form-group col-lg-6">


          <label> *Qualification: </label>
          <input className="form-control" type="text" maxLength='150' value={pharmUserDatas.qualification} name="qualification"  onChange={handleQualification} />
          {error.qualification ? <div style={{zIndex:'auto'}} className="error-validation-msg">{error.qualification}</div> : null}
        </div>
       
        
       

        
       



        
      </div>
        <button type="submit" className="btn btn-primary btn-col"> Submit Data</button> 
		</form>





    {submitMsg!=="" && submitMsg!==null?
     <h5 style={{ color: "red" }}>
            
            {submitMsg}
          </h5> : 
          null
   }

 {submitSuccessMsg!=="" && submitSuccessMsg!==null?
      <SubmitPopUp
        show={modalShow}
        onHide={() => {setModalShow(false);
                       setSubmitSuccessMsg("")}}
      /> : 
          null
   }
    

    
    </div>
    </div>
		)
}

export default UpdatePharmacyUser;
