import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import './userprofile.css';

import { Card, Button, Modal } from 'react-bootstrap'
import { Link, useParams, useNavigate } from 'react-router-dom';

function HospitalDetails() {
  const { id } = useParams(); //

  const [img, setImg] = useState()
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [mail, setMail] = useState('');
  const [location, setLocation] = useState('');
  const [state, setState] = useState('')
  const [number, setNumber] = useState('')
  const [facilities, setFacilities] = useState([])
  const [country, setCountry] = useState('');
  const [website, setWebsite] = useState('');
  const [acc, setAcc] = useState('');
  const [year, setYear] = useState('');
  const [phone, setPhone] = useState('');
  const [emer, setEmer] = useState('');
  const [prMail, setPrMail] = useState('');
  const [regNum, setRegNum] = useState('');
  const [type, setType] = useState('');
  const [street, setStreet] = useState('');
  const [deliveryRadious, setDeliveryRadious] = useState('');

  const [approveShow, setApproveShow] = useState(false);
  const [approveFail, setApproveFail] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [rejectFail, setRejectFail] = useState(false);
  const [approveMsg, setApproveMsg] = useState('');
  const [approveFailMsg, setApproveFailMsg] = useState('');
  const [rejectMsg, setRejectMsg] = useState('');
  const [rejectFailMsg, setRejectFailMsg] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [modalShow, setModalShow] = useState(false)
  const [errorShow, setErrorShow] = useState(false)

  let navigate = useNavigate();


  useEffect(() => {


    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    axios.get(`${APIURL}/api/v1/staff/hospital-profile/?hospital_id=${id}`, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          const hospData = res.data.hospital_profile
          setImg(hospData.photo);
          setName(hospData.name);
          setDesc(hospData.description)
          setMail(hospData.email)
          setLocation(hospData.location)
          setState(hospData.state)
          setNumber(hospData.mobile_number)
          setCountry(hospData.country)
          setWebsite(hospData.website)

          setAcc(hospData.accreditation)
          setYear(hospData.established_year)
          setPhone(hospData.contact_phone)

          setPrMail(hospData.primary_email)
          setRegNum(hospData.reg_number)
          setType(hospData.health_care_type)
          setDeliveryRadious(hospData.delivery_radius)
          setStreet(hospData.street)

          if (hospData.emergency_number === null) {
            setEmer('Not Available')
          }
          else {
            setEmer(hospData.emergency_number);
          }

          const facility = hospData.facilities.join(', ');
          setFacilities(facility)
          // const specializations =hospData.specializations.join(', ');
          //setSpecs(specializations);
        }
        else {
          setErrorShow(true)
        }
      })
      .catch((err) => {
        setErrorShow(true)
      })
  }, [])

  const handleApprove = () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    const data = {
      hospital_id: id
    }
    axios.post(`${APIURL}/api/v1/staff/hospital-approve/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          setApproveMsg('success')
          setApproveShow(true)
        }
        else {
          setApproveFailMsg('Failed to submit data')
          setApproveFail(true)
        }
      })
      .catch((err) => {
        setApproveFailMsg('Failed to submit data')
        setApproveFail(true)
      })

  }

  const handleReject = () => {
    const id = sessionStorage.getItem('id')

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    const data = {
      // hospital_id: id,
      status: 'rejected'
    }
    axios.post(`${APIURL}/api/v1/staff/hospital-approve/?hospital_id=${id}`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          setRejectMsg('succesfully rejected')
          setRejectShow(true)
        }
        else {
          setRejectFailMsg('failed to reject')
          setRejectFail(true)
        }
      })
      .catch((err) => {
        setRejectFailMsg('failed to reject')
        setRejectFail(true)
      })

  }

  // const getTime =()=> {
  //   let data = time;
  //   const date = new Date(time)
  //   let dateUpdated = date.toString()
  // }

  const modalHandle = () => {
    setModalShow(false)
  }

  const ConfigPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* <ConfigPayment id={sessionStorage.getItem('id')} handle={modalHandle}/> */}

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn btn-primary btn-col' onClick={props.onHide}> X Close</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ApprovalPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Service Provider approved Successfully</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={() => navigate("/hospitallist")}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ApprovalFail = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ color: "red" }}> Error in Data Submission. Please try again! </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const RejectPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Hospital rejected Successfully</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const RejectFail = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ color: "red" }}> Error in Data Submission. Please try again! </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 id="confirm-reject">Are you sure?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={props.onReject}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ color: "red" }}> Error in Data Retrieval. Please try again! </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  return (<>


    <div className="padding ">
      <h4 className="title-of-page" >Service Provider Details</h4>
      <div className='card-length'>
        <div className="mb-2" bg='info' border='success' style={{ width: '25rem', height: '40rem' }}>
          <img variant="top" src={img} alt='Profile Picture' className="img-radius" />
          <div className='hospdetails'><br />
            <div style={{ fontSize: '150%' }}><h4 className="title-of-tasks" >{name}</h4></div>
            <div>
              <p className="lead text-justify">{desc ? desc : <p className='lead text-justify'>Description of Service Provider is not available at the moment</p>}</p>
            </div>


          </div>
        </div>
      </div>
      <div className=" check-class">
        <div className="card-block">


          <div className="row">
            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">ID :  <span className='text-info span-size'>{id}</span></p>
              <p></p>
            </div>
            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Registration Number : <span className='text-info span-size'>{regNum ? regNum : 'Not Available'}</span> </p>
              <p></p>
            </div>

            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Health Care Type : <span className='text-info span-size'>{type === '' ? 'Not Available' : type}</span></p>
              <p></p>
            </div>

            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Established Year : <span className='text-info span-size'>{year === null ? 'Not Available' : year}</span></p>
              <p></p>
            </div>


            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Email : <span className='text-info span-size'>{mail === null ? 'Not Available' : mail}</span></p>
              <p></p>
            </div>

            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Location : <span className='text-info span-size'>{street}, {location}, {state}, {country}</span></p>
              <p></p>
            </div>

            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Phone Number : <span className='text-info span-size'>{number === '' ? 'Not Available' : number}</span></p>
              <p></p>
            </div>

            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Emergency Number : <span className='text-info span-size'>{emer === null ? 'Not Available' : emer}</span></p>
              <p></p>
            </div>

            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Facilities : <span className='text-info span-size'>{facilities === null ? 'Not Available' : facilities}</span></p>
              <p></p>
            </div>
            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Delivery Radius : <span className='text-info span-size'>{deliveryRadious === null ? 'Not Available' : deliveryRadious}</span></p>
              <p></p>
            </div>

            <div className="col-sm-6">
              <p className="m-b-10 f-w-600 p-size text-left">Website : <span className='text-info span-size'>{website === '' ? 'Not Available' : website}</span></p>
              <p></p>
            </div>

          </div>
        </div>
        <br /><br />

        <div>
          {/* <button style={{ marginTop: '-30px' }} onClick={() => setConfirm(true)} className="btn btn-danger">Reject</button>{'  '} */}
          <button style={{ marginTop: '-30px' }} onClick={handleApprove} className="btn btn-primary btn-col">Approve</button>
        </div>


      </div>

      {
        approveMsg !== '' ?
          <ApprovalPopUp
            show={approveShow}
            onHide={() => { setApproveShow(false); setApproveMsg(''); }} /> : null
      }

      {
        approveFailMsg !== '' ?
          <ApprovalFail
            show={approveFail}
            onHide={() => { setApproveFail(false); setApproveFailMsg(''); }} /> : null
      }

      {
        rejectMsg !== '' ?
          <RejectPopUp
            show={rejectShow}
            onHide={() => { setRejectShow(false); setRejectMsg(''); }} /> : null
      }

      {
        rejectFailMsg !== '' ?
          <RejectFail
            show={rejectFail}
            onHide={() => { setRejectFail(false); setRejectFailMsg(''); }} /> : null
      }

      {
        confirm ?
          <ConfirmPopUp
            show={confirm}
            onHide={() => { setConfirm(false) }}
            onReject={() => { handleReject(); setConfirm(false) }} /> : null
      }

      {
        modalShow ?
          <ConfigPopUp
            show={modalShow}
            onHide={() => {
              setModalShow(false)
            }} />
          : null
      }

      {
        errorShow ?
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
            }} />
          : null
      }


    </div>
  </>

  )
}


export default HospitalDetails
