import React , {useState ,useEffect ,useContext} from 'react'
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import axios from "axios";
import {APIURL} from "../../Global";
//import {useHistory} from 'react-router-dom'
import {Button ,Modal ,Card ,ListGroup ,ListGroupItem} from 'react-bootstrap';
import '../../modules/css/bootstrap.min.css';
import '../../modules/css/iofrm-style.css';
import '../../modules/css/iofrm-theme4.css';
import "./popup.css";
import "./country_region_selector.css";
import "./dob.css";
import './hospprofile.css'
import EditHospProfile from './EditHospProfile';
import {ProfileContext} from '../contexts/ProfileContext'
//import VerifyOtp from '../VerifyOtp/VerifyOtp';
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
function HospProfile() {

    const [img, setImg] =useState('');
    const [practise, setPractise]=useState();
    const [location,setLocation]=useState();
    const [email,setEmail]=useState(null);
    const [name,setName] = useState('');
    const [spec,setSpec] = useState([]);
    const [mobile, setMobile]= useState();
    const [editShow, setEditShow]= useState(false);
    const [website, setWebsite]= useState(false)
    const [facility, setFacility]= useState([])
    const [desc, setDesc]= useState('')
    const [verifyShow ,setVerifyShow] =useState(false)
    const [verified,] =useState()
    const [render, setRender]= useContext(ProfileContext)
    const [refresh ,setRefresh] =useState(false)
    const [user,setUser]= useState('')
    const [otpShow, setOtpShow]= useState(false)
    const [otpFail, setOtpFail]= useState(false)
    const [disable, setDisable]= useState(false)

   // const history =useHistory();

//const [keys,] = useState({'d': false,'a': false,'b': false,'o': false,'n': false,'s': false,'l': false,'p': false,'c': false,'h': false});


// useEffect(()=>{

// window.onkeydown =(e) => {
 
//  keys[e.key]=true

//  if(e.altKey && keys['d']){
//    history.push('/doctors-list')
//  }
//  if(e.altKey && keys['a']){
//    history.push('/hospital/add')
//  }
//  if(e.altKey && keys['b']){
//    history.push('/bdo-list')
//  }
//  if(e.altKey && keys['o']){
//    history.push('/hospital/add-bdo')
//  }
//  if(e.altKey && keys['n']){
//    history.push('/emergency-number')
//  }
//  if(e.altKey && keys['s']){
//    history.push('/service-request-list')
//  }
//  if(e.altKey && keys['l']){
//    history.push('/license-details')
//  }
//  if(e.altKey && keys['p']){
//    history.push('/license-payment')
//  }
//  if(e.altKey && keys['c']){
//    history.push('/hosp-calllog')
//  }
//  if(e.altKey && keys['h']){
//    history.push('/payment-history')
//  }

// }
// window.onkeyup=(ev)=> {
 
//  keys[ev.key]= false
// }

// return()=>{
//  window.onkeydown=null
//  window.onkeyup=null
// }
// },[]);

    const handleEditPopUp =(e) => {
      debugger;
        e.preventDefault();
        setEditShow(true)
    }

    useEffect(()=>{
      debugger;
        const tokenString= sessionStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '')                           
          
         
          axios.get(`${APIURL}/api/v1/service-provider/hospital-profile/`, { headers: {
            
            "Authorization" : 'Token '+str  }})
          .then(res=>{
              
            if(res.data.status==="success"){
              const hospData= res.data.hospital_profile
             // setFacility(hospData.facilities.join())       
            //setSpec(hospData.specializations.join())

            setPractise(hospData.practise_type)
            setImg(hospData.photo)
            setName(hospData.name)
            setLocation(hospData.location)
            setMobile(hospData.mobile_number)                
            setEmail(hospData.email)
            setWebsite(hospData.website)
            setDesc(hospData.description)
            setUser(hospData.username)
            debugger;
            }
      
      
          })
          .catch(err=>{
            debugger;
      
      })
      
      
      },[refresh]);


      const verifyMail = () => {
        // const tokenString= localStorage.getItem('usertoken');
             
        //      let str= tokenString.replace(/["]+/g, '')
    
        //   axios.get(`${APIURL}/api/v1/account/email-verification/`,{headers : {
        //     "Authorization" : 'Token '+str  }})
        //     .then(res => {
    
        //       if(res.data.status==="success"){
    
        //         setVerifyShow(true)
        //       }
        //     })
      }

      const handleReset = (e) => {
        e.preventDefault();
        setDisable(true)
        const pw = sessionStorage.getItem('pw')

        const data = null

        const tokenString= sessionStorage.getItem('usertoken');
        let str= tokenString.replace(/["]+/g, '');        

       axios.post(`${APIURL}/api/v1/account/reset-password/?username=${user}&password=${pw}`, data , { headers: {
         
        "Authorization" : 'Token '+str,
        'Content-Type': 'application/json' }
   
   
       })
   
       .then(res=>{
        
         if(res.data.status=== "success"){
            setDisable(false)
            setOtpShow(true)
         }
         else{
            setDisable(false)
            setOtpFail(true)
               }
                   
               })
        .catch(err =>{
          setDisable(false)
          setOtpFail(true)
     })
      }


      const VerifyPopUp = ({onHide}) => {
        return(
        <Modal
            show
            onHide
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 id="confirm-reject">Verification Link sent to Email</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }

      const modalHandle = () => {
        setOtpShow(false)
      }
      const OtpPopUp = ({onHide}) => {
        return(
        <Modal
            show
            onHide
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <VerifyOtp username= {user} mail = {email} handle={modalHandle} /> */}
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={onHide}>Close</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }

      const OtpFailPopUp = ({onHide}) => {
        return(
        <Modal
            show
            onHide
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-danger'>Error in Data Submission.Please Try Again.</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={onHide}>Ok</Button>          
      
            </Modal.Footer>
          </Modal>
        )
      }

      const EditPopUp = (props) => {
        debugger;
          return(
            <Modal
            {...props}
            aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog1"
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body1">

       <EditHospProfile specs={spec} show={editShow} image={img} mail={email} location={location} />
       
      </Modal.Body>
      <Modal.Footer>
            
         <Button className='btn btn-primary btn-col' onClick={props.onHide}> X Close</Button>   

      </Modal.Footer>
    </Modal>
          )
      }

    return (
      <>
      <div>
    <HospitalNavbar/></div>
    <div style={{marginLeft: '23%', display:'flex', marginRight:'21%', width:'auto'}}>
        <Card className="mb-2" bg={'light'} border='light' style={{ width: '50rem' ,height: 'auto' }}>
    <div >
        <div >
            <Card.Img style={{width: 200,
                height: 200,
                maxWidth: '100%',
                borderRadius: '50%'}} variant="top" src={img} alt="Profile Photo"  />
            
        </div>
        <hr/>
    </div>
  <Card.Body>
    <Card.Title style={{fontSize:'200%'}}>Profile</Card.Title>
    <ListGroup className="list-group-flush">
    <ListGroupItem>Hospital Name : {name}</ListGroupItem>
    <ListGroupItem>Email : {email}<label onClick={verifyMail} style={{color:'blue', cursor: 'pointer'}}>{ verified===false && email !=='' ? <p>(verify)</p> :'' }</label></ListGroupItem>
    <ListGroupItem>Location : {location}</ListGroupItem>
    <ListGroupItem>Mobile Number : {mobile}</ListGroupItem>
    {/* <ListGroupItem>Specialization : {spec}</ListGroupItem>
    <ListGroupItem>Facilities : {facility}</ListGroupItem> */}
    <ListGroupItem>Practice Type : {practise}</ListGroupItem>
    <ListGroupItem>Website : {website}</ListGroupItem>
    <ListGroupItem>Description : {desc}</ListGroupItem>
  </ListGroup>
     <Button style={{margin:'1rem'}} onClick={handleEditPopUp}className='btn btn-primary btn-col'>Edit Profile</Button>
    {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button>  */}
  </Card.Body>
</Card>
            
        

        {
            editShow ? 
        <EditPopUp
            show={editShow}
            onHide= { ()=> {setEditShow(false)
                            setRefresh(!refresh)
                            setRender(!render)}} />
            : null
        }

        {
            verifyShow ? 
        <VerifyPopUp
            show={verifyShow}
            onHide= { ()=> {setVerifyShow(false)
                           }} />
            : null
        } 

        {
            otpShow ? 
        <OtpPopUp
            show={otpShow}
            onHide= { ()=> {setOtpShow(false)
                           }} />
            : null
        }   

{
            otpFail ? 
        <OtpFailPopUp
            show={otpFail}
            onHide= { ()=> {setOtpFail(false)
                           }} />
            : null
        }  
    </div>
    </>

    
    )
}

export default HospProfile
