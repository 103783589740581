import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL } from "../../Global";
import { Button } from 'react-bootstrap';
function EditSelectedService({ onSuccess,amount,vat,id }) {
    
    const [newAmount, setNewAmount] = useState(amount)
    const [newvat, setNewVat] = useState(vat)
    const [error, setError] = useState("")

  


    
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        if (key === "newAmount") {
            setNewAmount(val);
        }
        if (key === "newVat") {
            setNewVat(val);
        }
           
       
    };
    const editService = (e) => {
        e.preventDefault();


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */

        const dataToSend = {
            amount: newAmount,
            vat:newvat,

        }

        axios
            .put(`${APIURL}/api/v1/service-provider/hospital-service-detail/${id}/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {

                    onSuccess();
                    //setRefresh(true)
                    //setCompleteShow(true)
                    //setSuccessShow(false)
                } else {
                    setError(res.data.message)
                }
            })
            .catch((err) => {
                //setErrorShow(true)
            });
        // setSuccessShow(false)






    }

    return (
        <div className="appForm" >
            <div className="">

                <div className="formdata1"><h2 className="name">Edit Service</h2></div>
                <form className="formFields" onSubmit={editService}>

                    <div className="formField">
                    <label>Amount: </label>
                        <input
                            type="number"
                            className="formFieldInput"
                            name="newAmount"
                            value={newAmount}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="formField">
                    <label>VAT: </label>
                    <input
                            type="number"
                            className="formFieldInput"
                            name="newVat"
                            value={newvat}
                            onChange={handleChange}
                            required
                        />
                    </div>
                   
                    <div className="formField" style={{marginLeft: "30%"}}>
                        <button className=" btn formFieldButton">Save Changes</button>
                        {error ? (
                            <div className="error-validation-msg">
                                {error}
                            </div>
                        ) : null}




                    </div>


                </form>

            </div>


        </div>

    )
}
export default EditSelectedService