import React, { useState, useEffect } from 'react';
import ReactTable from "react-table-v6";
 import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from 'react-bootstrap';
import AddNewService from './AddNewService';
const SubServiceTable = ({ id }) => {
    const [addServiceShow, setAddServiceShow] = useState(false)
    const [subServiceList, setSubServiceList] = useState([])
    const [subid, setSubId]=useState(0)
    const [serviceName,SetServiceName]=useState(" ")

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");


        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        axios.get(`${APIURL}/api/v1/service-provider/sub-services/?main_service=${id}`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    const datas = res.data.sub_services

                    setSubServiceList(datas)

                }
                else {
                   
                }
            })
            .catch((err) => {
               
            })


    }, [])

    const AddServicePopUp = (props) => {
        return (
            <Modal 
                {...props}
                aria-labelledby="example-custom-modal-styling-title"
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-medium"
            >  <Modal.Header closeButton>
                
                    <Modal.Title id="contained-modal-title-vcenter">


                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <AddNewService mainid={id} id={subid} servicename={serviceName} />


                </Modal.Body>

            </Modal>
        )
    }

    const columns = [
       
      
        {
            
            accessor: 'services',
            minWidth: 800
        },
        {
           
            accessor: 'addservice',
            minWidth: 120
        },


    ]
      const handleAddService = (subid,servicename) => {
       setSubId(subid);
       SetServiceName(servicename);
       setAddServiceShow(true);
    };
    
    const getServiceCol = (subServiceList) => {
        return subServiceList.map(item => {
            return {
              
                id: item.id,
                services: item.sub_service,
                addservice:<div><i className="fa fa-arrow-right" style={{ float: "right",  color: "#6788BC  " }}aria-hidden="true" onClick={() => handleAddService(item.id,item.sub_service) }></i></div>
               

            }
        })
    }

    const data = getServiceCol(subServiceList);

    return (<>
        <div id="patient-presc-page" className="Appform" style={{ width: '100%' }}><br />

            <div className="patient-presc-sections patient-presc-sections2">
                <div className="table-holder">
                    <div>
                        <ReactTable responsive
                         style={{
                        color:"#259BE9",
                        fontSize:"20px"}}
                            minRows={1}
                            data={data}
                            columns={columns}
                            showPaginationBottom={false}
                        />
                    </div>
                </div>

            </div>

        </div>
         {
            addServiceShow ?
                <AddServicePopUp
                    show={addServiceShow}
                    onHide={() => setAddServiceShow(false)}

                /> : null
        }

       </>
    );
}


export default SubServiceTable;