import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import "./footer.css";
import { useTranslation } from "react-i18next";
import {logo} from "../../assets/images/icons/cp_logo.jpg";



function Footer() {
  const [backend, setBackend] = useState(null);
  const [webapp, setWebapp] = useState(null);
  const { t } = useTranslation();

  useEffect(()=>{
      
  
 
  
    axios.get(`${APIURL}/api/v1/account/app-config/`)
    .then(res=>{
     if(res.data.status==="success"){

   
     
    setBackend(res.data.backend_version);
       setWebapp(res.data.webapp_version);
      }

        else{
         

      }
      


    })
    .catch(err=>{

 
})


},[]);

 
  return (
    <footer> 
       <div className="flex-row footer-row">

   

<div className="flex-column-footer">

 

  <h6 className="text-muted1">3 Doc </h6>

  <h6 className="text-muted">

  Centerpoint health care private limited

  </h6>

  <h6 className="text-muted"> No.30/358, Aradhana,</h6>
   <h6 className="text-muted"> Kovoor in the Kozhikode, Kerala,</h6>
   <h6 className="text-muted"> India</h6>

</div>

<div className="flex-column-footer">
{/* <img className="logo-img" src={logo} alt="Logo" /> */}
<h6 className="">{t("Contact")} </h6>

<h6 className="text-muted">

  <a

    href="tel:+91 75610 57363"

    target="_blank"

    rel="noopener noreferrer"

    aria-label="contact 1"

  >

    <i className="fa fa-mobile mob-icon" aria-hidden="true"></i>

    <span className="mob"> +91 75610 57363 </span>

  </a>

  <br />



  <a

    href="tel:+91 9731066055"

    target="_blank"

    rel="noopener noreferrer"

    aria-label="contact number 2"

  >

    <span className="mob2"> +91 9731066055 </span>

  </a>

</h6>
</div>

</div>
<div className="flex-row footer-icons-row">
<a

aria-label="linkedin"

href="https://www.linkedin.com/company/intpurple/"

target="_blank"

rel="noopener noreferrer"

>

<i className="fab fa fa-linkedin social-media-icons"></i>

</a>



<a

aria-label="mail id"

href="mailto:Contact@IntPurple.com"

target="_blank"

rel="noopener noreferrer"

>

<i className="fas fa fa-envelope social-media-icons"></i>

</a>



<a

aria-label="fb"

href="https://www.facebook.com/IntPurpleTech"

target="_blank"

rel="noopener noreferrer"

>

<i

  className="fab fa fa-facebook social-media-icons"

  aria-hidden="true"

></i>

</a>

<a

href="https://www.intpurple.com"

target="_blank"

rel="noopener noreferrer"

aria-label="website"

>

<i className="fa fa-globe social-media-icons"></i>

</a>
<span style={{float:"right"}}>

Powered By   <a
href="https://felixacare.com"
target="_blank"
rel="noopener noreferrer"
aria-label="website link"
>
 FelixaCare 
</a>
</span>
</div>


<div className="flex-row copy-info">

 Copyright&copy; 2022 IntPurple Technologies LLP.
<span style={{float:"right"}}>{backend !== null && webapp !== null ? (







<h6>

  Ver: F{backend}/WV1.S2.0.0</h6>



) : null}
</span>
</div>




 
       



    </footer>
  );
}

export default Footer;