import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


import "./hospitalnavbar.css";

function HospitalNavbar() {
  const { t } = useTranslation();
  return (
    <nav className="hosp-navbar">
      {/*<img
        src="http://intpurple.com/wp-content/uploads/2020/04/logo_small.png"
        className="logo"
        alt="logo"
      /> */}

      <div className="btns">
        <button className="btn nav-btn">
          <NavLink
            exact
            className="inactive"
            activeClassName="active"
            to="/hospital"
          >
            {" "}
            <i className="fas fa-home"></i> {t("HOME")}
          </NavLink>
        </button>
        <button className="btn nav-btn">
          <NavLink
            className="inactive"
            activeClassName="active"
            to="/doctorslist"
          >
            {t("DOCTORS LIST")}{" "}
          </NavLink>
        </button>
        <button className="btn nav-btn">
          <NavLink className="inactive" activeClassName="active" to="/bdo-list">
            {" "}
            {t("BDO LIST")}{" "}
          </NavLink>
        </button>
        <button className="btn nav-btn">
          <NavLink className="inactive" activeClassName="active" to="/sda-list">
            {" "}
            {t("SDA LIST")}{" "}
          </NavLink>
        </button>

        {/* <button className="btn login-btn">
          <Link to="/login">Login </Link>
        </button>
        <button className="btn">
          <Link to="/register"> Register </Link>
        </button> */}
      </div>
    </nav>
  );
}

export default HospitalNavbar;
