import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import LabUser from './LabUser';
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';
import "./labuserlist.css";
import axios from 'axios';
import { APIURL } from "../../Global";

function LabUserList() {
  const [labUsersList, setLabUserList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState()
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false)





  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");


    axios
      .get(`${APIURL}/api/v1/service-provider/lab-user/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Token " + v2
        },
      })
      .then((res) => {

        const datas = res.data;


        setLabUserList(datas);

      })
      .catch((err) => {
        setErrorMsg("error");
      });
  }, [refresh]);

  const refreshHandle = () => {
    setRefresh(!refresh)
  }

  return (<div className="lablist-page">
<HospitalNavbar/>
    <br />


    <h2 className="title-of-page"> Lab Users List </h2>
    <div className="quick-links">
      <Link to="/add-labuser"><button type="button" className="btn btn-primary btn-col lab-btn"> + Add Lab User to List </button></Link>

    </div>

    {labUsersList && labUsersList.length !== 0 ? (<div>
      <div className="drs-container">
        {labUsersList.map((data, index) => {
          return <LabUser key={index} data={data}  handle={refreshHandle} />
        })}
      </div>

      <div className="quick-links">


      </div>

    </div>)
      :
      <h5 style={{ color: "red", margin: "20px" }}>Lab Users List is empty </h5>
    }

    {errorMsg === "error" ?
      <h5 style={{ color: "red" }}>

        Error in data fetching.Please try again!
      </h5>
      :
      null}
  </div>
  );
}

export default LabUserList;