import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { APIURL } from "../../Global";
import Pagination from "react-js-pagination";
import "./pagination.css";
import SubServiceTable from "./SubServiceTable";
import { useNavigate } from "react-router-dom";

function MasterServices() {
    const [serviceList, setServiceList] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage,] = useState(10);
    const [expand, setExpand] = useState()
    let navigate = useNavigate();


    const handleDetail = (index) => {
        if (expand !== index) {
            setExpand(index)
        }
        else {
            setExpand()
        }
    }


    const loadData = () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        axios
            .get(`${APIURL}/api/v1/service-provider/main-services/`, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {


                if (res.data.status === "success") {

                    const datas = res.data.main_services;

                    setServiceList(datas);

                }


                else {

                }
            })
            .catch((err) => {

            });
    }

    useEffect(() => {
        loadData();


    }, [])

    const onSuccess = () => {
        loadData();

    }





    const handleSearchChange = (e) => {
        e.preventDefault();
        setCurrentPage(1)
        setSearchTerm(e.target.value)
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }


    let data = serviceList ? serviceList.sort((a, b) => {
        if (a.service_name.toLowerCase() < b.service_name.toLowerCase()) return -1;
        if (a.service_name.toLowerCase() > b.service_name.toLowerCase()) return 1;
        return 0;
    })
        .filter((value) => {
            if (searchTerm === '') {
                return value
            } else if (value.service_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                return value
            }
        }) : [];


    const indexLast = currentPage * perPage;
    const indexFirst = indexLast - perPage;
    const current = data.slice(indexFirst, indexLast);
    const total = data.length;

    const pageNumbers = []
    for (let i = 0; i <= Math.ceil(total / perPage); i++) {
        pageNumbers.push(i)
    }
    const totalPages = pageNumbers.length;

    let display = current.length > 0 ? current.map((data, index) => {


        return (

            <div className='list-item'>

                {" "}
                <h4 className="each-list-link">
                    <Link to="#" style={{ textDecoration: 'none' }} className={expand === index ? 'feature-data mt-3' : 'feature-data'} onClick={() => handleDetail(index)}> <i className="fa fa-plus-square-o" style={{ color: "#5DADE2 " }} ></i>
                        <span>&nbsp;&nbsp;</span>{data.service_name}{" "}
                    </Link>
                </h4>

                {
                    expand === index ? <>
                        <div className='seperate-content' />
                        <div className='row-of-features'>
                            <div className='col-of-features'>
                                <SubServiceTable id={data.id} />

                            </div>


                        </div>

                    </>
                        : null
                }

            </div>

        )
    }) : null
    return (
        <>
            <div>

                <>
                    <div className="container">
                        <div className="container failedlist-parentcontainer">

                            <div className="title-of-page"><h2 className="name">Master Services List</h2></div>
                            <button className="btn btn-primary btn-col" style={{ marginLeft: "80%" }} onClick={() => navigate("/selected-services")}>
                                Services</button>
                            <br />
                            <br />
                             <div className="search-section">
  
       
                                 <div className="search-div">
                                <form style={{ display: "flex" }}>


                                    <input

                                        className="form-control search-input"
                                        type="text"
                                        placeholder="Search a Service"
                                        value={searchTerm}
                                        maxLength="30"
                                        onChange={handleSearchChange}
                                    />
                                     <button type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
                                </form>
                                 </div>
                            </div>
                            <div className="failed-list-section">
                                <div className="container">
                                    <div className="test-reports-display-list">
                                        {display ? (
                                            display
                                        ) : (
                                            <div style={{ color: "red", fontSize: "25px", height: "200px", padding: "40px" }}>
                                                <h4>No list to show!!</h4>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <br /><br />
                            <div className="failed-list-section">
                                <div className="container">
                                    <div className="test-reports-display-list">
                                        {totalPages > 1 ?
                                            <div className="pagn " >

                                                <Pagination
                                                    style={{ outerWidth: "100%", justifyContent: "center" }}
                                                    activePage={currentPage}
                                                    itemsCountPerPage={perPage}
                                                    totalItemsCount={total}
                                                    pageRangeDisplayed={totalPages}
                                                    onChange={handlePageChange}
                                                    disabledClass="disabled-class"
                                                />
                                            </div>
                                            : null}
                                    </div></div></div>
                        </div>
                    </div>

                </>
            </div>

        </>
    )

}

export default MasterServices
