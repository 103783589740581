import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { APIURL } from "../../Global";
import Pagination from "react-js-pagination";
import "./regdhospital.css"
import "./pagination.css";
import BackofficeNavBar from "../BackoffcieNav/BackofficeNavBar";
function RegisteredHospitals() {

  const [pending, setPending] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage,] = useState(10);

 


  useEffect(() => {
   
    const tokenString = sessionStorage.getItem("usertoken");
   
    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );

    axios.get(`${APIURL}/api/v1/staff/hospital-approve/`, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          const data = res.data.hospitals
          setPending(data)
        }
        else {

        }
      })
      .catch((err) => {

      })

  }, [])

  

  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1)
    setSearchTerm(e.target.value)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }



  let data = pending.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  })
    .filter((value) => {
      if (searchTerm === '') {
        return value
      } else if (value.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    })


  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = []
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i)
  }
  const totalPages = pageNumbers.length;

  let display = current.length > 0 ? current.map((data, index) => {
    let id = data.id
  
    return (
        
      <div className='list-item'>
        
          {" "}
          <h4 className="each-list-link">
          <Link to={{
          pathname: `/hospital-details/${id}`          
        }}>
            {(currentPage - 1) * 10 + index + 1}.{data.name}{" "}
            </Link>
          </h4>
            <small>ID : {data.id} </small>

      </div>
      
    )
  }) : null
  return (
    <div>
      <BackofficeNavBar/>
      <>
        <div className="container">
          <div className="container failedlist-parentcontainer">
            <h2 className="title-of-page">Service Providers List</h2>
            <div className="search-section">
  
       
                             <div className="search-div">
           
            <form style={{ display: "flex" }}>
             
                <input
                  className="form-control search-input"
                  type="text"
                  placeholder="Search a Hospital"
                  value={searchTerm}
                  maxLength="30"
                  onChange={handleSearchChange}
                />
                 <button type="submit" className="btn btn-primary btn-col search-btn">
              {" "}
              <i className="fas fa-search"></i>{" "}
            </button>
             
            </form>
            </div>
           </div>
           <br/>
            <div className="failed-list-section">
              <div className="container">
                <div className="test-reports-display-list">
                  {display ? (
                    display
                  ) : (
                    <div style={{ color: "red", fontSize: "25px", height: "200px", padding: "40px" }}>
                      <h4>No more Pending list to show!!</h4>
                    </div>
                  )}
                </div>

              </div>
            </div>

            {totalPages > 1 ?
              <div className="pagn pagn-small">
                <br/>
                <br />
                <br />
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={total}
                  pageRangeDisplayed={totalPages}
                  onChange={handlePageChange}
                  disabledClass="disabled-class"
                />
              </div>
              : null}

          </div>
        </div>
      </>
     
    </div>
  )
}

export default RegisteredHospitals
