import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./modules/login/LoginPage";
import ForgotPassword from "./modules/login/ForgotPassword";
import InputOtp from "./modules/login/InputOtp";
import LogoutPage from "./modules/login/LogoutPage";
import HomePage from "./modules/home/HomePage";
import StaffDashboard from "./modules/staff/StaffDashboard";
import Navbar from "./modules/common/Navbar"
import Footer from "./modules/common/Footer";
import RequireAuth from "./modules/registration/RequireAuth";
import ServiceProviderRegister from "./modules/registration/ServiceProviderRegister";
import { LoginProvider } from "./modules/contexts/LoginContext";
import { LoginStatusProvider } from "./modules/contexts/LoginStatusContext";
import { HospLoginProvider } from "./modules/contexts/HospitalLoginContext";
import { RegProvider } from "./modules/contexts/RegistrationContext";
import HospitalPending from "./modules/Hospital/HospitalPending";
import HospitalDashboard from "./modules/Hospital/HospitalDashboard";
import PageNotFound from "./modules/errorPage/PageNotFound";
import AddBdo from "./modules/BDO/addbdo";
import BdoList from "./modules/BDO/BdoList";
import AddSda from "./modules/Sda/AddSda";
import SdaList from "./modules/Sda/SdaList";
import RegisteredHospitals from "./modules/staff/RegisteredHospitals";
import HospitalDetails from "./modules/staff/HospitalDetails";
import ServiceDetails from "./modules/staff/service/ServiceDetails"
import CreateAppUserDoctor from "./modules/doctor/CreateAppUserDoctor";
import CreateAppUserBdo from "./modules/BDO/CreateAppUserBdo/CreateAppUserBdo";
import CreateAppUserSda from "./modules/Sda/CreateAppUserSda/CreateAppUserSda";
import "react-datepicker/dist/react-datepicker.css";
import { ProfileProvider } from "./modules/contexts/ProfileContext";
import HospProfile from "./modules/HospProfile/HospProfile";
import AddDoctor from "./modules/doctor/AddDoctor";
import DoctorsList from "./modules/doctorsList/DoctorsList"
import EditDoctor from "./modules/doctor/EditDoctor";
import DeleteDoctor from "./modules/doctor/DeleteDoctor";
import SuspendDoctor from "./modules/doctor/SuspendDoctor";
import ChangePasswordDoctor from "./modules/doctor/ChangePasswordDoctor";
import DoctorDashboard from "./modules/doctor/DoctorDashboard";
import BDODashboard from "./modules/BDO/BDODashboard";
import AddLabUser from "./modules/LaboratoryUser/AddLabUser";
import LabUserList from "./modules/LaboratoryUser/LabUserList";
import UpdateLabUser from "./modules/LaboratoryUser/UpdateLabUser";
import CreateLabUser from "./modules/LaboratoryUser/CreateLabUser"
import AddPharmacyUser from "./modules/PharmacyUser/AddPharmacyUser";
import PharmacyUserList from "./modules/PharmacyUser/PharmacyUserList";
import CreatePharmacyUser from "./modules/PharmacyUser/CreatePharmacyUser"
import UpdatePharmacyUser from "./modules/PharmacyUser/UpdatePharmacyUser"
import HospitalUpdateBdo from "./modules/BDO/HospitalUpdateBdo";
import HospitalUpdateSda from "./modules/Sda/HospitalUpdateSda";
import AddProf from "./modules/Professionals/addprof";
import ProfList from "./modules/Professionals/ProfList";
import HospitalUpdateProf from "./modules/Professionals/HospitalUpdateProf";
import CreateAppUserProf from "./modules/Professionals/CreateAppUserProf/CreateAppUserProf";
import AddNurse from "./modules/Nurses/addnurse";
import NurseList from "./modules/Nurses/NurseList";
import HospitalUpdateNurse from "./modules/Nurses/HospitalUpdateNurse";
import CreateAppUserNurse from "./modules/Nurses/CreateAppUserNurse/CreateAppUserNurse";
import MainServices from "./modules/staff/service/MainServices";
import MasterServices from "./modules/SPServices/MasterServices";
import SelectedServices from "./modules/SPServices/SelectedServices";
import Scan from "./modules/staff/AddScanTest/Scan";
import Test from "./modules/staff/AddLabTest/Test";



// import ForgotPassword from "./modules/Forgot_password/ForgotPassword";
import UserListDr from "./modules/HospSearchPatient/UserListingDr";
import PatientRecord from "./modules/HospSearchPatient/PatientRecord";
import ResetPassword from "./modules/login/ResetPassword";
import EmailConfirmation from "./modules/registration/EmailConfirmation";
import ChangePasswordHospitalBdo from "./modules/BDO/ChangePasswordHospitalBdo";
import ChangePasswordHospitalSda from "./modules/Sda/ChangePasswordHospitalSda";
import ChangePasswordHospitalNurse from "./modules/Nurses/ChangePasswordHospitalNurse";
import ChangePasswordHospitalProf from "./modules/Professionals/ChangePasswordHospitalProf";
import ChangePasswordHospitalPharm from "./modules/PharmacyUser/ChangePasswordHospitalPharm";
import ChangePasswordHospitalLab from "./modules/LaboratoryUser/ChangePasswordHospitalLab";
// import InputOtp from "./modules/login/InputOtp";





function App() {

  return (
    <>

      <BrowserRouter>
        <RegProvider>
          <LoginStatusProvider>
            <HospLoginProvider>
              <ProfileProvider>
                <LoginProvider>
                  <Navbar />
                  <Routes>
                    <Route exact path="/" element={<RequireAuth><HomePage /></RequireAuth>} />
                    <Route exact path="/hospital" element={<RequireAuth type="hospital"><HospitalDashboard /></RequireAuth>} />
                    <Route exact path="/hospitallist" element={<RegisteredHospitals />} />
                    <Route exact path="/servicelist" element={<MainServices />} />
                    
                    <Route exact path="/hospital-details/:id" element={<HospitalDetails />} />
                    <Route exact path="/service-details/:id" element={<ServiceDetails />} />
                    <Route exact path="/login" element={<LoginPage />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword />} />
                    <Route exact path="/input-otp" element={<InputOtp/>} />
                    <Route exact path="/reset-password" element={<ResetPassword/>} />
                    <Route exact path="/confirm-email" element={<EmailConfirmation/>} />
                    <Route exact path="/signup" element={<ServiceProviderRegister />} />
                    <Route exact path="/logout" element={<LogoutPage />} />
                    <Route exact path="/hospital_pending" element={<RequireAuth type="hospital_pending"><HospitalPending /></RequireAuth>} />
                    <Route exact path="/staff" element={<RequireAuth type="staff"><StaffDashboard /></RequireAuth>} />
                    <Route exact path="/404" element={<PageNotFound />} />
                    <Route exact path="/add-bdo" element={<AddBdo />} />
                    <Route exact path="/bdo-list" element={<BdoList />} />
                    <Route exact path="/add-sda" element={<AddSda />} />
                    <Route exact path="/sda-list" element={<SdaList />} />
                    <Route exact path="/create-doctor/:id" element={<CreateAppUserDoctor />} />
                    <Route exact path="/create-bdo/:id" element={<CreateAppUserBdo />} />
                    <Route exact path="/update-bdo/:id" element={<HospitalUpdateBdo />} />
                    <Route exact path="/changepassword-bdo/:id" element={<ChangePasswordHospitalBdo/>}/>
                    <Route exact path="/changepassword-sda/:id" element={<ChangePasswordHospitalSda/>}/>
                    <Route exact path="/changepassword-prof/:id" element={<ChangePasswordHospitalProf/>}/>
                    <Route exact path="/changepassword-nurse/:id" element={<ChangePasswordHospitalNurse/>}/>
                   
                    <Route exact path="/changepassword-lab/:id" element={<ChangePasswordHospitalLab/>}/>
                    <Route exact path="/changepassword-pharm/:id" element={<ChangePasswordHospitalPharm/>}/>
                    <Route exact path="/create-prof/:id" element={<CreateAppUserProf/>} />
                    <Route exact path="/update-prof/:id" element={<HospitalUpdateProf />} />
                    <Route exact path="/update-sda/:id" element={<HospitalUpdateSda />} />
                    <Route exact path="/create-sda/:id" element={<CreateAppUserSda />} />
                    <Route exact path="/profile" element={<HospProfile />} />
                    <Route exact path="/adddoctor" element={<AddDoctor />} />
                    <Route exact path="/doctorslist" element={<DoctorsList />} />
                    <Route exact path="/editdoctor/:id" element={<EditDoctor />} />
                    <Route exact path="/deletedoctor/:id" element={<DeleteDoctor />} />
                    <Route exact path="/suspenddoctor/:id" element={<SuspendDoctor />} />
                    <Route exact path="/changepassword/:id" element={<ChangePasswordDoctor/>} />

                 
                    <Route exact path="/hospital_doctor" element={<RequireAuth type="doctor"><DoctorDashboard /></RequireAuth>} />
                    <Route exact path="/hospital_BDO" element={<RequireAuth type="bdo"><BDODashboard /></RequireAuth>} />
                    <Route exact path="/add-labuser" element={<AddLabUser />} />
                    <Route exact path="/labusers-list" element={<LabUserList />} />
                    <Route exact path="/updateLabUser/:id" element={<UpdateLabUser />} />
                    <Route exact path="/create-lab-user/:id" element={<CreateLabUser />} />
                    <Route exact path="/add-Pharm-user" element={<AddPharmacyUser />} />
                    <Route exact path="/pharmacyusers-list" element={<PharmacyUserList />} />
                    <Route exact path="/create-pharmacy-user/:id" element={<CreatePharmacyUser />} />
                    <Route exact path="/updatePharmacyUser/:id" element={<UpdatePharmacyUser />} />

                    <Route exact path="/patient-record/:id" element={<PatientRecord />} />
                    <Route exact path="/updatePharmacyUser/:id" element={<UpdateLabUser />} />
                    <Route exact path="/add-professional" element={<AddProf />} />
                    <Route exact path="/professional-list" element={<ProfList />} />
                    <Route exact path="/add-nurse" element={<AddNurse />} />
                    <Route exact path="/nurse-list" element={<NurseList />} />
                    <Route exact path="/update-nurse/:id" element={<HospitalUpdateNurse />} />
                    <Route exact path="/create-nurse/:id" element={<CreateAppUserNurse />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword/>} />
                    <Route exact path="/patientlist" element={<UserListDr/>} />
                    {/* <Route exact path="/deleteNurse/:id" element={<DeleteNurse />} /> */}
                    <Route exact path="/master-services" element={<MasterServices/>} />
                    <Route exact path="/selected-services" element={<SelectedServices/>} />
                    <Route exact path="/Scantest" element={<Scan/>} />
                    <Route exact path="/Labtest" element={<Test/>} />

                    

                  </Routes>
                  <Footer />
                </LoginProvider>
              </ProfileProvider>
            </HospLoginProvider>

          </LoginStatusProvider>
        </RegProvider>

      </BrowserRouter>


    </>
  );
}

export default App;
