import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, Navigate } from "react-router-dom";
import "./login.css";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import { LoginContext } from "../contexts/LoginContext";
import { APIURL } from "../../Global";
import axios from "axios";
import background from "../../assets/images/bg.jpg";
import { HospLoginContext } from "../contexts/HospitalLoginContext";
import AuthUtil from "./AuthUtil";


function LoginPage() {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [, setHospData] = useContext(HospLoginContext);
  //const [, setHospDocData] = useContext(HospDrLoginContext);
  const location = useLocation();
  const [, setLoginDatas] = useContext(LoginContext);
  const [, setIsLoggedIn] = useContext(LoginStatusContext);
  const [error, setError] = useState(false);
 
  useEffect(() => {
    setLoginDatas(null);
    setIsLoggedIn(false);
  }, []);
  const handleLogin = async (e) => {
    e.preventDefault();
    await apiCall();
  };

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "loginUsername") {
      setLoginUsername(val);
    }
    if (key === "loginPassword") {
      setLoginPassword(val);
    }
  };

  const apiCall = () => {

    axios
      .post(
        `${APIURL}/api/v1/account/login/`,
        {
          username: loginUsername,
          password: loginPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          //TODO, sessionStorage is not recommeded for user data, use cookies instead.
          sessionStorage.setItem("logindatas", JSON.stringify(res.data));

          setLoginDatas(res.data);
          /* Store Token */
          sessionStorage.setItem("usertoken", JSON.stringify(res.data.token));

          sessionStorage.setItem("usertype", res.data.user_type);
          sessionStorage.setItem("userid", res.data.user_id);
          sessionStorage.setItem("hospId", res.data.hospital_id);

          if (res.data.verification_status === "pending") {
            sessionStorage.setItem("hosp_data", JSON.stringify(res.data));
            sessionStorage.setItem("hospital_pending", "true");
            setHospData(res.data);           
          }
        } else {
          setLoginUsername("");
          setLoginPassword("");
          setError(res.data.message);
        }
      })
      .catch(() => {
        setLoginUsername("");
        setLoginPassword("");
        setError("There was some error in login");
      });
  };

  const authUrl = AuthUtil.validateAuth();
  //When authentication is valid.
  if (authUrl) {
    return <Navigate to={authUrl} state={{ from: location }} />;
  }

  return (
    <>
    


      <div className="App">

        <div className="appAside">

          <div className="divider-line"></div>
          <div className="appSide-text"><h4>Remote Care Platform, It Is Easy And Quick</h4></div>
 
            <div className="appSide-text"> <h4>Convenient Home Care Technology Solution.</h4></div>


          <div className="divider-line"></div>
          
        </div>
        <div className="appForm" >




          <div className="formData">
            <div className="formdata1"><h2 className="name">Login</h2></div>
            <form className="formFields" onSubmit={handleLogin}>
              <div className="formField">

                <input
                  type="text"
                  className="formFieldInput"
                  name="loginUsername"
                  placeholder={"Username/E-mail Address"}
                  value={loginUsername}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="formField">

                <input
                  type="password"
                  className="formFieldInput"
                  value={loginPassword}
                  name="loginPassword"
                  placeholder={"Password"}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="forgot-password">
                  <Link to="/forgot-password" >
                    Forgot password?
                  </Link>
                </div>
            
              <div className="formField formButtonLayout">
                <button className="formFieldButton">Sign In</button>
                

                <Link to="/signup" className ="formFieldButton"> Create an account </Link>
                

              </div>
              <div className="formField">
              {error ? (
                  <div>
                    <p className="login-error" style={{ color: "red" }}>
                      {"Error"}:{" "}
                      {error === "Invalid credentials"
                        ? "Invalid credentials"
                        : error === "There was some error in login"
                          ? "There was some error in login"
                          : error}{" "}
                      <br />
                      {"Please try again!"}
                    </p>{" "}
                  </div>
                ) : null}
                </div>

              

            </form>

          </div>


        </div>


      </div>
      <div className="headerdata1"/>
    </>

  );




}




export default LoginPage;
