import React,{useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import PharmacyUser from './PharmacyUser';

import "./pharmacylist.css";
import axios from 'axios';
import {APIURL} from "../../Global";
import HospitalNavbar from '../HospitalNavbar/HospitalNavbar';

function PharmacyUserList(){
const [pharmUsersList,setPharmUserList] = useState([]); 
const [freeCount, setFreeCount]= useState();
const [activeCount, setActiveCount]= useState()
   const [errorMsg, setErrorMsg]= useState("");
   const [refresh, setRefresh]= useState(false)
	
   
 
  

 
 useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g,""); 

   
    axios
      .get(`${APIURL}/api/v1/service-provider/pharmacy-user/`, {
        headers: { 'Content-Type': 'application/json',
                     Authorization: "Token " +v2},
      })
      .then((res) => {
        
          const datas = res.data;
        
          
          setPharmUserList(datas);
       
      })
      .catch((err) => {
        setErrorMsg("error");
      });
  }, [refresh]); 

  const refreshHandle= ()=> {
    setRefresh(!refresh)
  }

	return(<div className="pharmlist-page">
   <HospitalNavbar/>
		<br />
   

		<h2 className="title-of-page"> Pharmacy Users List </h2>
    <div className="quick-links">
      <Link to="/add-Pharm-user"><button type="button" className="btn btn-primary btn-col pharm-btn"> + Add Pharmacy User to List </button></Link>
      

</div>

		{pharmUsersList.length!== 0  ? (<div>
		<div className="drs-container">
		{pharmUsersList.map((data,index)=>{
			return <PharmacyUser key={index} data={data} freeCount={freeCount} activeCount={activeCount} handle={refreshHandle}   />
		})}
         </div>
       
        <div className="quick-links">
		  

       </div>

       </div>)
       :
       <h5 style={{color: "red" ,margin: "20px"}}>Pharmacy Users List is empty </h5>
   }

    {errorMsg=== "error" ?
       <h5 style={{ color: "red" }}>
            
            Error in data fetching.Please try again!
          </h5>
      :
      null}
		</div>
		);
}

export default PharmacyUserList;