import { useLocation, Navigate } from "react-router-dom";

const getHomeURL = (userType, hospital_pending) => {

    let homeURL = "/";
    homeURL += hospital_pending ? "hospital_pending" : userType;
    return homeURL;
}

const RequireAuth = ({ type, children }) => {
    // use hooks to check this : TODO
    const loggedIn = sessionStorage.getItem("usertoken");
    const userType = sessionStorage.getItem("usertype");
    const hospital_pending = sessionStorage.getItem("hospital_pending");
    const location = useLocation();

    if (loggedIn && userType) {
        if ((userType === "staff" && type === "staff") ||
         (userType === "hospital_doctor" && type === "doctor") ||
         (userType === "hospital_bdo" && type === "bdo")||
            (userType === "hospital" && type === "hospital" && !hospital_pending) ||
            (userType === "hospital" && type === "hospital_pending" && hospital_pending)) {
            return children;
        }
        return <Navigate to={getHomeURL(userType, hospital_pending)} state={{ from: location }} />;
    }

    return <Navigate to="/login" state={{ from: location }} />;
}

export default RequireAuth;