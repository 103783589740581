
import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, Navigate, NavLink } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import 'font-awesome/css/font-awesome.min.css';

import "./doctorDashboard.css";




function BDODashboard() {
  const [loginDatas] = useContext(LoginContext);


  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <div >

      <div className="home">
        {/* <h1>Doctor Dashboard</h1> */}
        <h2 className="title-of-page"> {login_datas.name} </h2>
        <h4 className="subtitle-of-page">{login_datas.location}</h4>

        <div className="home-container">
       

          <div className="home-tiles" 
           onClick={() => navigate("/selected-services")}
          >
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">Services</h5>

          </div>

          

          
        </div>

      </div>

    </div>


  );
}

export default BDODashboard;