import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import "./glucosevitals.css";
import axios from "axios";
import DatePicker from "react-date-picker";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { APIURL } from "../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Carousel from "react-grid-carousel";
import Accordion from 'react-bootstrap/Accordion'
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Card from 'react-bootstrap/Card'
import AddSugar from "../vitals/AddSugar";

let myChart;

function GlucoseVitals(props) {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .split("T")[0]
  );
  const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [patientId] = useState(props.id);

  const [inference, setInference] = useState([]);
  const [inferenceLoad, setInferenceLoad] = useState("");
  const [glucDatesArray, setGlucDatesArray] = useState([]);
  const [updateRender, setUpdateRender] = useState(false);
  const [glucoseList, setGlucoseList] = useState([]);
  const [nonFasting, setNonFasting] = useState([]);
  const [fasting, setFasting] = useState([]);
  const [random, setRandom] = useState([]);
  const [unit, setUnit] = useState();
  const [load, setLoad] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [deleteIndex, setDeleteIndex] = useState('');
  const [deleteShow, setDeleteShow] = useState(false)
  const [submitMsg, setSubmitMsg] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [glucoDeleteRowValue, setGlucoDeleteRowValue] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const ctx = document.getElementById("myChart-glucose").getContext("2d");

    if (myChart) {
      myChart.destroy();
    }
    myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: glucDatesArray,
        datasets: [
          {
            label: "NonFasting Blood Sugar",
            data: nonFasting,
            backgroundColor: "purple",

            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "purple",
          },
          {
            label: "Fasting Blood Sugar",
            data: fasting,
            backgroundColor: "green",

            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "green",
          },
          {
            label: "Random Blood Sugar",
            data: random,
            backgroundColor: "red",

            pointBorderWidth: 2,
            pointHoverRadius: 5,
            fill: false,
            borderColor: "red",
          },
        ],
        tooltips: {
          backgroundColor: "purple",
        },
      },

      options: {
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: `Blood Sugar Values  ${unit ? "(" + unit + ")" : null
                  } `,
              },

              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Date and Time",
              },
            },
          ],
        },
        legend: {
          position: "right",
          labels: {
            fontColor: "#000",
          },
        },
      },
    });
  }, [updateRender, glucDatesArray, nonFasting, fasting, random, unit]);




  const submitSugarHandle = () => {

    handleGraph();
    handleTableData();
  };

  useEffect(() => {
    handleTableData();
    handleGraph();

  }, [updateRender]);

  const handleTableData = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    setLoad("Loading Data...");

    axios
      .get(
        `${APIURL}/api/v1/doctor/patient-glucose/?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&timeZone=${timezoneOffset}&sort_by=asc`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {

        if (res.data.status === "success") {
          setLoad("");
          let arr = res.data.results;

          let check = [];
          let check1 = [];
          let check2 = [];

          arr.map((i) => {
            if (i.glucose_type === "glucose") {
              check.push(i.value);
              check1.push(null);
              check2.push(null);
            } else if (i.glucose_type === "glucose_fasting") {
              check.push(null);
              check2.push(null);
              check1.push(i.value);
            } else if (i.glucose_type === "glucose_random") {
              check.push(null);
              check1.push(null);
              check2.push(i.value);
            }
          });

          setNonFasting(check);
          setFasting(check1);
          setRandom(check2);

          let newArrayDates = [];
          arr.map((result) => {
            newArrayDates.push(result.date.slice(0, 16).split("T").join("\n"));
          });
          setGlucDatesArray(newArrayDates);

          setGlucoseList(res.data.results);

          let unit1 = null;
          if (res.data.results.length > 0) {
            unit1 = res.data.results[0].unit;
          } else {
            unit1 = null;
          }

          setUnit(unit1);
        } else {
          setLoad("Error in Fetching Data");
        }
      })
      .catch((err) => {
        setLoad("Error in Fetching Data");
      });
  }

  const handleGraph = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    setInferenceLoad("Loading Data...");

    axios
      .get(
        `${APIURL}/api/v1/patient/sugar-inferences/?patient_id=${patientId}&date_from=${startDate}&date_to=${endDate}&timeZone=${timezoneOffset}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setInferenceLoad("");
          setInference(res.data.data);
        } else {
          setInferenceLoad("Error in Fetching Data");
        }
      })
      .catch((err) => {
        setInferenceLoad("Error in Fetching Data");
      });
  }



  const callDelete = () => {
    ;

    let idToDelete = glucoDeleteRowValue;
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    const headers = {
      Authorization: "Token " + str,
      "Content-Type": "application/json",
    };

    axios
      .delete(`${APIURL}/api/v1/patient/tests/${idToDelete}`, {
        data: { patient_id: patientId },
        headers: { Authorization: "Token " + str },
      })

      .then((res) => {

        if (res.status == "200") {

          setSubmitMsg("Deleted Successfully");
          setModalShow(true);
          //  setInferenceLoad("");
          setRefreshKey(1);

        } else {
          setErrorMsg("in Data Submission. Please try again!");
          setErrorSubmit(true);
        }

        setDeleteShow(false);
      })
      .catch((err) => {
        ;
      });


  };

  const SubmitPopUp = ({ show, onHide }) => {


    return (
      <Modal
        show
        onHide
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{submitMsg}</h4>

        </Modal.Body>
        <Modal.Footer>


          <Button onClick={onHide}>Ok</Button>


        </Modal.Footer>
      </Modal>
    );
  }


  // const deleteRow=(index)=>{



  // }

  const DeleteConfirmPopup = (props) => {
    ;

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
 
          <Button variant="danger" onClick={callDelete}> Confirm</Button>



        </Modal.Footer>
      </Modal>
    );
  }

  const handleGo = (e) => {
    e.preventDefault();
    setUpdateRender(!updateRender);
  };

  const handleFromDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setStartDate(DateExtracted);
    }
  };

  const handleToDate = (date) => {
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setEndDate(DateExtracted);
    }
  };

  const renderGlucose = (cellInfo) => {
    const date4 = glucoseList;
    const date5 = [...date4];
    return <h6> {date5[cellInfo.index][cellInfo.column.id]} </h6>;
  };

  const renderType = (cellInfo) => {
    const date4 = glucoseList;
    const date5 = [...date4];
    const data = date5[cellInfo.index][cellInfo.column.id];

    return data === "glucose" ? (
      <h6>Non Fasting Blood Sugar</h6>
    ) : data === "glucose_fasting" ? (
      <h6>Fasting Blood Sugar</h6>
    ) : data === "glucose_random" ? (
      <h6>Random Blood Sugar</h6>
    ) : null;
  };
  const renderDate = (cellInfo) => {
    const date4 = glucoseList;
    const date5 = [...date4];
    return (
      <h6> {date5[cellInfo.index].date.slice(0, 16).split("T").join("\n")} </h6>
    );
  };
  const renderFitData = (cellInfo) => {
    const data = glucoseList;
    const fitData = [...data];

    return (
      <h6> {fitData[cellInfo.index][cellInfo.column.id] ? "Yes" : "No"} </h6>
    );
  };
  const handleDeleteClick = (e) => {


    ;
    let testID = e.row.test_id

    setDeleteShow(true)
    setGlucoDeleteRowValue(testID)

    //setDeleteIndex(props)
  }

  const columnsGlucose = [
    {
      Header: <>
        <h6>Data and</h6>
        <h6>Time </h6>
      </>, accessor: "date",
      Cell: renderDate,
      width: 120,
      minWidth: 50,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
    },
    {
      Header: "Test Type",
      accessor: "glucose_type",
      Cell: renderType,
      width: 170,
      minWidth: 50,
    },
    {
      Header: (
        <>
          <h6>Blood Sugar</h6>
          <h6>(mg/dL)</h6>
        </>
      ),
      accessor: "value",
      Cell: renderGlucose,
      width: 100,
      minWidth: 80,
    },
    {
      Header: <>
        <h6>Smart Device</h6>
        <h6>Data </h6>
      </>,
      accessor: "is_wearables",
      Cell: renderFitData,
      width: 130,
      minWidth: 50,
    },
    // {

    //   accessor: "test_id",
    //   //Cell: renderFitData,
    //   show: false,
    // },
    {
      Header: <span className="table-header-tooltip" data-toggle="tooltip"
        data-placement="top" title="Delete Row"><i className="fas fa-trash-alt"></i></span>,
      accessor: "test_id",
      Cell: props => {

        return (


          <i className="fas fa-times close-button" onClick={() => handleDeleteClick(props)}     ></i>
        )
      },
      sortable: false,
      filterable: false,
      minWidth: 50,
      width: 50

    }
  ];
  const Glucose = (
    <ReactTable
      columns={columnsGlucose}
      data={glucoseList}
      defaultPageSize={10}
      className="gluco-table"

    ></ReactTable>
  );

  const inferenceData =
    inferenceLoad === "" ? (
      inference !== undefined ? (
        <div className="inference-container analysis-subsection">
          <div className="inference-container-header box-theme">
            <h6 className="inference-container-title"> Analysis</h6>
          </div>
          <div className="inference-container-body">
            <h5 className="text-success font-weight-bold">
              {inference.summary}
            </h5>
            <h6 className="text-muted font-weight-bold">
              Maximum Sugar Level :{" "}
            </h6>{" "}
            <p>
              {inference.max_sugar}mg/dL {inference.max_sugar_date}
            </p>
            <br />
            <h6 className="text-muted font-weight-bold">
              Minimum Sugar Level :{" "}
            </h6>{" "}
            <p>
              {inference.min_sugar}mg/dL {inference.min_sugar_date}
            </p>
            <br />
            <h6 className="text-muted font-weight-bold">
              Sugar Deviation :{" "}
            </h6>{" "}
            <p>{inference.sugar_sd}</p>
          </div>
        </div>
      ) : (
        <h6 style={{ color: "red" }}> No data to show! </h6>
      )
    ) : inferenceLoad === "Error in Fetching Data" ? (
      <h6 style={{ color: "red" }}>{inferenceLoad}</h6>
    ) : (
      <h6 style={{ color: "grey" }}>{inferenceLoad}</h6>
    );

  return (
    <>
      <div className="food-habits-page">
        <div className="food-habits-header" >

          {/* <h3 className=""> Patient  ID : {patientId} </h3><br/><br/> */}

          <div className="calender-pickers-from-to" >
            <h4 className="title-of-tasks"> Blood Sugar </h4>
            <div className="span-date">
              <span> From </span>

              <DatePicker
                value={new Date(startDate)}
                onChange={(date) => {
                  handleFromDate(date);
                }}
                maxDate={new Date()}
              />
            </div>
            <br/>
            <div className="span-date">
              <span>To </span>

              <DatePicker
                value={new Date(endDate)}
                onChange={(date) => {
                  handleToDate(date);
                }}
                maxDate={new Date()}
              />

            </div>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <br/>
            <button
              type="submit"
              className="btn btn-primary btn-col"
              onClick={handleGo}
            >
              {" "}
              Check{" "}
            </button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>


          </div>

        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <div className="food-habits-sections"  >
          <div>
            {/* <Accordion>
              <Card>
                <Card.Header>
                  <AddSugarHeader as={Card.Header} variant="link" eventKey="0" />
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <AddSugar id={patientId} submitsugar={submitSugarHandle} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion> */}

            <Carousel cols={1} rows={1} gap={20} showDots={true} loop>
              <Carousel.Item>
                <div style={{ width: "95%", maxHeight: "100%", marginLeft: "5%", position: "absolute" }}>
                  {/* <h5 className="title-of-lists"> Graph </h5> */}
                  <div className="chart-div-glucose">
                    <canvas style={{ width: "100%", height: "100%", paddingTop: "15%" }} id="myChart-glucose"></canvas>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div style={{ width: "80%", maxHeight: "90%", marginLeft: "10%", paddingTop: "10%" }} className="food-table">
                  {/* <h5 className=" title-of-lists"> Table </h5> */}
                  {load === "" ? (
                    glucoseList.length > 0 ? (
                      <>{Glucose}</>
                    ) : (
                      <h6 style={{ color: "red" }}> No data to show! </h6>
                    )
                  ) : load === "Error in Fetching Data" ? (
                    <h6 style={{ color: "red" }}>{load}</h6>
                  ) : (
                    <h6 style={{ color: "grey" }}>{load}</h6>
                  )}
                </div>
              </Carousel.Item>
              {/* <Carousel.Item>
                <div style={{ width: "90%", maxHeight: "100%", marginLeft: "5%", paddingTop: "20%" }} className="food-table">
                  {/* <h5 className=" title-of-lists"> Inference </h5> */}

                  {/* {inferenceData}
                </div>
              </Carousel.Item> */} 
            </Carousel>
          </div >

        </div>

      </div>

      {submitMsg !== "" && submitMsg !== null ?
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            handleGraph();
            handleTableData();
            setModalShow(false);
            setSubmitMsg('');
          }}


        /> : null}

      <DeleteConfirmPopup
        show={deleteShow}
        onHide={() => {
          setDeleteShow(false);
        }}

      />



    </>
  );
}

function AddSugarHeader({ children, eventKey }) {
  const [toggle, setToggle ] = useState(false);
  const decoratedOnClick = Accordion(eventKey, () => setToggle(!toggle));

  return (
    <h5 className="addH5" onClick={decoratedOnClick}>
      Add Sugar +<svg className={toggle ? "opened" : "closed"} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
      </svg>
    </h5>
  );
}

export default GlucoseVitals;
