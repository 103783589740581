import React, { useState, useRef, useEffect, useContext } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./hosppatientrecord.css";
import { Link ,useParams} from "react-router-dom";
import DatePicker from "react-date-picker";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import "./reacttable.css";
import moment from "moment";
import AddBp from "../vitals/AddBp";
import AddCholestrol from "../vitals/AddCholestrol";
import AddPulse from "../vitals/AddPulse";
import AddSugar from "../vitals/AddSugar";
import GlucoseVitals from "../vitals/GlucoseVitals";
import VitalsBP from "../vitals/VitalsBP";
import VitalsChol from "../vitals/VitalsChol";
import VitalsPulse from "../vitals/VitalsPulse";
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
//import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
//import DrNotes from "../DrNotes/DrNotes";

//import WeightAdd from "./WeightAdd"
//import HeightAdd from "./HeightAdd";

import { APIURL } from "../../Global";




import { Suspense, lazy } from "react";






function PatientRecord(props) {
  const [height, setHeight] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [weight, setWeight] = useState();
  const [age, setAge] = useState();
  const [summary, setSummary] = useState("");
  //const [refresh, setRefresh] = useContext(TargetContext);

  const {id} = useParams();
  const [, setuserDetails] = useState();
  const [name, setName] = useState();
  const [gender, setGender] = useState();
  const [dob, setDob] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [instructions, setInstructions] = useState();
  const [instructionsDate, setInstructionsDate] = useState();
  const [addWeight, setAddWeight] = useState(false);
  const [heightAdd, setAddHeight] = useState(false);
  const [weightAddShow, setWeightAddShow] = useState(false);
  const [heightAddShow, setHeightAddShow] = useState(false);
  const [, setInstructionsArray] = useState([]);
  const [next_visit, setNext_visit] = useState(null);
  const [next, setNext] = useState();
  const [editweight, seteditweight] = useState();
  const [editheight, seteditheight] = useState();
  const [addPulse, setAddPulse] = useState(false);
  const [addBp, setAddBp] = useState(false);
  const [addChol, setAddCholestrol] = useState(false);
  const [addSugar, setAddSugar] = useState(false);
  const [testsList, setTestsList] = useState([
    {
      id: "",
      test_name: "",
      date: "",
      lab_test_details:"",
    },
  ]);
  const [scanList, setScanList] = useState([
    {
      id: "",
      scan_name: "",
      date: "",
      scan_details:"",
    },
  ]);
  const [popupShow, setPopupShow] = useState(false);
  const [imgEditShow, setImgEditShow] = useState(false);
  const [reasonAddShow, setReasonAddShow] = useState(false);
  const [imgViewShow, setImgViewShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [reasonEditShow, setReasonEditShow] = useState(false);
  const [index, setIndex] = useState();
  const [editShow, setEditShow] = useState(false);
  const [editShow1, setEditShow1] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [remarkViewShow, setRemarkViewShow] = useState(false);
  const [detailData, setDetailData] = useState("");
  const [cell, setCell] = useState("");
  const [editSuccess, setEditSuccess] = useState(false);
  const [sizeShow, setSizeShow] = useState(false);
  const [validShow, setValidShow] = useState(false);
  const [contentShow, setContentShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(false);
  const [searchAssign, setSearchAssign] = useState("");
  const [displayDoctor, setDisplayDoctor] = useState(false);
  const [doctorId, setDoctorId] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [assignList, setAssignList] = useState([]);

  const [submitMsg, setSubmitMsg] = useState("");
  const [submitMsg1, setSubmitMsg1] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [deleteNextMsg, setDeleteNextMsg] = useState("");
  const [deleteNextModalShow, setDeleteNextModalShow] = useState(false);


  const [submitInstructionsMsg, setSubmitInstructionsMsg] = useState("");
  const [modalInstructionsShow, setModalInstructionsShow] = useState(false);
  const [, setPatientData] = useState();
  const [, setDisplaySuggestions] = useState(false);
  const [, setDisplaySuggestions1] = useState(false);
  const [, setDisplaySuggestions2] = useState(false);
  const [options, setOptions] = useState([]);
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [display3, setDisplay3] = useState(false);
  const [search, setSearch] = useState("");
  const [searchScan, setSearchScan] = useState("");
  const [scanSuggest, setScanSuggest] = useState();
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [testListCounter, setTestListCounter] = useState(0);
  const [scanListCounter, setScanListCounter] = useState(0);
  const wrapperRef = useRef(null);

  const [render, setRender] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [, setDateVal] = useState("");
  const [deleteScanConfirmMsg, setDeleteScanConfirmMsg] = useState("");
  const [deleteScanConfirmModalShow, setDeleteScanConfirmModalShow] =
    useState(false);
  const [deleteNextVisitConfirmMsg, setDeleteNextVisitConfirmMsg] =
    useState("");
  const [deleteNextVisitConfirmModalShow, setDeleteNextVisitConfirmModalShow] =
    useState(false);

  const [scanIndexToDelete, setScanIndexToDelete] = useState();
  const [deleteTestConfirmMsg, setDeleteTestConfirmMsg] = useState("");
  const [deleteTestConfirmModalShow, setDeleteTestConfirmModalShow] =
    useState(false);
  const [testIndexToDelete, setTestIndexToDelete] = useState();
  const [showTestTable, setShowTestTable] = useState(false);
  const [showScanTable, setShowScanTable] = useState(false);
  const [testErr, setTestErr] = useState("false");
  const [scanErr, setScanErr] = useState("false");
  const [updatedTestList, setUpdatedTestList] = useState([]);
  const [updatedScanList, setUpdatedScanList] = useState([]);

  const [submitTrigger, setSubmitTrigger] = useState(true);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorScanShow, setErrorScanShow] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [errorSubmit1, setErrorSubmit1] = useState(false);
  const [foodHabitsShow, setFoodHabitsShow] = useState(false);
  const [glucoseShow, setGlucoseShow] = useState(false);
  const [bpShow, setBpShow] = useState(false);
  const [pulseShow, setPulseShow] = useState(false);
  const [cholesterolShow, setCholesterolShow] = useState(false);
  const [, setScanShow] = useState(false);
  const [, setTestShow] = useState(false);
  const [addPrescShow, setAddPrescShow] = useState(false);
  const [drNotesShow, setDrNotesShow] = useState(false);
  const [targetShow, setTargetShow] = useState(false);
  const [inferenceShow, setInferenceShow] = useState(false);
  const [observationsShow, setObservationsShow] = useState(false);
  const [observationsArray, setObservationsArray] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [symptomsShow, setSymptomsShow] = useState(false);
  const [refreshInstr, setRefreshInstr] = useState(false);
  const [timing] = useState(200);
  const [isBooped, setIsBooped] = useState(false);
  const [allergy, setAllergy] = useState([]);
  const [allergyArray, setAllergyArray] = useState([]);
  const [allergyShow, setAllergyShow] = useState(false);
  const [errorNextModalShow, setErrorNextModalShow] = useState(false);
  const [nextErr, setNextErr] = useState("nil");
  const [messagesShow, setMessagesShow] = useState(false);
  const [summaryDone, setSummaryDone] = useState("");
  const [prescForm, setPrescForm] = useState(false);
  const [userID, setUserID] = useState(0);
  const freq_link = `analysis/${id}`;
  const [procedureRefresh, setProcedureRefresh]= useState(false)
  const [searchProcedure, setSearchProcedure] = useState("");
  const [procedureSuggest, setProcedureSuggest] = useState();
  const [editData, setEditData] = useState();
  const [errorMessage, setErrorMessage] = useState('');






  const [historyShow, setHistoryShow] = useState(false);






  //let history = useHistory();

  let formData = new FormData();
  const reader = new FileReader();

  const setDefaultDoctor = () => {
    const hosp_doc_data =sessionStorage.getItem('hosp_doc_data');
    if(hosp_doc_data) {
      const {doctor_name, user_id} = JSON.parse(hosp_doc_data);
      setDoctorId(user_id);
      setDoctorName(doctor_name);
    }
  }

  const [keys] = useState({
    p: false,
    a: false,
    n: false,
    m: false,
    l: false,
    s: false,
  });
  let track = null;

  //const hospId = props.location.hospId;

  // useEffect(() => {
  //   let getPath = JSON.parse(sessionStorage.getItem("logindatas"));

  //   setUserID(getPath.user_id);

  //   if (hospId !== undefined) {
  //     sessionStorage.setItem("hospId", hospId);
  //   }
  // }, []);

  // useEffect(() => {
  //   window.onkeydown = (e) => {
  //     keys[e.key] = true;

  //     if (e.altKey && keys["p"]) {
  //       history.push("/patients-directory");
  //     }
  //     if (e.altKey && keys["a"]) {
  //       history.push("/appointment");
  //     }
  //     if (e.altKey && keys["n"]) {
  //       history.push("/doctor/notifications");
  //     }
  //     if (e.altKey && keys["m"]) {
  //       history.push("/message");
  //     }
  //     if (e.altKey && keys["l"]) {
  //       history.push("/doctor/analytics");
  //     }
  //     if (e.altKey && keys["s"]) {
  //       history.push("/service-requestlist");
  //     }
  //   };
  //   window.onkeyup = (ev) => {
  //     keys[ev.key] = false;
  //   };

  //   return () => {
  //     window.onkeydown = null;
  //     window.onkeyup = null;
  //   };
  // }, []);

  useEffect(() => {
    debugger;
    
    const tokenString = sessionStorage.getItem("usertoken");
   const userid = sessionStorage.getItem("userid");
    let str = tokenString.replace(/["]+/g, "");
    if (testErr === "nil") {
      let values = [];
      let labtests={};
      const tests = updatedTestList;
      for (let i = 0; i < tests.length; i++) {

        values.push({
          patient:id,
          doctor : userid,
          lab_test_details : tests[i].lab_test_details,   
          date :tests[i].date,
          test :tests[i].id
        })

    

        // if (
        //   tests[i].labtest_reason.image instanceof File === true ||
        //   tests[i].labtest_reason.image === ""
        // ) {
        //   formData.append(`tests[${i}]test`, tests[i].test);
        //   formData.append(`tests[${i}]date`, tests[i].date);
        //   formData.append(
        //     `tests[${i}]patient_lab_test_details`,
        //     tests[i].labtest_reason.details
        //   );
        //   formData.append(
        //     `tests[${i}]patient_lab_test_details_image`,
        //     tests[i].labtest_reason.image
        //   );
        //   formData.append(
        //     `tests[${i}]patient_lab_test_reason`,
        //     tests[i].labtest_reason.reason
        //   );
          
        // }
      }
      labtests = {

        "labtests":values
      }

      console.log(labtests)
    
 
  
    axios
    .post(`${APIURL}/api/v1/doctor/tests-list/`,labtests, {
      headers: {
        Authorization: "Token " + str,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.data.status === "success") {
        setSubmitMsg("success");
        setModalShow(true);
      } else {
        setSubmitMsg("error");
        setErrorSubmit(true);
      }
    })
    .catch((err) => {
      setSubmitMsg("error");
      setErrorSubmit(true);
    });
  }
  }, [testErr !== "nil", submitTrigger]);
  

  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");
    const userid = sessionStorage.getItem("userid");
    let str = tokenString.replace(/["]+/g, "");

    if (scanErr === "nil") {
      const scans1 = updatedScanList;
     let values1=[]
     let scans = {}

      for (let i = 0; i < scans1.length; i++) {

        values1.push({
          patient:id,
          doctor : userid,
          scan_details : scans1[i].scan_details,
          date :scans1[i].date,
          scan :scans1[i].id
        })

        // if (
        //   scans[i].scan_details.image instanceof File === true ||
        //   scans[i].scan_details.image === ""
        // ) {
      //     formData.append(`scans[${i}]test`, scans[i].test);
      //     formData.append(`scans[${i}]date`, scans[i].date);
      //     formData.append(
      //       `scans[${i}]patient_scan_details`,
      //       scans[i].scan_details.details
      //     );
      //     formData.append(
      //       `scans[${i}]patient_scan_reason`,
      //       scans[i].scan_details.reason
      //     );
      //     formData.append(
      //       `scans[${i}]patient_scan_details_image`,
      //       scans[i].scan_details.image
      //     );
      //   }
      
   
      // formData.append("patient_id", id);
      // formData.append("doctor_id", userID);

      // if (next_visit !== null) {
      //   formData.append("next_visit", next_visit);
      // }

      // formData.append("quick_summary", summary);
      scans = {
         "scans":values1
      }
      }
      axios
        .post(`${APIURL}/api/v1/doctor/scans-list/`, scans, {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
          if (res.data.status === "success") {
            setSubmitMsg("success");
            setModalShow(true);
          } else {
            setSubmitMsg("error");
            setErrorSubmit(true);
          }
        })
        .catch((err) => {
          setSubmitMsg("error");
          setErrorSubmit(true);
        });
    }
  }, [ scanErr !== "nil", submitTrigger]);



  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/laboratory-tests/?per_page=20&q=${search}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status==200) {
    
        
          setOptions(res.data);
        }
      })
      .catch((err) => {});
  }, [search]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/scans/?q=${searchScan}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status==200) {
          setScanSuggest(res.data);
        }
      })
      .catch((err) => {});
  }, [searchScan]);











 




















  



  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/procedure-assignee/?q=${searchAssign}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {

        if (res.data.status === "success") {
       
          setAssignList(res.data.details);
          setDefaultDoctor();
        }
      })
      .catch((err) => {});
  }, [searchAssign]);











  function msgData(childData) {
    track = childData;
  }
  const handleTrack = () => {
    setMessagesShow(false);
    if (track !== null) {
      track.stop();
    }
  };



  useEffect(() => {
    PatientDetails();
  }, []);

const TestData= async()=>{
  setTestsList([
    {
      id: "",
      test_name: "",
      date: "",
      lab_test_details:""
    
      //labtest_reason: { details: "", image: "", reason: "" },
    },  
  ]);
  setTestListCounter(0);
  const tokenString = sessionStorage.getItem("usertoken");

  let str = tokenString.replace(/["]+/g, "");
  await axios
    .get(`${APIURL}/api/v1/doctor/tests-list/?patient_id=${id}`, {
      headers: {
        Authorization: "Token " + str,
      },
    })
    .then((res) => {
      if (res.data.status === "success") {
         if (res.data.message.length > 0) 
          setTestsList(res.data.message);
          //setTestListCounter(res.data.message.length);
        
      }
    })
        .catch((err) => {});
}
const ScanData= async()=>{
  setScanList([
    {
      id: "",
      scan_name: "",
      date: "",
      scan_details: "",
    },
  ]);
  setScanListCounter(0);
  const tokenString = sessionStorage.getItem("usertoken");

  let str = tokenString.replace(/["]+/g, "");
  await axios
    .get(`${APIURL}/api/v1/doctor/scans-list/?patient_id=${id}`, {
      headers: {
        Authorization: "Token " + str,
      },
    })
    .then((res) => {
      if (res.data.status === "success") {
        if (res.data.message.length > 0) {
          setScanList(res.data.message);
          setScanListCounter(res.data.message.length);
        }
      }
    })
    
        .catch((err) => {});
}
  const allDatas = async () => {
   
  
  
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    await axios
      .get(`${APIURL}/api/v1/doctor/patient-record/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setuserDetails(res.data);
          setPatientData(res.data);
          setName(res.data.name);
          setDob(res.data.dob);
          function getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age--;
            }
            return age;
          }

          setAge(getAge(res.data.dob));
          setSummary(res.data.quick_summary);

         
         
          setGender(res.data.gender);
          setNext_visit(res.data.next_visit);

          if (res.data.next_visit !== null) {
            setNext(new Date(res.data.next_visit));
          } else {
            setNext(null);
          }

          setImgUrl(res.data.photo);
        }
      })
      .catch((err) => {});
  };

  const saveNextVisit = () => {
    debugger;
    const fd1 = new FormData();

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    console.log('THE DOCTOR', searchAssign);
    console.log('THE VISIT', next_visit);

    if (doctorId === '' || doctorId === null) {
      setSubmitMsg("error");
      setErrorSubmit(true);
      setErrorMessage('Please select a Doctor for next visit');
    } else if (next_visit === null) {
      setSubmitMsg("error");
      setErrorSubmit(true);
      setErrorMessage('Please select a Valid Date for next visit');
    } else if (next_visit !== null &&  doctorId !== null) {
      fd1.append("patient_id", id);
      fd1.append("next_visit", next_visit);
      fd1.append("doctor_id", doctorId);
      axios
        .post(`${APIURL}/api/v1/doctor/patient-record/`, fd1, {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        })

        .then((res) => {
          if (res.data.status === "success") {
            setSubmitMsg("success");
            setModalShow(true);
          } else {
            setSubmitMsg1("error");
            setErrorSubmit1(true);
          }
        })
        .catch((err) => {
          setSubmitMsg1("error");
          setErrorSubmit1(true);
        });
    }
  };


  // const summaryData = () => {
  //   const tokenString = localStorage.getItem("usertoken");

  //   let str = tokenString.replace(/["]+/g, "");

  //   axios
  //     .get(
  //       `${APIURL}/api/v1/patient/cholesterol-inferences/?patient_id=${id}`,
  //       {
  //         headers: {
  //           Authorization: "Token " + str,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.status === "success") {
  //         const hdl = res.data.cholesterol_inference_data.hdl_cholesterol_data;
  //         const ldl = res.data.cholesterol_inference_data.ldl_cholesterol_data;
  //         const serum =
  //           res.data.cholesterol_inference_data.serum_cholesterol_data;
  //         const total =
  //           res.data.cholesterol_inference_data.total_cholesterol_data;

  //         if (hdl !== null) {
  //           setSummary((chol) => chol.concat(`\n${hdl.summary}`));
  //         }
  //         if (ldl !== null) {
  //           setSummary((chol) => chol.concat(`\n${ldl.summary}`));
  //         }
  //         if (serum !== null) {
  //           setSummary((chol) => chol.concat(`\n${serum.summary}`));
  //         }

  //         if (total !== null) {
  //           setSummary((chol) => chol.concat(`\n${total.summary}`));
  //         }
  //       }

  //       setSummaryDone("done");
  //     })
  //     .catch((err) => {});
  // };

  useEffect(() => {
    async function execute() {
      await allDatas();
      await  TestData();
      await  ScanData();
      //await summaryData();
    }
  
    
    execute();
  }, [id, reRender]);

  // useEffect(() => {
  //   setTestsList(testsList);
  // }, [testsList]);

  // useEffect(() => {
  //   setTestsList(testsList);
  //   setScanList(scanList);
  //   setRender(false);
  // }, [render]);

  const PatientDetails = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data.health_details;
          setWeight(data.weight);
          setHeight(data.height);
          setMaritalStatus(data.married);
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
        setErrorSubmit(true);
      });
    setWeightAddShow(false);
    setHeightAddShow(false);
  };

  useEffect(() => {
    return () => {
      return (
        <>
          {" "}
          <ReactTable
            columns={columns}
            data={testsList}
            defaultPageSize={4}
            resizable
          ></ReactTable>
        </>
      );
    };
  }, [testsList]);


  // const handleBack = (e) => {
  //   history.goBack();
  // };

  const handleClick = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };


  const handleWeightAdd = () => {
    setWeightAddShow(true);
  };

  const handleHeightAdd = () => {
    setHeightAddShow(true);
  };

  const submitweightHandle = () => {
    setAddWeight(false);
  };

  const submitheightHandle = () => {
    setAddHeight(false);
  };

  const handleSubmitTest = () =>
  {
    debugger;
    setSubmitTrigger(!submitTrigger);
    setTestErr("nil");
    const updatedTests = testsList.filter((row, i) => {
      return row.test !== "" && row.date !== "";
    });
    const testData = testsList.filter((row, i) => {
      return (
        row.test !== "" || row.date !== "" || row.lab_test_details !== ""
      );
    });
    testData.map((item) => {
      if (item.test_name === "") {
        setTestErr("*Test name in Test Schedules Table is mandatory!");
        setErrorModalShow(true);
      }
      if (item.date === "") {
        setTestErr("*Date in Test Schedules Table is mandatory!");
        setErrorModalShow(true);
      }
      if (item.test !== "" && item.date !== "") {
        setUpdatedTestList(updatedTests);
        setSubmitTrigger(!submitTrigger);
      }
    });
    setUpdatedTestList(updatedTests);
    setSubmitTrigger(!submitTrigger);

  }
  const handleSubmitScan = () => {
    setSubmitTrigger(!submitTrigger);
  
    setScanErr("nil");
  
    const updatedScans = scanList.filter((row, i) => {
      return (
        row.scan !== "" && row.date !== "" && row.scan_details !== ""
      );
    });

   

    const scanData = scanList.filter((row, i) => {
      return (
        row.scan !== "" || row.date !== "" || row.scan_details !== ""
      );
    });

  
    scanData.map((item) => {
      if (item.scan === "") {
        setScanErr("*Scan name in Scan Schedules table is mandatory!");
        setErrorScanShow(true);
      }
      if (item.date === "") {
        setScanErr("*Date in Scan Schedules Table is mandatory!");
        setErrorScanShow(true);
      }
      if (item.scan_details === "") {
        setScanErr("*Scan details in Scan Schedules Table is mandatory!");
        setErrorScanShow(true);
      }

      if (
        item.test !== "" &&
        item.date !== "" &&
        item.scan_details !== ""
      ) {
        setUpdatedScanList(updatedScans);
        setSubmitTrigger(!submitTrigger);
      }
    });

  
    setUpdatedScanList(updatedScans);
    setSubmitTrigger(!submitTrigger);
  };
  const handleNextVisit = (date) => {
    setNext(date);

    if (date !== null) {
      const offset = date.getTimezoneOffset();

      let dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setNext_visit(DateExtracted);
     
    }
  };





  const handleDeleteNextVisit1 = () => {
    setDeleteNextVisitConfirmMsg("success");
    setDeleteNextVisitConfirmModalShow("true");
  };
  const handleDeleteNext = () => {
    setNext_visit(null);
    setNext(null);
    callDeleteNext();
  };

  const handleDeleteTest = (index) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    
    const  ts_id=testsList[index].id
     
  

    axios
      .delete(`${APIURL}/api/v1/doctor/tests-details/${ts_id}`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.status ==204) {
          setReRender(!reRender);
        } else {
          setSubmitMsg("error");
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
      });
  };
  const callDeleteNext = (index) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToUpdate = {
      patient_id: id,
      next_visit: "True",
    };

    axios
      .put(`${APIURL}/api/v1/doctor/patient-record/`, dataToUpdate, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
          setDeleteNextMsg("Successfully deleted next visit data");
          setDeleteNextModalShow(true);
          setDoctorName("")
          setDisplayDoctor(false)
        } else if (res.data.status === "error") {
          setNextErr(
            res.data.message ? res.data.message : "Error in submission!"
          );
        } else {
          setNextErr("Error in submission!");
        }
      })
      .catch((err) => {
        setNextErr("Error in submission!");
      });
  };

  const handleDisplayTestTable = () => {
    setShowTestTable(!showTestTable);
  };

  const handleDisplayScanTable = () => {
    setShowScanTable(!showScanTable);
  };




  const DeleteNextVisitConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Next Visit!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              setDeleteNextVisitConfirmModalShow(false);
              handleDeleteNext();
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DeleteTestConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Test</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              debugger
              setDeleteTestConfirmModalShow(false);
              handleDeleteTest(props.index);
              setTestIndexToDelete(null);
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };




  const WeightAddPopup = (props) => {
    ;
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <WeightAdd
            id={id}
            submitweight={submitweightHandle}
            seteditweight={weight}
            onHide={PatientDetails}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={PatientDetails}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const HeightAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <HeightAdd
            id={id}
            submitheight={submitheightHandle}
            seteditheight={height}
            onHide={PatientDetails}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={PatientDetails}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const PulsePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsPulse {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const GlucosePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GlucoseVitals {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };



  
  



  const BpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsBP {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const CholesterolPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VitalsChol {...props} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };




  const MessagesPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Suspense fallback={<div>Loading...</div>}>
            {/* <HospDrMessages patientId={id} track={msgData} /> */}
          </Suspense>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Data submitted sucessfully!</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const DeletePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{deleteNextMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };



  const ErrorNextPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {nextErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {testErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMessage ? errorMessage : 'Error in Data Submission. Please try again!'}{" "}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitErrorPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            Please Select doctor name{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };







  const handleGlucoseOverlay = (e) => {
    e.preventDefault();
    setGlucoseShow(true);
  };
  const handlePulseOverlay = (e) => {
    e.preventDefault();
    setPulseShow(true);
  };
  const handleCholOverlay = (e) => {
    e.preventDefault();
    setCholesterolShow(true);
  };
  const handleBpOverlay = (e) => {
    e.preventDefault();
    setBpShow(true);
  };




  const ErrorScanPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {scanErr}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <ImageUpdate
            img={scanList[index].scan_details.image}
            imgUpdate={handleFile}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ImgEditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <ImageUpdate
            img={testsList[index].labtest_reason.image}
            imgUpdate={handleFile}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {flag ? (
            <ImageUpdate
              img={testsList[index].labtest_reason.image}
              imgUpdate={handleFile}
            />
          ) : (
            <ImageUpdate
              img={scanList[index].scan_details.image}
              imgUpdate={handleFile}
            />
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  let imgFile = null;

  function handleFile(childData) {
    imgFile = childData;
  }

  const reasonAddHandle = () => {
    setReasonAddShow(false);
    const tests = testsList;
    tests[cell].labtest_reason.reason = sendData;
    setTestsList(tests);
    setEditSuccess(true);
  };

  const ReasonAddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <AddRemark update={handleSend} save={reasonAddHandle} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ReasonEditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <EditRemark data={detailData} update={handleSend} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };



  const handleDeleteRowTest = (index,row) => {
    setDeleteTestConfirmMsg("success");
    setDeleteTestConfirmModalShow("true");
    setTestIndexToDelete(index);
  };

  const deleteRow = (index,props) => {
    let newlist = [];
    if (index > testListCounter - 1) {
      debugger
      // newlist = testsList.filter((test, i) => {
      //   return i !== index;
      // });
      let s=testsList.filter(x=>  x.test==props.row._original.test);
      if(s.length>0){
        handleDeleteRowTest(index,s);
      }
      else{
        setTestsList(newlist);
        if (newlist.length <= 0) {
          setTestsList([
            {
              id: "",
              test_name: "",
              date: "",
              lab_test_details:""
            },
          ]);
        }
      }

 
    } else {
      handleDeleteRowTest(index);
    }
  };
  const renderEditableTest = (cellInfo) => {

    //if (cellInfo.index > testListCounter - 1 || testListCounter === 0) {
      return (
        <div ref={wrapperRef}>
          <input
            style={{ backgroundColor: "#fafafa" }}
            value={testsList[cellInfo.index][cellInfo.column.id]}
            className="form-control"
            name="test_name"
            onChange={(e) => {
              e.preventDefault();
 
              setCurrentIndex(cellInfo.index);
              setCurrentColumn(cellInfo.column.id);
              setDisplaySuggestions1(true);
              setDisplay1(true);

              setSearch(e.target.value);

              const tests = testsList;
              tests[cellInfo.index].test = e.target.value;

              setTestsList(tests);
            }}
            autoComplete="off"
          />

          {currentIndex === cellInfo.index &&
            cellInfo.column.id === "test_name" &&
            display1 && (
              <div className="suggestions-box">
                {options.map((item, i) => {
                  debugger;

                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        ;
                        e.preventDefault();

                        setRender(!render);

                        const tests = testsList;
                        tests[cellInfo.index].test_name = item.test_name;
                        tests[cellInfo.index].id=item.id
                        setTestsList(tests);
                        setDisplay1(false);
                      }}
                    >
                      <p className="search-item">{item.test_name}</p>
                    </div>
           
                  );
                })}
              </div>
            )}
        </div>
      );
   // }
    
    // else {
    //   return (
    //     <div>
    //       <div
    //         style={{ backgroundColor: "#fafafa" }}
    //         className="form-control test-name-col"
    //         name="test"
    //         value={testsList[cellInfo.index].test}
    //         onBlur={(e) => {
    //           const tests = testsList;
    //           tests[cellInfo.index].test = e.target.innerHTML;

    //           setTestsList(tests);
    //         }}
    //         autoComplete="off"
    //         dangerouslySetInnerHTML={{
    //           __html: testsList[cellInfo.index][cellInfo.column.id],
    //         }}
    //       />
    //     </div>
    //   );
    // }
  };

  const handlePulse = () => {
    setAddPulse(true);
  };

  const handleSugar = () => {
    setAddSugar(true);
  };
  const handleBp = () => {
    setAddBp(true);
  };
  const handleChol = () => {
    setAddCholestrol(true);
  };

  const renderEditableTestDate = (cellInfo) => {
   
    if (cellInfo.index > testListCounter - 1) {
      const today = new Date();

      const offset = today.getTimezoneOffset();

      let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      return (
        <div>
          <input
            type="date"
            data-date={testsList[cellInfo.index].date}
            className="scan-date-col date-custom-format"
            min={DateExtracted}
            onChange={(e) => {
              e.preventDefault();
              let date = e.target.value;

              const tests = testsList;
              tests[cellInfo.index].date = date;

              setTestsList(tests);
              const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

              setDateVal(m);
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-date-col"
            name="date"
            value={testsList[cellInfo.index].date}
            onBlur={(e) => {
              const tests = testsList;
              tests[cellInfo.index].date = e.target.innerHTML;

              setTestsList(tests);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: testsList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  const editTestImage = (pos) => {
    setCell(pos);
    const fileInput = document.getElementById("testImage");
    fileInput.click();
  };

  const uploadTestImage = (e) => {
    const file = e.target.files[0];
    const test = testsList;
    if (!file) {
      setUploadShow(!uploadShow);
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow);
      return false;
    }
    if (file.size > 2e6) {
      setSizeShow(!sizeShow);
      return false;
    }
    reader.onload = (e) => {
      const img1 = new Image();
      img1.onload = () => {
        test[cell].labtest_reason.image = file;
        setTestsList(test);
        setIndex(cell);
        setTableRefresh(!tableRefresh);
      };
      img1.onerror = () => {
        setContentShow(!contentShow);

        return false;
      };
      img1.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const renderTestImage = (cellInfo) => {
    if (cellInfo.index > testListCounter - 1) {
      if (
        testsList[cellInfo.index].labtest_reason.image === null ||
        testsList[cellInfo.index].labtest_reason.image === ""
      ) {
        return (
          <div>
            <input
              id="testImage"
              hidden="hidden"
              type="file"
              onChange={uploadTestImage}
            />

            <i
              onClick={() => {
                editTestImage(cellInfo.index);
              }}
              class="fas fa-image select-img"
            ></i>
          </div>
        );
      } else {
        return (
          <button
            onClick={() => {
              setImgEditShow(true);
              setIndex(cellInfo.index);
            }}
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View/Edit
          </button>
        );
      }
    } else {
      if (
        testsList[cellInfo.index].labtest_reason.image === null ||
        testsList[cellInfo.index].labtest_reason.image === ""
      ) {
        return null;
      } else {
        return (
          <button
            onClick={() => {
              setViewShow(true);
              setIndex(cellInfo.index);
              setFlag(true);
            }}
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View
          </button>
        );
      }
    }
  };

  const renderTestDetails = (cellInfo) => {
 debugger;
    if (cellInfo.index > testListCounter - 1) {
      return (
        <div>
          <textarea
            className="text-area"
            placeholder="Add Test Details"
            cols="100"
            rows="2"
            onChange={(e) => {
              e.preventDefault();
              let data = e.target.value;

              const tests = testsList;
              testsList[cellInfo.index].lab_test_details = data;

              setTestsList(tests);
            }}
          />
        </div>
      );
    } else {
      return (
        <textarea
          className="scan-text"
          value={testsList[cellInfo.index].lab_test_details}
          cols="100"
          rows="2"
        />
      );
    }
  };

  const handleReasonAdd = (index) => {
    setCell(index);
    setReasonAddShow(true);
  };

  const handleReasonEdit = (data, index) => {
    setDetailData(data);
    setCell(index);
    setReasonEditShow(true);
  };

  // const renderTestReason = (cellInfo) => {
  //   if (cellInfo.index > testListCounter - 1) {
  //     if (
  //       testsList[cellInfo.index][cellInfo.column.id].reason === "" ||
  //       testsList[cellInfo.index][cellInfo.column.id].reason === null
  //     ) {
  //       return (
  //         <button
  //           onClick={() => handleReasonAdd(cellInfo.index)}
  //           className="btn btn-smallest add-remark-btn"
  //         >
  //           <i className="fas fa-plus"></i>
  //         </button>
  //       );
  //     } else {
  //       return (
  //         <button
  //           onClick={() =>
  //             handleReasonEdit(
  //               testsList[cellInfo.index][cellInfo.column.id].reason,
  //               cellInfo.index
  //             )
  //           }
  //           className="btn btn-primary btn-col btn-square btn-smallest"
  //         >
  //           View/Edit
  //         </button>
  //       );
  //     }
  //   } else {
  //     if (
  //       testsList[cellInfo.index][cellInfo.column.id].reason === "" ||
  //       testsList[cellInfo.index][cellInfo.column.id].reason === null
  //     ) {
  //       return null;
  //     } else {
  //       return (
  //         <button
  //           onClick={() =>
  //             handleView(testsList[cellInfo.index][cellInfo.column.id].reason)
  //           }
  //           className="btn btn-primary btn-col btn-square btn-smallest"
  //         >
  //           View
  //         </button>
  //       );
  //     }
  //   }
  // };

  const AddRowTest = () => {
debugger
 
    const list = testsList;
    list.push({
      created_at: "",
      created_by: "",
      date: "",
      doctor: '',
      doctor_name: "",
      hospital: '',
      id: "",
      lab_test_details: "",
      lab_test_details_image: '',
      lab_test_reason: '',
      modified_at: "",
      patient_name: "",
      test: "",
      test_name: ""
    });

    setTestsList(list);
    setRender(true);
  };

  const columns = [
    {
      Header: <i className="fa fa-trash"></i>,
      Cell: (props) => {
        return (
          <i
            className="fa fa-trash trash-btn"
            onClick={() => {
               
              deleteRow(props.index,props);
            }}
          ></i>
        );
      },
      width: 100,
      sortable: false,
      filterable: false,
    },
    // id: "",
    // test_name: "",
    // date: "",
    // lab_test_details:""
    {
      Header: "TEST NAME",
      accessor: "test_name",
      Cell: renderEditableTest,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 300,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "DATE (YYYY-MM-DD)",
      accessor: "date",
      Cell: renderEditableTestDate,
      sortable: false,
      width: 300,
    },
    {
      Header: "Test Details",
      accessor: "lab_test_details",
      Cell: renderTestDetails,
      sortable: false,
      filterable: false,
      width: 500,
    },
    // {
    //   Header: "Reason",
    //   accessor: "labtest_reason",
    //   Cell: renderTestReason,
    //   sortable: false,
    //   filterable: false,
    //   width: 110,
    // },
    // {
    //   Header: <i class="fas fa-upload"></i>,
    //   accessor: "labtest_reason",
    //   Cell: renderTestImage,
    //   sortable: false,
    //   filterable: false,
    //   width: 300,
    // },

    {
      Header: "ADD",

      Cell: (props) => {
       
        return props.index === testsList.length - 1 ? (
          <button
            className="btn btn-primary btn-col btn-col btn-col btn-small"
            onClick={AddRowTest}
          >
            +Add
          </button>
        ) : null;
      },
      width: 300,
      sortable: false,
      filterable: false,
    },
  ];

  const AddRowScan = () => {
    const list = scanList;
    list.push({
      id: "",
      scan_name: "",
      date: "",
      scan_details: " "
    });

    setScanList(list);
    setRender(true);
  };
  const handleDeleteRowScan = (index) => {
    setDeleteScanConfirmMsg("success");
    setDeleteScanConfirmModalShow("true");
    setScanIndexToDelete(index);
  };

  const DeleteScanConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please confirm to delete Scheduled Scan</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              setDeleteScanConfirmModalShow(false);
              handleDeleteScan(props.index);
              setScanIndexToDelete(null);
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };




  const SizePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">You have Exceeded the size limit 2MB</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const LoadPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please select an Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ValidPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Please select a valid Image</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ContentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Invalid Image Content</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddBpPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddBp id={id} submitbp={submitBpHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddCholestrolPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCholestrol id={id} submitcholestrol={submitCholHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddSugarPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddSugar id={id} submitsugar={submitSugarHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const AddPulsePopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddPulse id={id} submitpulse={submitPulseHandle} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const submitBpHandle = () => {
    setAddBp(false);
  };
  const submitCholHandle = () => {
    setAddCholestrol(false);
  };

  const submitSugarHandle = () => {
    setAddSugar(false);
  };
  const submitPulseHandle = () => {
    setAddPulse(false);
  };

  const handleDeleteScan = (index) => {
    debugger;
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

   
     const ts_id=scanList[index].id
    


    axios
      .delete(`${APIURL}/api/v1/doctor/scans-details/${ts_id}`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.status ==204) {
          setReRender(!reRender);
          ScanData();
        } else {
        }
      })
      .catch((err) => {
        setSubmitMsg("error");
      });
  };
  const deleteRowScan = (index) => {

    debugger;
   
    // let newlist = [];
    // if (index >= scanListCounter - 1) {
    //   debugger
    //   // newlist = testsList.filter((test, i) => {
    //   //   return i !== index;
    //   // });
    //   let s=scanList.filter(x=>  x.scan==props.row._original.scan);
    //   if(s.length>0){
    //     handleDeleteRowScan(index,s);
    //   }
    //   else{
    //     setScanList(newlist);
    //     if (newlist.length <= 0) {
    //       setScanList([
    //         {
    //           id: "",
    //           scan_name: "",
    //           date: "",
    //           scan_details:""
    //         },
    //       ]);
    //     }
    //   }

 
    // } else {
      handleDeleteRowScan(index);
    //}
  };

  const handleMessagesOverlay = (e) => {
    e.preventDefault();
    setMessagesShow(true);
  };

  const renderEditableScan = (cellInfo) => {
  
   
   // if (cellInfo.index > scanListCounter - 1 || scanListCounter === 0) {
      return (
        <div>
          <input
            style={{ backgroundColor: "#fafafa" }}
            value={scanList[cellInfo.index][cellInfo.column.id]}
            className="form-control"
            name="scan_name"
            onChange={(e) => {
              e.preventDefault();

              setCurrentIndex(cellInfo.index);
              setCurrentColumn(cellInfo.column.id);
              setDisplaySuggestions2(true);
              setDisplay2(true);

              setSearchScan(e.target.value);

              const scans = scanList;
              scans[cellInfo.index].scan = e.target.value;
              //scans[cellInfo.index]. = e.target.value;

              setScanList(scans);
            }}
            autoComplete="off"
          />

          {currentIndex === cellInfo.index &&
            cellInfo.column.id === "scan_name" &&
            display2 && (
              <div className="suggestions-box">
                {scanSuggest.map((item, i) => {
                  
                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const scans = scanList;
                        scans[cellInfo.index].scan_name = item.scan_name;
                        scans[cellInfo.index].id=item.id
                        setScanList(scans);
                        setDisplay2(false);
                      }}
                    >
                 
                      <p className="search-item">{item.scan_name}</p>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      );
    //} 
    
    // else {
    //   return (
    //     <div>
    //       <div
    //         style={{ backgroundColor: "#fafafa" }}
    //         className="form-control scan-name-col"
    //         name="scan"
    //         value={scanList[cellInfo.index].scan}
    //         onBlur={(e) => {
    //           const scans = scanList;
    //           scans[cellInfo.index].scan = e.target.innerHTML;

    //           setScanList(scans);
    //         }}
    //         autoComplete="off"
    //         dangerouslySetInnerHTML={{
    //           __html: scanList[cellInfo.index][cellInfo.column.id],
    //         }}
    //       />
    //     </div>
    //   );
    // }
  };

  const renderEditableScanDate = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1) {
      const today = new Date();

      const offset = today.getTimezoneOffset();

      let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      return (
        <div>
          <input
            type="date"
            data-date={scanList[cellInfo.index].date}
            className="scan-date-col date-custom-format"
            min={DateExtracted}
            onChange={(e) => {
              e.preventDefault();
              let date = e.target.value;

              const scans = scanList;
              scans[cellInfo.index].date = date;

              setScanList(scans);
              const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

              setDateVal(m);
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control scan-date-col"
            name="date"
            value={scanList[cellInfo.index].date}
            onBlur={(e) => {
              const scans = scanList;
              scans[cellInfo.index].date = e.target.innerHTML;

              setScanList(scans);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanList[cellInfo.index][cellInfo.column.id],
            }}
          />
        </div>
      );
    }
  };

  let sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }

  // const addHandle = () => {
  //   setAddShow(false);
  //   const scans = scanList;
  //   scans[cell].scan_details.reason = sendData;
  //   setScanList(scans);
  //   setEditSuccess(true);
  // };

  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <AddRemark update={handleSend} save={addHandle} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RemarkViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <ViewRemark data={detailData} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DetailPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <EditRemark data={detailData} update={handleSend} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DetailsUpdateSuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Updated sucessfully.Please Submit to Save Changes</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleAdd = (index) => {
    setCell(index);
    setAddShow(true);
  };

  const handleView = (data) => {
    setDetailData(data);
    setRemarkViewShow(true);
  };

  const handleEdit = (data, index) => {
    setDetailData(data);
    setCell(index);
    setEditShow(true);
  };

  const renderDetails = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1) {
      return (
        <div>
          <textarea
            className="text-area"
            placeholder="Add Scan Details"
            cols="100"
            rows="2"
            onChange={(e) => {
              e.preventDefault();
              let data = e.target.value;

              const scans = scanList;
              scanList[cellInfo.index].scan_details = data;

              setScanList(scans);
            }}
          />
        </div>
      );
    } else {
      return (
        <textarea
          className="scan-text"
          value={scanList[cellInfo.index].scan_details}
          cols="100"
          rows="2"
        />
      );
    }
  };

  const renderReason = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1) {
      if (
        scanList[cellInfo.index][cellInfo.column.id].reason === "" ||
        scanList[cellInfo.index][cellInfo.column.id].reason === null
      ) {
        return (
          <button
            onClick={() => handleAdd(cellInfo.index)}
            className="btn btn-smallest add-remark-btn"
          >
            <i className="fas fa-plus"></i>
          </button>
        );
      } else {
        return (
          <button
            onClick={() =>
              handleEdit(
                scanList[cellInfo.index][cellInfo.column.id].reason,
                cellInfo.index
              )
            }
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View/Edit
          </button>
        );
      }
    } else {
      if (
        scanList[cellInfo.index][cellInfo.column.id].reason === "" ||
        scanList[cellInfo.index][cellInfo.column.id].reason === null
      ) {
        return null;
      } else {
        return (
          <button
            onClick={() =>
              handleView(scanList[cellInfo.index][cellInfo.column.id].reason)
            }
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View
          </button>
        );
      }
    }
  };

  const editImage = (pos) => {
    setCell(pos);
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    const scans = scanList;
    if (!file) {
      setUploadShow(!uploadShow);
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow);
      return false;
    }
    if (file.size > 2e6) {
      setSizeShow(!sizeShow);
      return false;
    }
    reader.onload = (e) => {
      const img1 = new Image();
      img1.onload = () => {
        scans[cell].scan_details.image = file;
        setScanList(scans);
        setIndex(cell);
        setTableRefresh(!tableRefresh);
      };
      img1.onerror = () => {
        setContentShow(!contentShow);

        return false;
      };
      img1.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const renderImage = (cellInfo) => {
    if (cellInfo.index > scanListCounter - 1) {
      if (
        scanList[cellInfo.index].scan_details.image === null ||
        scanList[cellInfo.index].scan_details.image === ""
      ) {
        return (
          <div>
            <input
              id="imageInput"
              hidden="hidden"
              type="file"
              onChange={uploadImage}
            />

            <i
              onClick={() => {
                editImage(cellInfo.index);
              }}
              class="fas fa-image select-img"
            ></i>
          </div>
        );
      } else {
        return (
          <button
            onClick={() => {
              setPopupShow(true);
              setIndex(cellInfo.index);
            }}
            className="btn btn-primary btn-col btn-square btn-smallest"
          >
            View/Edit
          </button>
        );
      }
    } else {
      if (
        scanList[cellInfo.index].scan_details.image === null ||
        scanList[cellInfo.index].scan_details.image === ""
      ) {
        return null;
      } else {
        return (
          <button
            onClick={() => {
              setViewShow(true);
              setIndex(cellInfo.index);
            }}
            className="btn btn-success btn-square btn-smallest"
          >
            View
          </button>
        );
      }
    }
  };

  const columnsScans = [
    {
      Header: <i className="fa fa-trash"></i>,
      Cell: (props) => {
       
        return (
          <i
            className="fa fa-trash trash-btn"
            onClick={() => {
              deleteRowScan(props.index,props);
            }}
          ></i>
        );
      },
      width:100,
      sortable: false,
      filterable: false,
    },
    {
      Header: "SCAN NAME",
      accessor: "scan_name",
      Cell: renderEditableScan,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 310,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "DATE (YYYY-MM-DD)",
      accessor: "date",
      Cell: renderEditableScanDate,
      sortable: false,
      width: 300,
    },

    {
      Header: "Scan Details",
      accessor: "scan_details",
      Cell: renderDetails,
      sortable: false,
      filterable: false,
      width: 500,
    },
    // {
    //   Header: "Reason",
    //   accessor: "scan_details",
    //   Cell: renderReason,
    //   sortable: false,
    //   filterable: false,
    //   width: 110,
    // },
    // {
    //   Header: <i class="fas fa-upload"></i>,
    //   accessor: "scan_details",
    //   Cell: renderImage,
    //   sortable: false,
    //   filterable: false,
    //   width: 100,
    // },
    {
      Header: "ADD",

      Cell: (props) => {
        return props.index === scanList.length - 1 ? (
          <button
            className="btn btn-primary btn-col btn-col btn-small"
            onClick={AddRowScan}
          >
            {" "}
            +Add
          </button>
        ) : null;
      },
      width: 500,
      sortable: false,
      filterable: false,
    },
  ];









  
 







 




  let summaryArray = summary.split(/[\r\n]+/);
  let displaySummary =
    summaryArray.length > 1
      ? summaryArray.map((line, i) => {
          if (line) {
            return (
              <>
                <h6 className="text-left ">
                  {" "}
                  {i + 1}.{line}{" "}
                </h6>
              </>
            );
          }
        })
      : "-No data to show-";

  let displaySummaryTable = (
    <div className="sub-section inferences-subsection">
      <div className="sub-section-header box-theme">
        <h6 className="sub-section-title">
          {" "}
          Inferences 
          <OverlayTrigger
            trigger="hover"
            rootClose
            placement="top"
            
          >
            <i className="fas fa-info-circle"></i>
          </OverlayTrigger>
        </h6>
      </div>
      <div className="sub-section-body">{displaySummary}</div>
    </div>
  );
  useEffect(() => {
    if (!isBooped) {
      return;
    }
    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);

  return (
    <>
     <div>
       <HospDocNavbar/>
     </div>
      <br />

      <div className=" flex-row-end">
        <div className=" text-left">
     
        </div>

        {/* <GeneralSearchBar {...props} /> */}
      </div>

      <div className="patient-record-holder ">
        {/* <div className="sticky-nav">
          <div className="left-nav">
            <ul className="stickynav-ul">
              <li
                onClick={() => {
                  handleClick(ref1);
                }}
              >
                {" "}
                Personal Data & Targets{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip2}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li
                onClick={() => {
                  handleClick(ref2);
                }}
              >
                {" "}
                Inference & Impact Analysis{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip15}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li
                onClick={() => {
                  handleClick(ref3);
                }}
              >
                {" "}
                Diagnostics Info{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip3}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>

              <li
                onClick={() => {
                  handleClick(ref4);
                }}
              >
                {" "}
                Current Medications{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip8}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li
                onClick={() => {
                  handleClick(ref5);
                }}
              >
                {" "}
                Observations{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip9}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li>
              <li
                onClick={() => {
                  handleClick(ref6);
                }}
              >
                {" "}
                Symptoms{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip10}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li>
              <li
                onClick={() => {
                  handleClick(ref7);
                }}
              >
                {" "}
                Allergy{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip20}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li>
              <li
                onClick={() => {
                  handleClick(ref8);
                }}
              >
                Health Info & Medical records{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip12}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li>

              <li
                onClick={() => {
                  handleClick(ref9);
                }}
              >
                {" "}
                Treatment Planner{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip14}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li
                onClick={() => {
                  handleClick(ref11);
                }}
              >
                {" "}
                Procedures{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip26}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>
              </li>
              <li
                onClick={() => {
                  handleClick(ref10);
                }}
              >
                {" "}
                Instructions{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip13}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li onClick={handleDrNotesOverlay}>
                {" "}
                Doctors Note{" "}
                <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  overlay={renderTooltip24}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger>{" "}
              </li>
              <li>
              {/* <span className="freq-link text-left"> */}
                {/* <Link to={{ pathname: freq_link }}>
                  {" "} */}
                  {/* <div className=" box-4 box-dr-notes header-box1 freq-box sub-section-header"> */}
                    {/* <h6 className="med-tests-link">
                      {" "}
                      Frequent Medicines/Tests Analyis{" "}
                      <span col="">
                        <OverlayTrigger
                          trigger="hover"
                          rootClose
                          placement="top"
                          overlay={renderTooltip25}
                        >
                          <i className="fas fa-info-circle"></i>
                        </OverlayTrigger>
                      </span>
                    </h6> */}
                  {/* </div> */}
                {/* </Link> */}
              {/* </span> */}
              {/* </li>
            </ul>
          </div>
        </div> */} 
        
        <div className="right-section" >
         
                  <h4>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span
              className="title-of-tasks"
              id={gender === "female" ? "patient-female" : "patient-male"}
            >
              {" "}
              {name}{" "}
            </span>
            <span className="prime-header felixa-header">
              {" "}
              (3Doc ID: {id}{" "}
              {sessionStorage.getItem("hospId") !== ""
                ? " , Hospital ID:" + sessionStorage.getItem("hospId")
                : null}
              )
            </span>
          </h4>
      
        
          <br />

          {/*********top panel**********/}
          <div className="info section-1  zero-contentbox">
            <div className="box-2 visit-box  panel-box" onClick={() => {}}>
              <h6 className="prime-header ">
                {" "}
                Next Visit : <br />
                {next_visit ? <span> {next_visit}</span> : <span> ---</span>}
                
              </h6>
            </div>
            <div className="box-1 visit-box panel-box" onClick={() => {}}>
              <h6 className="prime-header">
                {" "}
                Upcoming Test : <br />
                {testsList.length > 0 ? (
                  <>
                    <span>{testsList[0].test ? testsList[0].test : "---"}</span>
                    <h6> {testsList[0].date}</h6>
                  </>
                ) : null}
              </h6>
            </div>
            <div className="box-4 visit-box panel-box" onClick={() => {}}>
              <h6 className="prime-header">
                {" "}
                Upcoming Scan : <br />
                {scanList.length > 0 ? (
                  <>
                    <span>{scanList[0].test ? scanList[0].test : "---"}</span>
                    <h6> {scanList[0].date}</h6>
                  </>
                ) : null}
              </h6>
            </div>
          </div>
          <div
            className="info section-1 each-section first-contentbox "
           
          >
            <div className="second-tab sub-section ">
              <div className="sub-section-header box-theme">
                <h6 className="sub-section-title"> Personal Data </h6>
              </div>
              <div className="personal-details-sub-section">
                <div className="sub-section-img ">
                  <img
                    className="profile-picture"
                    src={imgUrl}
                    alt="Profile Photo"
                    width="120px"
                    height="auto"
                  />
                </div>
                <br />
                <br />

                <h6>
                  {" "}
                  Gender : <span className="user-details">{gender}</span>
                </h6>
                <h6>
                  {" "}
                  DOB: <span className="user-details"> {dob}</span>{" "}
                </h6>
                <h6>
                  {" "}
                  Age: <span className="user-details"> {age}</span>{" "}
                </h6>
                <h6 className="text-left">
                  {" "}
                  Weight:{" "}
                  <span>
                    {" "}
                    <b>{weight} kg</b>{" "}
                  </span>{" "}
                  {/* {weight ? (
                    <i
                      style={{ justifySelf: "start", color: "#3468c2" , cursor:"pointer"}}
                      onClick={handleWeightAdd}
                      className="fas fa-edit"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i
                      onClick={() => handleWeightAdd()}
                      style={{ justifySelf: "start", color: "#3468c2" }}
                      className="fas fa-plus"
                      aria-hidden="true"
                    ></i>
                  )} */}
                </h6>
                <h6>
                  {" "}
                  Height:{" "}
                  <span>
                    {" "}
                    <b>{height} cm</b>{" "}
                  </span>{" "}
                  {/* {height ? (
                    <i
                      style={{ justifySelf: "start", color: "#3468c2",cursor:"pointer" }}
                      onClick={handleHeightAdd}
                      className="fas fa-edit iconStyle"
                    ></i>
                  ) : (
                    <i
                      onClick={() => handleHeightAdd()}
                      style={{ justifySelf: "start", color: "#3468c2" }}
                      className="fas fa-plus"
                    ></i>
                  )} */}
                </h6>

                <h6>
                  {" "}
                  Marital Status:{" "}
                  <span className="user-details">
                    {" "}
                    {maritalStatus ? "Married" : "Not Married"}
                  </span>
                </h6>
              </div>
            </div>

            {/* <div className="second-tab sub-section imp-info-subsection">
              <div className="sub-section-header header-box1">
                <svg
                  width="1.2em"
                  height="1.2em"
                  viewBox="0 0 16 16"
                  className="bi bi-bookmark-fill"
                  fill="var(--red)"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5V2z"
                  />
                </svg>

                <h6 className="sub-section-title">
                  {" "}
                  Targets{" "}
                  <OverlayTrigger
                    trigger="hover"
                    rootClose
                    placement="top"
                    overlay={renderTooltip2}
                  >
                    <i className="fas fa-info-circle"></i>
                  </OverlayTrigger>
                </h6>
              </div>

              <div className="target-tab-content">
                <Suspense fallback={<div>Loading...</div>}>
                  <TargetBasic {...props} />
                </Suspense>

                <br />
                <span className="history-link" onClick={handleTargetOverlay}>
                  <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
                    {" "}
                    Goto Target Tables{" "}
                  </button>
                </span>
                <br />
              </div>
            </div>
          </div> */}

    
           
         

            <div className="">
              {/**** Inferences/ Summary******/}

              <div className="">
                <div className="sub-section-body">
                  {summaryDone === "done" ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      {/* <Inference patientId={id} summary={displaySummaryTable} /> */}
                    </Suspense>
                  ) : null}
                  <br />
                  {/* <span
                    className="history-link"
                    onClick={handleInferenceOverlay}
                  >
                    <button className="btn btn-primary btn-col btn-col btn-smallest btn-square">
                      {" "}
                      View Details
                    </button>
                  </span> */}
                  <br />
                </div>
              </div>
            </div>
          </div>

          {/*****************Diagnostic section ************/}
          <div className="each-section summary  section-diagnostic">
    <h5 className="sub-title head-strip">
    {" "}
    Diagnostics Info{" "}
    {/* <OverlayTrigger
        trigger="hover"
        rootClose
        placement="top"
        //overlay={renderTooltip3}
    >
        <i className="fas fa-info-circle"></i>
    </OverlayTrigger> */}
    </h5>
    <div className="second-contentbox">
        <div className="sub-section vitals-subsection diagnostics">
            <div className="sub-section-header box-theme">
                <h6 className="sub-section-title">
                    {" "}
                    Vitals{" "}
                    {/* <OverlayTrigger
                    trigger="hover"
                    rootClose
                    placement="top"
                   // overlay={renderTooltip4}
                    >
                    <i className="fas fa-info-circle"></i>
                    </OverlayTrigger> */}
                </h6>
            </div>
            <div className="sub-section-body">
                <div className="vitals-colv">
                    <div className="vitals-row">
                        <div className="">
                            <br />
                            <button
                            className="btn btn-primary btn-col"
                            onClick={handleSugar}
                            >
                            +
                            </button>
                        </div>
                    <div
                        className="each-list-title each-tile box-4 colored-box colored-box-small"
                        id="vitals-tile-small"
                        onClick={handleGlucoseOverlay}
                    >
                        <span className="history-link">
                        <h6>Blood Sugar </h6>
                        </span>
                    </div>

                    <div
                        className="each-list-title each-tile box-4 colored-box colored-box-small"
                        id="vitals-tile-small"
                        onClick={handlePulseOverlay}
                    >
                        <span className="history-link">
                        <h6> Pulse </h6>
                        </span>
                    </div>
                    <div>
                        <br />
                        <button
                        onClick={handlePulse}
                        className="btn btn-primary btn-col"
                        >
                        +
                        </button>
                    </div>
                    </div>
                    <br />
                    <br />
                    <div className="vitals-row">
                    <div>
                        <br />
                        <button
                        className="btn btn-primary btn-col"
                        onClick={handleBp}
                        >
                        +
                        </button>
                    </div>
                    <div
                        className="each-list-title each-tile box-4 colored-box colored-box-small"
                        id="vitals-tile-small"
                        onClick={handleBpOverlay}
                    >
                        <span className="history-link">
                        <h6>Blood Pressure </h6>
                        </span>
                    </div>

                    {/* <div
                        className="each-list-title each-tile box-4 colored-box colored-box-small"
                        id="vitals-tile-small"
                        onClick={handleCholOverlay}
                    >
                        <span className="history-link">
                        <h6> Cholesterol </h6>
                        </span>
                    </div>
                    <div>
                        <br />
                        <button
                        cl
                        ssName="btn btn-primary btn-col"
                        onClick={handleChol}
                        >
                        +
                        </button>
                    </div> */}
                    </div>
                </div>
            </div>
        </div>

        {/******Primary/Chronic Medical Condition*******/}
     
        {/******Chronic/Other Medical Condition******END*/}
        {/******Comorbidities*******/}
      

        {/******Chronic/Other Medical Condition******END*/}
        {/******Comorbidities*******/}
      
    </div>
    <br />
</div>









          {/*****Schedule planners****/}
          <div className="each-section summary  section-diagnostic">  
            <h5 className="sub-title head-strip">
              {" "}
              Treatment Planner{" "}
              {/* <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                //overlay={renderTooltip14}
              >
                <i className="fas fa-info-circle"></i>
              </OverlayTrigger> */}
            </h5>
            <div className="schedule-planners">
              {/************/}
              <br />

              <div style={{ gap: "10px" }} className="second-contentbox">
                <div className="next-visit-box">
                  <h5 className="font-weight-bold">Next Visit</h5>
                  <div className="d-flex flex-row justify-content-around">
                    <div className="d-flex flex-column align-self-center">
                      <h6>Select Doctor from list</h6>
                      <input
                        style={{ backgroundColor: "#fafafa" }}
                        onClick={() => {
                          setSearchAssign("");
                          setDisplayDoctor(true);
                        }}
                        value={doctorName}
                        className="form-control"
                        name="assignee"
                        onChange={(e) => {
                          e.preventDefault();

                          setDoctorName(e.target.value);
                          setDisplayDoctor(true);

                          setSearchAssign(e.target.value);
                        }}
                      />

                      {displayDoctor && (
                        <div className="suggestions-box-holder">
                          <button
                            className="btn-suggestion btn btn-secondary btn-smallest"
                            title="close"
                            onClick={() => setDisplayDoctor(false)}
                          >
                            {" "}
                            X{" "}
                          </button>

                          <div className="suggestions-box suggestions-assignee-box">
                            {assignList.map((item, i) => {
                              return (
                                <div
                                  className="scan-item"
                                  key={item.id}
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setRender(!render);
                                    setDoctorId(item.id);
                                    setDoctorName(item.full_name);
                                    setDisplayDoctor(false);
                                  }}
                                >
                                  <div className="search-item each-option-suggestion">
                                    <h6 className="">ID: {item.id} </h6>
                                    <h6 className=""> {item.full_name}</h6>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{ alignSelf: "flex-start" }}
                      className="each-list-title each-tile box-4 colored-box colored-box-small schedule-box"
                    >
                      <div>
                        <DatePicker
                          format="dd-MM-yyyy"
                          value={next}
                          onChange={(date) => {
                            handleNextVisit(date);
                          }}
                          minDate={new Date()}
                          clearIcon={null}
                        />
                        <button
                          className="delete-btn btn-smallest"
                          onClick={() => {
                            handleDeleteNextVisit1();
                          }}
                        >
                          {" "}
                          X{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <button
                style={{ alignSelf: "center" }}
                onClick={saveNextVisit}
                className="btn btn-primary btn-col btn-square btn-small"
              >
                Save Next Visit
              </button>
            </div>
            <br />

            <br />
            <br />

            <div className="each-section summary  section-diagnostic">
              <h4 className="sub-title">
                Test Schedules
                {/* <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  //overlay={renderTooltip22}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger> */}
              </h4>

              <br />

              <ReactTable
                columns={columns}
                data={testsList}
                defaultPageSize={5}
                //resizable
                className="test-react-table"
              ></ReactTable>
              <br />
{/* 
              {testsList.length < 1 ? (
                <button
                  className="btn btn-primary btn-col btn-col add-btn-react-table"
                  onClick={AddRowTest}
                >
                  {" "}
                  + Click to Add Data{" "}
                </button>
              ) : null} */}

              {deleteTestConfirmMsg === "success" ? (
                <DeleteTestConfirmPopup
                  show={deleteTestConfirmModalShow}
                  onHide={() => setDeleteTestConfirmModalShow(false)}
                  index={testIndexToDelete}
                />
              ) : (
                ""
              )}

              <div className="schedule-btn">
                <button  style={{ alignSelf: "center" }}
                  className="btn btn-primary btn-col btn-square btn-small"
                  onClick={handleSubmitTest}
                >
                  {" "}
                  Save and Submit{" "}
                </button>
              </div>
            </div>
            <br />

            <div className="each-section summary  section-diagnostic">
              <h4 className="sub-title">
                Scan Schedules
                {/* <OverlayTrigger
                  trigger="hover"
                  rootClose
                  placement="top"
                  //overlay={renderTooltip21}
                >
                  <i className="fas fa-info-circle"></i>
                </OverlayTrigger> */}
              </h4>

              <br />

              <ReactTable
                columns={columnsScans}
                data={scanList}
                defaultPageSize={5}
                //resizable
                className="scan-react-table"
              ></ReactTable>
              <br />

              {deleteScanConfirmMsg === "success" ? (
                <DeleteScanConfirmPopup
                  show={deleteScanConfirmModalShow}
                  onHide={() => setDeleteScanConfirmModalShow(false)}
                  index={scanIndexToDelete}
                />
              ) : (
                ""
              )}

              <div className="schedule-btn">
                <button  style={{ alignSelf: "center" }}
                  className="btn btn-primary btn-col btn-col btn-square btn-small"
                  onClick={handleSubmitScan}
                >
                  
                  Save and Submit
                </button>
              </div>
            </div>

            {deleteNextVisitConfirmMsg === "success" ? (
              <DeleteNextVisitConfirmPopup
                show={deleteNextVisitConfirmModalShow}
                onHide={() => setDeleteNextVisitConfirmModalShow(false)}
              />
            ) : (
              ""
            )}
          </div>

</div>

          {/********* Set Reminders******/}
         
          {testErr !== "nil" && testErr !== "false" ? (
            <ErrorPopup
              show={errorModalShow}
              onHide={() => setErrorModalShow(false)}
            />
          ) : null}

          {scanErr !== "nil" && scanErr !== "false" ? (
            <ErrorScanPopup
              show={errorScanShow}
              onHide={() => setErrorScanShow(false)}
            />
          ) : null}

     

          {nextErr !== "nil" ? (
            <ErrorNextPopup
              show={errorNextModalShow}
              onHide={() => setErrorNextModalShow(false)}
            />
          ) : null}




 

          {glucoseShow ? (
            <GlucosePopup
              show={glucoseShow}
              onHide={() => setGlucoseShow(false)}
              id={id}
            />
          ) : null}

          {bpShow ? (
            <BpPopup show={bpShow} onHide={() => setBpShow(false)} id={id} />
          ) : null}

          {pulseShow ? (
            <PulsePopup
              show={pulseShow}
              onHide={() => setPulseShow(false)}
              id={id}
            />
          ) : null}

          {cholesterolShow ? (
            <CholesterolPopup
              show={cholesterolShow}
              onHide={() => setCholesterolShow(false)}
              id={id}
            />
          ) : null}

 

          {/* {inferenceShow ? (
            <InferencePopup
              show={inferenceShow}
              onHide={() => setInferenceShow(false)}
            />
          ) : null} */}


{
          submitMsg1 === "error" ? (
            <SubmitErrorPopup1
              show={errorSubmit1}
              onHide={() => {
                setErrorSubmit1(false);
                setSubmitMsg1("");
              }}
            />
          ) : null
            }
          {deleteNextMsg !== "" ? (
            <DeletePopUp
              show={deleteNextModalShow}
              onHide={() => {
                setDeleteNextModalShow(false);
                setReRender(!reRender);

                setDeleteNextMsg("");
              }}
            />
          ) : (
            ""
          )}
          {submitMsg === "success" ? (
            <SubmitPopUp
              show={modalShow}
              onHide={() => {
                setModalShow(false);
                setReRender(!reRender);
                setErrorMessage("");
                setDoctorId("");
                 setDoctorName(doctorName);
                setNext_visit(null);
                setNext(null);
                setSearchAssign("");
                setSubmitMsg("");
              }}
            />
          ) : submitMsg === "error" ? (
            <SubmitErrorPopup
              show={errorSubmit}
              onHide={() => {
                setErrorSubmit(false);
                setSubmitMsg("");
              }}
            />
          ) : (
            ""
          )}

          {sizeShow ? (
            <SizePopUp
              show={sizeShow}
              onHide={() => {
                setSizeShow(false);
              }}
            />
          ) : null}

          {uploadShow ? (
            <LoadPopUp
              show={uploadShow}
              onHide={() => {
                setUploadShow(false);
              }}
            />
          ) : null}

          {validShow ? (
            <ValidPopUp
              show={validShow}
              onHide={() => {
                setValidShow(false);
              }}
            />
          ) : null}

          {contentShow ? (
            <ContentPopUp
              show={contentShow}
              onHide={() => {
                setContentShow(false);
              }}
            />
          ) : null}

          {popupShow ? (
            <EditPopup
              show={popupShow}
              onHide={() => {
                setPopupShow(false);
              }}
              onSave={() => {
                if (imgFile !== null) {
                  setPopupShow(false);
                  const scans = scanList;
                  scans[index].scan_details.image = imgFile;
                  setScanList(scans);
                  setEditSuccess(true);
                }
              }}
            />
          ) : null}

          {imgEditShow ? (
            <ImgEditPopup
              show={imgEditShow}
              onHide={() => {
                setImgEditShow(false);
              }}
              onSave={() => {
                if (imgFile !== null) {
                  setImgEditShow(false);
                  const tests = testsList;
                  tests[index].labtest_reason.image = imgFile;
                  setTestsList(tests);
                  setEditSuccess(true);
                }
              }}
            />
          ) : null}

          {remarkViewShow ? (
            <RemarkViewPopup
              show={remarkViewShow}
              onHide={() => {
                setRemarkViewShow(false);
              }}
            />
          ) : null}

          {editSuccess ? (
            <DetailsUpdateSuccessPopUp
              show={editSuccess}
              onHide={() => {
                setEditSuccess(false);
              }}
            />
          ) : null}

          {addShow ? (
            <AddPopup
              show={addShow}
              onHide={() => {
                setAddShow(false);
              }}
            />
          ) : null}

          {editShow ? (
            <DetailPopup
              show={editShow}
              onHide={() => {
                setEditShow(false);
              }}
              onSave={() => {
                if (sendData !== scanList[cell].scan_details) {
                  setEditShow(false);
                  setEditSuccess(true);
                  const scans = scanList;
                  scans[cell].scan_details = sendData;
                  setScanList(scans);
                }
              }}
            />
          ) : null}

          {viewShow ? (
            <ViewPopup
              show={viewShow}
              onHide={() => {
                setViewShow(false);
                setFlag(false);
              }}
            />
          ) : null}
  



          {reasonAddShow ? (
            <ReasonAddPopup
              show={reasonAddShow}
              onHide={() => {
                setReasonAddShow(false);
              }}
            />
          ) : null}

          {reasonEditShow ? (
            <ReasonEditPopup
              show={reasonEditShow}
              onHide={() => {
                setReasonEditShow(false);
              }}
              onSave={() => {
                if (sendData !== testsList[cell].labtest_reason.reason) {
                  setReasonEditShow(false);
                  setEditSuccess(true);
                  const tests = testsList;
                  tests[cell].labtest_reason.reason = sendData;
                  setTestsList(tests);
                }
              }}
            />
          ) : null}

          {messagesShow === false ? (
            <div
              className="dr-msgs-basic-sticky "
              onClick={handleMessagesOverlay}
              title="Message via Felixa Messenger"
            >
              <i className="fas fa-comments messenger-icon"></i>
            </div>
          ) : null}

          {/*
   <div className="dr-msgs-basic-sticky">
       <HospDrMessagesBasic patientId={id}/>

  </div>
  */}
        </div>
      

      {messagesShow ? (
        <MessagesPopup show={messagesShow} onHide={handleTrack} id={id} />
      ) : null}





      {weightAddShow ? (
        <WeightAddPopup
          show={weightAddShow}
          seteditweight={weight}
          onHide={() => {
            setWeightAddShow(false);
          }}
        />
      ) : null}

      {heightAddShow ? (
        <HeightAddPopup
          show={heightAddShow}
          seteditheight={height}
          onHide={() => {
            setHeightAddShow(false);
          }}
        />
      ) : null}

      {addPulse ? (
        <AddPulsePopup
          show={addPulse}
          onHide={() => {
            setAddPulse(false);
          }}
        />
      ) : null}

      {addBp ? (
        <AddBpPopup
          show={addBp}
          onHide={() => {
            setAddBp(false);
          }}
        />
      ) : null}
      {addChol ? (
        <AddCholestrolPopUp
          show={addChol}
          onHide={() => {
            setAddCholestrol(false);
          }}
        />
      ) : null}

      {addSugar ? (
        <AddSugarPopup
          show={addSugar}
          onHide={() => {
            setAddSugar(false);
          }}
        />
      ) : null}
    </>
  );
}
export default PatientRecord;
