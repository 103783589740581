
import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, Navigate, NavLink } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import 'font-awesome/css/font-awesome.min.css';
import HospDocNavbar from "../HospDocNavbar/HospDocNavbar";
import "./doctorDashboard.css";




function DoctorDashboard() {
  const [loginDatas] = useContext(LoginContext);


  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <>
    <div>
      <HospDocNavbar/>
    </div>
    <div >

      <div className="home">
        {/* <h1>Doctor Dashboard</h1> */}
        <h2 className="title-of-page"> {login_datas.name} </h2>
        <h4 className="subtitle-of-page">{login_datas.location}</h4>

        <div className="home-container">
          <div className="home-tiles" 
          onClick={() => navigate("/patientlist")}
           >
            <i className="fa fa-user-md home-icon">
            </i>
            <h5 className="each-title"> Patient Records
            </h5>

          </div>

          {/* <div className="home-tiles" 
          // onClick={() => navigate("/doctorslist")}
          >
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">Service Requests</h5>

          </div>

          <div className="home-tiles" 
          // onClick={() => navigate("/hospital/add-bdo")}
          >
            <i className="fa fa-user-md home-icon">
            </i>
            <h5 className="each-title">Appointments</h5>

          </div>

          <div className="home-tiles"

            // onClick={() => navigate("/bdo-list")}
            >
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">Schedules</h5>

          </div>
          <div className="home-tiles"

            // onClick={() => navigate("/add-sda")}
            >
            <i className="fa fa-user-md home-icon">
            </i>
            <h5 className="each-title">Notifications</h5>

          </div> */}

          
        </div>

      </div>

    </div>
    </>


  );
}

export default DoctorDashboard;