import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import { Button } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import AddSubservice from "./AddSubservice";
import EditSubservice from "./EditSubservice"
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
function ServiceDetails(props) {

    const { id } = useParams(); //

    const [serviceName, setServiceName] = useState("")
    const [consentLevelName, setConsentLevelName] = useState('');
    const [consentLevelUsers, setConsentLevelUsers] = useState([]);
    const [addServiceShow, setAddServiceShow] = useState(false)

    const [serviceList, setServiceList] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage,] = useState(10);

    const [deleteShow, setDeleteShow] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState('');
    const [editShow, setEditShow] = useState(false)
    const [editIndex, setEditIndex] = useState('');
    const [subserviceName, setSubserviceName] = useState("");




    useEffect(() => {


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        axios.get(`${APIURL}/api/v1/staff/service-list-detail/${id}`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.status === 200) {
                    const datas = res.data

                    setServiceName(datas.service_name);
                    setConsentLevelName(datas.consent_level_name)
                    setConsentLevelUsers(datas.consent_level_users)


                }
                else {
                    //  setErrorShow(true)
                }
            })
            .catch((err) => {
                // setErrorShow(true)
            })
    }, [])

    const DeleteConfirmPopup = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false} >

                <Modal.Body >

                    <h4 className="title-of-page"> Delete Sub Service!!  </h4>
                    <h5 className="title-of-page" > Please Confirm to Delete the Service  {subserviceName}</h5><br />

                    <div className="form-group " style={{ marginLeft: "120px" }}>
                        <button type="button" className="btn btn-danger btn-cancel" onClick={props.onHide}> Cancel </button>
                        <button type="submit" className="btn btn-primary btn-col" onClick={() => { handleDelete(deleteIndex) }} >Confirm </button>
                    </div>
                </Modal.Body>

            </Modal>
        );
    }
    const handleDelete = (item) => {


        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '');
        axios.delete(`${APIURL}/api/v1/staff/sub-service-detail/${deleteIndex}/`,
            {
                headers: {

                    "Authorization": 'Token ' + str,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {


                if (res.status === 204) {

                    loadData();

                }
                else {
                    console.log('else called ');
                }


                setDeleteIndex('');
                setDeleteShow(false);

            })
            .catch(err => {
                console.log("catch called");

            })

    }


    const loadData = () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        axios
            .get(`${APIURL}/api/v1/staff/sub-service-list/?main_service=${id}`, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {


                if (res.status === 200) {

                    const datas = res.data.sub_services;

                    setServiceList(datas);

                }


                else {

                }
            })
            .catch((err) => {

            });
    }

    useEffect(() => {
        loadData();


    }, [])

    const onSuccess = () => {
        loadData();
        setAddServiceShow(false);
        setEditShow(false);
    }

    const AddServicePopUp = (props) => {
        return (
            <Modal
                {...props}
                aria-labelledby="example-custom-modal-styling-title"
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-medium"
            >  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">


                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <AddSubservice onSuccess={onSuccess} id={id} />


                </Modal.Body>

            </Modal>
        )
    }


    const EditPopup = (props) => {
        return (
            <Modal
                {...props}
                aria-labelledby="example-custom-modal-styling-title"
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-medium"
            >  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">


                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <EditSubservice onSuccess={onSuccess} sub_service={subserviceName} id={editIndex} />


                </Modal.Body>

            </Modal>
        )
    }

    const handleDeletePopUp = (id, name) => {
        setDeleteIndex(id);
        setSubserviceName(name);
        setDeleteShow(true);

    }
    const handleEditPopUp = (id, name) => {
        setEditIndex(id);
        setEditShow(true);
        setSubserviceName(name);
    }

    const handleSearchChange = (e) => {
        e.preventDefault();
        setCurrentPage(1)
        setSearchTerm(e.target.value)
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }


    let data = serviceList ? serviceList.sort((a, b) => {
        if (a.sub_service.toLowerCase() < b.sub_service.toLowerCase()) return -1;
        if (a.sub_service.toLowerCase() > b.sub_service.toLowerCase()) return 1;
        return 0;
    })
        .filter((value) => {
            if (searchTerm === '') {
                return value
            } else if (value.service_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                return value
            }
        }) : [];

    const indexLast = currentPage * perPage;
    const indexFirst = indexLast - perPage;
    const current = data.slice(indexFirst, indexLast);
    const total = data.length;
    const pageNumbers = []
    for (let i = 0; i <= Math.ceil(total / perPage); i++) {
        pageNumbers.push(i)
    }
    const totalPages = pageNumbers.length;

    let display = current.length > 0 ? current.map((data, index) => {


        return (


            <div className='list-item'>

                {" "}
                <h4 className="each-list-link">
                    <Link to={{
                        pathname: `/service-details/${data.id}`
                    }}>
                        {(currentPage - 1) * 10 + index + 1}.{data.sub_service}{" "}
                    </Link>
                    <i class="fa fa-trash-o" onClick={() => handleDeletePopUp(data.id, data.sub_service)} style={{ float: "right", color: "red" }} ></i>
                    <i class="fa fa-pencil" onClick={() => handleEditPopUp(data.id, data.sub_service)} aria-hidden="true" style={{ float: "right", paddingRight: "25px", color: "blue" }}></i>


                </h4>


            </div>

        )
    }) : null
    return (<>
      
<BackofficeNavBar/>
        <div className="container">
            <div className="container failedlist-parentcontainer">


                <div className="title-of-page"><h2 className="name">{serviceName}</h2></div>

                <div style={{ paddingLeft: "10%", color:"#48C9B0" }} >

                    <h5><b>Consent Level :</b>{consentLevelName}</h5>

                    <h5 ><b>Consent Level Users : </b>{consentLevelUsers.map((item) => <h5 style={{ display: "inline" }}>{item}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span></h5>)}</h5>

                </div>

          
        <div className="formdata1 ">
        <div className="formdata1"  style={{display:"row", color:"#3DA5EA "}}><h4  className="name">Sub Services
        <Button  style={{width:"20%",float:"right"}}  className="btn btn-primary btn-sm btn-col" onClick={() => setAddServiceShow(true)} >
            Add a SubService <i className="fa fa-plus"></i>
        </Button></h4></div>
            
            <div className="search-section">
  
       
  <div className="search-div">
                <form style={{ display: "flex" }}>


                    <input
                        className="form-control search-input"
                        type="text"
                        placeholder="Search a Service"
                        value={searchTerm}
                        maxLength="30"
                        onChange={handleSearchChange}
                    />
                    <button type="submit" className="btn btn-primary btn-col search-btn">
                        {" "}
                        <i className="fas fa-search"></i>{" "}
                    </button>

                </form>
            </div>
           </div>
           <br/>
            <div className="failed-list-section">
                <div className="container">
                    <div className="test-reports-display-list">
                        {display ? (
                            display
                        ) : (
                            <div style={{ color: "red", fontSize: "25px", height: "200px", padding: "40px" }}>
                                <h4>No list to show!!</h4>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>
        </div>

</div>


        {totalPages > 1 ?
            <div className="pagn pagn-small">
                <br />
                <br />
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={totalPages}
                    onChange={handlePageChange}
                    disabledClass="disabled-class"
                />
            </div>
            : null}





        {
            addServiceShow ?
                <AddServicePopUp
                    show={addServiceShow}
                    onHide={() => setAddServiceShow(false)}

                /> : null
        }

        {
            deleteShow ?
                <DeleteConfirmPopup
                    show={deleteShow}
                    onHide={() => {
                        setDeleteShow(false);
                        setDeleteIndex('');

                    }}

                /> : ''
        }
        {
            editShow ?
                <EditPopup
                    show={editShow}
                    onHide={() => {
                        setEditShow(false);
                        setEditIndex('');

                    }}

                /> : ''
        }
    </>
    )
}
export default ServiceDetails