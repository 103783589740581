import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProfCard from "../Professionals/ProfCard";

import "./proflist.css";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { APIURL } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";

function ProfList() {
  const [profList, setProfList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
proflist();
  }, []);

  const proflist=()=>
  {
    debugger;
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/allied-professional/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + v2,
        },
      })
      .then((res) => {
        if (res.status ==200) {
          debugger
          const datas = res.data;

          setFreeCount(res.data.free_user_count);
          setActiveCount(res.data.active_free_users);
          
         
          setProfList(datas);
        } else {
          setErrorMsg("error");
        }
      })
      .catch((err) => {
        setErrorMsg("error");
      });
  }

  const refreshHandle = () => {
    setRefresh(!refresh);
    proflist();
  };

  return (
    <div className="drlist-page">
    <HospitalNavbar/>

      <h2 className="title-of-page"> Allied Professionals List</h2>
      <div className="quick-links">
        <Link to="/add-professional">
          <button type="button" className="btn btn-primary btn-col dr-btn">
            {" "}
            Add Professionals to List{" "}
          </button>
        </Link>
        {/* <Link to={{ pathname: "license-details", flag: "bdo-page" }}> */}
          {/* <button
            type="button"
            style={{ marginLeft: "10px" }}
            className="btn btn-info dr-btn"
          >
            {" "}
            {t("View Licence Details")}
          </button>
        </Link> */}
      </div>

      {profList.length !== 0 ? (
        <div>
          <div className="drs-container">
            {profList.length > 0
            
              ? profList.map((data, index) => {
           
                  return (
                    <ProfCard
                      key={index}
                      data={data}
                      // freeCount={freeCount}
                      // activeCount={activeCount}
                      handle={refreshHandle}
                    />
                
                  );
                })
              : null}
          </div>

          <div className="quick-links">
            <Link to="/add-professional">
              <button type="button" className="btn btn-primary btn-col dr-btn">
                {" "}
                 Add Professionals to List{" "}
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <h5 style={{ color: "red", margin: "20px" }}>Professionals List is empty </h5>
      )}

      {errorMsg === "error" ? (
        <h5 style={{ color: "red" }}>
          Error in data fetching.Please try again!
        </h5>
      ) : null}
    </div>
  );
}

export default ProfList;
