import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

function AddPulse(props) {

    const [pulse, setPulse]=useState()
    const [modalShow, setModalShow]=useState(false)
    const [errorShow, setErrorShow]= useState(false)
    const [limitShow , setLimitShow]= useState(false)


    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateTime = toISOString(Date().toLocaleString());
  function toISOString(s) {
    var months = {jan:'01',feb:'02',mar:'03',apr:'04',may:'05',jun:'06',
                  jul:'07',aug:'08',sep:'09',oct:'10',nov:'11',dec:'12'};
    var b = s.split(' ');
  
    return b[3] + '-' +
           months[b[1].toLowerCase()] + '-' +
           ('0' + b[2]).slice(-2) + 'T' +
           b[4] + b[5].substr(3);
  }

    const handleChange = (e)=> {
        const val = e.target.value
        if(val.length < 4){
            setPulse(e.target.value)
        }
        else {
            return false
        }
    }

    const handleSubmit = () => {

        if(pulse < 150) {

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               'vital_name':"pulse",
               'result' :pulse,
               'date_time':currentDateTime
           }
     
     
       
         axios.post(`${APIURL}/api/v1/patient/vitals/?patient_id=${id}&timeZone=${timezoneOffset}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
                setModalShow(true)
         
          
       }
       else{
            setErrorShow(true)
        
       }
     
         })
         .catch(err=>{
       
            setErrorShow(true)
     })

        }
        else {
            setLimitShow(true)
        }

        
    }

    const SubmitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-success'>Pulse added Successfully !!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const ErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> Error in data submission.Please check again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const LimitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-muted'>Please check Pulse value</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

    return (
        <div className='flex-col'>
           <input style={{width:'50%'}} type="number" min='0' max='150' placeholder="Add Pulse" value={pulse} onChange={handleChange} className="form-control"/><br/>
           <button disabled={pulse<1 || pulse=== undefined} onClick={handleSubmit} className='btn btn-primary btn-col'>Add</button> 


           {
      modalShow ? 
      <SubmitPopup
        show={modalShow}
        onHide= {() => { setModalShow(false); props.submitpulse() ;setPulse("")     
        }}
      /> : null
    }

{
      errorShow ? 
      <ErrorPopup
        show={errorShow}
        onHide= {() => { setErrorShow(false)     
        }}
      /> : null
    }

{
      limitShow ? 
      <LimitPopup
        show={limitShow}
        onHide= {() => { setLimitShow(false)     
        }}
      /> : null
    }

        </div>
    )
}

export default AddPulse
