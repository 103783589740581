
/*export const APIURL= "https://pc.felixacare.com";

https://api.cianlogic.com/
*/

let backendHost;


const hostname = window && window.location && window.location.hostname;

if(hostname === 'pcpweb.cianlogic.com') {
  backendHost = 'https://pcpapi.cianlogic.com';
} else if(hostname === '3dweb.cianlogic.com') {
  backendHost = 'https://3dapi.cianlogic.com';
}else if(hostname === 'localhost') {
  backendHost = 'https://pcpapi.cianlogic.com';
}

export const APIURL = `${backendHost}`;




