import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";

function EditTest(props) {

    const[data, setData]= useState({
      id: props.data.id,
      testname: props.data.test_name,
        testsample: props.data.test_sample,
        testmethod: props.data.test_method,
        unit: props.data.unit,
        minrefrange: props.data.min_ref_range,
        maxrefrange: props.data.max_ref_range,
        cptcode: props.data.cpt_code,
        description: props.data.description,
       disease: props.data.asso_disease,
    })
   const value = props.data.group ? props.data.group.toLowerCase() : '';
    const label = props.data.group ? props.data.group.toUpperCase() : '';
    const [group, setGroup]= useState({value:value, label: label})
   const [country1, setCountry1]= useState({value:props.data.country, label: props.data.country}
    )
    const [error, setError] = useState({
      testname: "",
      testsample: "",
      testmethod: "",
      unit: "",
      minrefrange: "",
      maxrefrange: "",
      group: "",
      country: "",
      //is_verified:""
    });

    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

   // const [year, setYear]= useState(data.year_of_manufacture=== null ? '' : new Date(data.year_of_manufacture))
    //const [expiry, setExpiry]= useState(data.expiry_date=== null ? '' : new Date(data.expiry_date))

  //  const [yearData, setYearData]= useState(data.year_of_manufacture)
    //const [expiryData, setExpiryData]= useState(data.expiry_date)
    const group1 = [
        { value: "male", label: "MALE" },
        { value: "female", label: "FEMALE" },
        { value: "kids", label: "KIDS" },
        { value: "pregnancy", label: "PREGNANCY" },
        { value: "foetal", label: "FOETAL" },
        { value: "others", label: "OTHERS" },
        { value: "na", label: "NA" },
      ];
    
      const country = [
        { value: "India", label: "INDIA" },
        { value: "US", label: "USA" },
      ];

const handleServiceChange= (data) => {
  setGroup(data)
}

const handleUnitChange= (data) => {

  setCountry1(data)
}


    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Updated Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

//       const handleServiceChange= (data) => {

//         setType(data)
//    }

//    const handleUnitChange= (data) => {

//         setUnit(data)
//   }

//   const handleRequest = (e)=> {
//    const val = e.target.value
//    const key = e.target.name

//    setData((current) => ({
//        ...current , [key] : val==='true'? true : val==='false'? false : null
//    }))

//   }

//   const handleYear = (date)=> {
//     setYear(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setYearData(DateExtracted);
      
//       }
//   }

//   const handleExpiry = (date)=> {
//     setExpiry(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setExpiryData(DateExtracted);
      
//       }
//   }

const validate = () => {
  ;
  let input = data;
  let errors = {};
  let isValid = true;
  setError({});

  if (!input["testname"] || input["testname"] === "") {
    isValid = false;
    errors["testname"] = "Please enter Test Name";
  }

  if (typeof input["testname"] !== "undefined") {
    var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    if (pattern3.test(input["testname"])) {
      isValid = false;
      errors["testname"] = "Special Characters are not allowed";
    }
  }
  if (!input["testsample"] || input["testsample"] === "") {
    isValid = false;
    errors["testsample"] = "Please enter a sample value";
  }
  if (!input["testmethod"] || input["testmethod"] === "") {
    isValid = false;
    errors["testmethod"] = "Please enter test method";
  }
  if (typeof input["testmethod"] !== "undefined") {
    var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    if (pattern3.test(input["testmethod"])) {
      isValid = false;
      errors["testmethod"] = "Special Characters are not allowed";
    }
  }
  if (!input["unit"] || input["unit"] === "") {
    isValid = false;
    errors["unit"] = "Please enter unit";
  }
  if (!input["minrefrange"] || input["minrefrange"] === "") {
    isValid = false;
    errors["minrefrange"] = "Please enter minimum refer range";
  }
  if (input["minrefrange"]  <= 0) {
    isValid = false;
    errors["minrefrange"] = "Please enter valid minimum refer range";
  }
  if (!input["maxrefrange"] || input["maxrefrange"] === "") {
    isValid = false;
    errors["maxrefrange"] = "Please enter maximum refer range";
  }
  if (input["maxrefrange"]  <= 0) {
    isValid = false;
    errors["maxrefrange"] = "Please enter valid maximum refer range";
  }

  if (!group1 || (input.group1 && !group1.value) ) {
    isValid = false;
    errors["group"] = "Please select group";
  }
  // if (!input["country"] || input["country"] === "") {
  //   isValid = false;
  //   errors["country"] = "Please select country";
  // }


  setError((currentstate) => ({
    ...currentstate,
    ...errors,
  }));

  return isValid;
};


const saveChanges = (e)=> {
    e.preventDefault();
    if (validate()) {

        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
     const dataToSend = {
        test_name: data.testname,
        test_sample: data.testsample,
        test_method: data.testmethod,
        unit: data.unit,
        min_ref_range: data.minrefrange,
        max_ref_range: data.maxrefrange,
        is_verified : false,
        cpt_code: data.cptcode,
        description: data.description,
       asso_disease: data.disease,
      };
      if (country1 && country1.value) {
        dataToSend.country = country1.value;
      }
      if (group && group.value) {
        dataToSend.group = group.value;
      }
 
     axios
       .put(`${APIURL}/api/v1/staff/master-labtest-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.status==200) {
          setSuccessShow(true);
          setData({
            testname: "",
    testsample: "",
    testmethod: "",
    unit: "",
    minrefrange: "",
    maxrefrange: "",
    group: { value: "", label: "" },
    country1: { value: "", label: "" },
    cptcode: "",
    description: "",
    disease: "",
         });}
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }
  }




    return (
        <>
       

    <h2 style={{ marginTop: "0" }} className="title-of-page">
        Edit Lab Test
      </h2>
      <div style={{ maxHeight: "1000px" }} className="config-box fit-content">
        <div className="feature-data pad-features">
          <div className="flex-col justify-feature">
            <span className="align-rental">* Test Name: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testname"
              value={data.testname}
              onChange={handleTitle}
            />
           {error.testname ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testname}
              </div>
            ) : null}
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">* Test Method : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testmethod"
             
              value={data.testmethod}
              onChange={handleTitle}
            />
            {error.testmethod ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testmethod}
              </div>
            ) : null}
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">* Test Sample : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testsample"
             
              value={data.testsample}
              onChange={handleTitle}
            />
            {error.testsample ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testsample}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">* Unit in rg/gh: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="unit"
              min="0"
              max="100"
              value={data.unit}
              onChange={handleTitle}
            />
           {error.unit ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.unit}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">* Min Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="minrefrange"
              min = "0"
              max = "100"
              value={data.minrefrange}
              onChange={handleTitle}
            />
            {error.minrefrange ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.minrefrange}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">* Max Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="maxrefrange"
              min = "0"
              max = "100"
              value={data.maxrefrange}
              onChange={handleTitle}
            />
            {error.maxrefrange ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.maxrefrange}
              </div>
            ) : null}
          </div>
         <div className="flex-col justify-feature">
            <span className="align-rental">CPT Code: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="cptcode"
              value={data.cptcode}
              onChange={handleTitle}
            />
           
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Associate Disease: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="disease"
              value={data.disease}
              onChange={handleTitle}
            />
             
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Description: </span>
            <textarea
              type="text"
              className="form-control title-flex"
              name="description"
              value={data.description}
              onChange={handleTitle}
            />
            
          </div>

          <div className="flex-row justify-feature">
            <span className="align-rental">* Group: </span>
            <Select
              defaultValue={group}
              onChange={(data, e) => {
                handleServiceChange(data, e);
              }}
              options={group1}
              name="group1"
              className="select-currency select-flex title-flex"
            />
            {  error.group ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.group}
              </div>
            ) : null}
          </div>

           {/* <div className="flex-row name-content">
            <span className="align-rental">Country: </span>
            <Select
              value={data.country1}
              onChange={(data, e) => {
                handleUnitChange(data, e);
              }}
              options={country}
              name="country1"
              className="select-currency select-flex"
            />
          
          </div> */}
        
       
        </div>

        <div className="text-center">
          <button onClick={saveChanges} className="btn btn-primary btn-col mb-2">
            Save
          </button>
        </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {
  setSuccessShow(false);
  props.modalClose();
  }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
            
        </div>
        </>
    )
}

export default EditTest
