

import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate, Navigate, NavLink } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import 'font-awesome/css/font-awesome.min.css';
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import "./hospital.css";
//import Navbar from "../common/Navbar"
//mport Footer from "../common/Footer";



function HomePage() {
  const [loginDatas] = useContext(LoginContext);


  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <div >
      {/* <Navbar /> */}
      <div className="home">
      <HospitalNavbar/>
        <h2 className="title-of-page"> {login_datas.name} </h2>
        <h4 className="subtitle-of-page">{login_datas.location}</h4>

        <div className="home-container">
       
          <div className="home-tiles" onClick={() => navigate("/adddoctor")} >
            <i className="fa fa-user-md home-icon">
            </i>
            <h5 className="each-title"> Add Doctor
            </h5>

          </div>

          <div className="home-tiles" onClick={() => navigate("/doctorslist")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">Doctors List</h5>

          </div>

          <div className="home-tiles"

            onClick={() => navigate("/add-bdo")}>
            <i className="fa fa-user home-icon">
            </i>
            <h5 className="each-title">Add BDO</h5>

          </div>

          <div className="home-tiles"

            onClick={() => navigate("/bdo-list")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">BDO List</h5>

          </div>
          <div className="home-tiles"

            onClick={() => navigate("/add-sda")}>
            <i className="fa fa-user home-icon">
            </i>
            <h5 className="each-title">Add SDA</h5>

          </div>

          <div className="home-tiles"

            onClick={() => navigate("/sda-list")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">SDA List</h5>

          </div>
           <div className="home-tiles"

            onClick={() => navigate("/add-Pharm-user")}>
            <i className="fa fa-user home-icon">
            </i>
            <h5 className="each-title">Add Pharmacy User</h5>

          </div>
          <div className="home-tiles"

            onClick={() => navigate("/pharmacyusers-list")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">Pharmacy Users List</h5>

          </div>

          <div className="home-tiles"

            onClick={() => navigate("/add-labuser")}>
            <i className="fa fa-user home-icon">
            </i>
            <h5 className="each-title">Add Lab User</h5>

          </div>
          <div className="home-tiles"

            onClick={() => navigate("/labusers-list")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">Lab Users List</h5>

          </div> 
          <div className="home-tiles"

onClick={() => navigate("/add-professional")}>
<i className="fa fa-plus-square home-icon">
</i>
<h5 className="each-title">Add Allied Professional</h5>

</div> 
 <div className="home-tiles"

            onClick={() => navigate("/professional-list")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">Allied Professionals List</h5>

          </div> 
          <div className="home-tiles"

onClick={() => navigate("/add-nurse")}>
<i className="fa fa-medkit home-icon">
</i>
<h5 className="each-title">Add Nurse</h5>

</div> 
 <div className="home-tiles"

            onClick={() => navigate("/nurse-list")}>
            <i className="fa fa-address-card home-icon">
            </i>
            <h5 className="each-title">Nurses List</h5>

          </div> 

        </div>


        {/* <div
                    // onClick={() => history.push("/doctors-list")}
                    className="each-list-title col-lg-2"
                  >
                    <i
                      className="fa fa-address-card task-icon"
                      id="task-icon-color"
                      aria-hidden="true"
                    ></i>
                    <h5 className="each-title">Add Doctor</h5> */}
        {/* </div> */}

      </div>
      {/* <Footer /> */}
    </div>

  );
}

export default HomePage;