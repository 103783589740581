import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL } from "../../Global";
import { useNavigate } from "react-router-dom";

function AddNewService({mainid, id, servicename }) {
    let navigate = useNavigate();
    const [error, setError] = useState("")
    const [amount, setAmount] = useState(0)
    const [vat, setVAT] = useState(0)
    
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        if (key === "amount") {
            setAmount(val);
        }
        if (key === "vat") {
            setVAT(val);
        }
    };

    const sendRequest = () => {
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */

        const dataToSend = {

            amount: amount,
            service: id,
            currency: "inr",
            is_active: 'true',
            vat: vat,
            main_service:mainid
        }

        axios
            .post(`${APIURL}/api/v1/service-provider/hospital-services/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.status === "success") {
                    navigate("/selected-services")

                } else {
                    setError(res.data.message)
                }
            })
            .catch((err) => {
                setError(err.response.data.amount)
            });
        // setSuccessShow(false)

    }
    return (
        <div className="appForm" >
            <div className="">

                <div className="formdata1"><h2 className="name">{servicename}</h2></div>
                <div className="formFields">

                    <div className="formField">
                        <label>Amount: </label>
                        <input
                            type="number"
                            className="formFieldInput"
                            name="amount"
                            value={amount}
                            onChange={handleChange}
                            required
                        ></input>
                    </div>
                    <div className="formField">
                        <label>VAT: </label>
                        <input
                            type="text"
                            className="formFieldInput"
                            name="vat"
                            value={vat}
                            onChange={handleChange}
                            required
                        ></input></div>,

                    <div className="formField">
                        <button onClick={sendRequest} style={{ marginLeft: "40%" }} className="btn formFieldButton">Add </button>
                        {error ? (
                            <div className="error-validation-msg">
                                {error}
                            </div>
                        ) : null}

                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddNewService