import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./staffdashboard.css";
import medicine from "../../assets/images/icons/images.png";
import scan from "../../assets/images/icons/scan.png";
import labtest from "../../assets/images/icons/labtest.png";
import steth from "../../assets/images/icons/service.png";
import BackofficeNavBar from "../BackoffcieNav/BackofficeNavBar";

function StaffDashboard() {

  const navigate = useNavigate();
  const [keys,] = useState({ 'h': false, 'd': false, 'u': false, 'r': false, 'l': false, 'i': false, 'a': false, 'p': false, 'v': false, 't': false, 'n': false });


  console.log(sessionStorage.getItem('id'))


  useEffect(() => {

    window.onkeydown = (e) => {

      keys[e.key] = true

      if (e.altKey && keys['h']) {
        navigate('/hospitallist')
      }
      if (e.altKey && keys['d']) {
        navigate('/doctorslist')
      }
      if (e.altKey && keys['u']) {
        navigate('/search-hospitals')
      }
      if (e.altKey && keys['r']) {
        navigate('/rejectedlist')
      }
      if (e.altKey && keys['l']) {
        navigate('/reportlist')
      }
      if (e.altKey && keys['i']) {
        navigate('/addsynonyms')
      }
      if (e.altKey && keys['a']) {
        navigate('/failedlist')
      }
      if (e.altKey && keys['p']) {
        navigate('/prescriptionlist')
      }
      if (e.altKey && keys['v']) {
        navigate('/written-prescriptionlist')
      }
      if (e.altKey && keys['t']) {
        navigate('/testlist')
      }
      if (e.altKey && keys['n']) {
        navigate('/nutrition')
      }

    }
    window.onkeyup = (ev) => {

      keys[ev.key] = false
    }

    return () => {
      window.onkeydown = null
      window.onkeyup = null
    }
  }, []);

  const handleBack = () => {
    Navigate.goBack();
  }

  return (
    <>
    <div>
      <BackofficeNavBar/>
    </div>
      {/* <div className="text-left">

        <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button>


      </div> */}
      <div>

        <div className="home">
          <h3 className="title-of-page"> Customer Management</h3>
          <div className="home-container">
            <div className="home-tiles" >
              <Link to="/hospitallist" >
                <img src={medicine} className="home-icon" width="65px" />
                <h4 className="each-title">
                  {" "}
                   Service Providers </h4>
                <h6 className="each-subtitle">Pending Approval</h6>
              </Link>
            </div>

            <div className="home-tiles" >
              <Link to="/servicelist" >
                <img src={steth} className="home-icon" width="65px" />
                <h4 className="each-title">
                  {" "}
                  Service List </h4>
                  <h6 className="each-subtitle">Add Feature</h6>
              </Link>
            </div>

          </div>

          <div className="seperator divide-line"> </div>
          <h3 className="title-of-page"> Treatment Planner</h3>
          <div className="home-container">

            <div className="home-tiles" >
              <Link to="/Labtest" >
                <img src={labtest} className="home-icon" width="65px" />
                <h4 className="each-title">
                  {" "}
                  Lab Tests </h4>
                <h6 className="each-subtitle">Add Lab Details</h6>
              </Link>
            </div>

            <div className="home-tiles" >
              <Link to="/Scantest" >
                <img src={scan} className="home-icon" width="65px" />
                <h4 className="each-title">
                  {" "}
                  Scan Tests </h4>
                <h6 className="each-subtitle">Add Scan Details</h6>
              </Link>
            </div>
          </div>


        </div>
      </div>

    </>
  );
}
export default StaffDashboard;
