import React,{useState, useEffect} from "react";
import "./createpharmacyuser.css";
import axios from 'axios';

import { Link, useParams, useLocation } from "react-router-dom";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
function SubmitPopUp(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>User credentials created successfully!!</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        


        <Link to="/pharmacyusers-list"><Button className='btn btn-primary btn-col' > Goto Pharmacy Users List</Button></Link>

       
      </Modal.Footer>
    </Modal>
  );
}

function CreatePharmacyUser(props) {
     const [hospData,] =useState(JSON.parse(sessionStorage.getItem('logindatas'))? JSON.parse(sessionStorage.getItem('logindatas')) : null);
    const [modalShow, setModalShow] = useState(false);
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState(null);
   const [submitMsg, setSubmitMsg]= useState("");
    const [error,setError]=useState({
    username:"",
    password: '',
    confirm_password:"",
   
   });
   const {id} =useParams();
   const hospital_username =  hospData.hospital_username;
   const { state } = useLocation()
   const name = state.name;
  
  

   const handleUserNameChange =(e)=>{
   
   	setUsername(e.target.value);
   }

    const handlePasswordChange =(e)=>{

   	setPassword(e.target.value);
   }

    const handleConfirmPassword =(e)=>{

    setConfirmPassword(e.target.value);
   }
   const validate=async ()=>{
   
    let errors = {};
    let isValid = true;
  setError({});

if (!username || username==="" || username===null) {
      isValid = false;
      errors["username"] = "Please enter username prefix";
    }


    if (username !== "undefined") {
      var pattern1 = new RegExp(/\s/g);
      if (pattern1.test(username)) {
        isValid = false;
        errors["username"] = "Do not allow space";
      }
    }
    if (username !== "undefined") {
      const re = /^\S*$/;
      if (username.length < 3 || !re.test(username.trim())) {
        isValid = false;
        errors["username"] = "Please enter a valid username";
      }
    }


if (typeof password !== "undefined") {
        

    let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!#^%*?&])([a-zA-Z0-9@$#^!%*?&]{8,})$/);
    if (!pattern.test(password)) {
      isValid = false;
      errors["password"] = "Password must contain at least 1 UPPERCASE, 1 lower case, 1 number, 1 special character(@$!#^%*?&) and don't use space";
    }
  }
    if (!password || password==="" || password===null) {
      isValid = false;
      errors["password"] = "Please enter password";
    }

    if (!confirmPassword || confirmPassword==="" || confirmPassword=== null || typeof confirmPassword === "undefined") {
      isValid = false;
      errors["confirm_password"] = "Please enter confirm password";
    }

    if (password && typeof password !== "undefined") {
      if(password.length >0 && password.length < 8){
          isValid = false;
          errors["password"] = "Must contain atleast 8 characters";
      }
    }

    if (typeof password !== "undefined" && password!=="" && password!==null && typeof confirmPassword !== "undefined" && confirmPassword!=="" && confirmPassword!== null) {
     if (password !== confirmPassword) {
        isValid = false;
        errors["confirm_password"] = "Passwords don't match";
      }
    }
   
      


    
       await setError(currentstate=>({
            ...currentstate,
            ...errors
          }));
      

      return isValid;
  }



   const handleSubmit=async (e)=>{
    e.preventDefault();
    if(await validate()){
    callSubmit();

  }
}
   const callSubmit=()=>{
   	

   	const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

    
  const data = {
  	username: username+"@"+hospital_username,
  	password : password
  }

    axios.post(`${APIURL}/api/v1/service-provider/pharmacy-user/${id}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization:  "Token "+v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setModalShow(true);
        

          
        } else {
        
           setSubmitMsg(res.data.message);
        }
      })
      .catch((err) => {
       
         setSubmitMsg("error");
      });
  }

   
 return( 
 <>
 

 <div className="liner">
<HospitalNavbar/>
  </div>
    <div className="container">
    <h4 className="title-of-page">Pharmacy User Credentials </h4>
       <form className="create-pharm-user-form col-lg-6" onSubmit={handleSubmit}>
       
         <h5 className="pharmuser-details"> Name : {name}</h5>
          <h5 className="pharmuser-details"> User Id : {id} </h5> <br/>
       <div className="input-group mb-3">
          <input className="form-control" type="text" maxLength='30' placeholder="*Username" value={username} onChange={handleUserNameChange} />
          <div className="input-group-append">
    <span className="input-group-text postfix-username" id="basic-addon2">@{hospital_username}</span>
   
                                         
  </div>
   {error.username ?<div className="error-validation-msg"> {error.username}</div>: null} 
         </div>

         <div className="form-group ">
           <input className="form-control " type="password" maxLength='30' placeholder="*Password" value={password} onChange={handlePasswordChange} />
         {error.password ?<div className="error-validation-msg"> {error.password}</div>: null} 
           </div>
          <div className="form-group ">
           <input className="form-control " type="password" maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={handleConfirmPassword} />
         
        {error.confirm_password ?<div className="error-validation-msg"> {error.confirm_password}</div>: null} 
                                         
         </div>

  <div className="form-group ">
          <button type="submit" className="btn btn-primary btn-col" >Generate User Credentials </button>
  </div>







       </form>

        <SubmitPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

     {submitMsg!== "" ?
       <h5 style={{ color: "red" }}>
            
            {submitMsg}
          </h5>
      :
      null}

    </div>
 </>
);
}
export default CreatePharmacyUser;