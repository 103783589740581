import React, { useContext, useState, useEffect,useRef} from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import "./navbar.css";
import axios from "axios";
import { APIURL } from "../../Global";
import { LoginContext } from "../contexts/LoginContext";
import logo from "../../assets/images/cp_logo.jpg";
import { ProfileContext } from "../contexts/ProfileContext";
import { NavDropdown } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
// import { useTranslation } from "react-i18next";

function Navbar() {

    const [isLoggedIn, setIsLoggedIn] = useContext(LoginStatusContext);
    const [img, setImg] = useState("");
    const [hospImg, setHospImg] = useState();
    const [loginDatas] = useContext(LoginContext);
    const navigate = useNavigate();
    const [render] = useContext(ProfileContext);
    const [location, setLocation] = useState();
    const [logoutConfirm, setLogoutConfirm] = useState(false);
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
    );
    useEffect(() => {
      document.addEventListener("click", handleClickOutside, true);
  
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }, []);
    useEffect(() => {
        setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
    }, [loginDatas !== null]);

    
    const callLogout = () => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/account/logout/`, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
            })

            .then((res) => {
                if (res.data.status === "success") {
                } else {
                }
            })
            .catch((err) => { });

        navigate("/login");
    };

    const handleOpen = () => {
        setShow(!show);
      };
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      };
      const handleLogout1 = () => {
        setLogoutConfirm(true);
      };

    const handleLogout = () => {
      setLogoutConfirm(false);
        callLogout();
        setIsLoggedIn(false);

        sessionStorage.clear();
    };

    if (sessionStorage.getItem("usertoken")) {
        setIsLoggedIn(true);
    }

    const LogoutConfirmPopup = (props) => {
      return (
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
       
      >
        
        <Modal.Body >
        
         
       <h5 className="title-of-page" > Please Confirm to Logout </h5><br/>
       
  <div className="form-group " style={{marginLeft:"120px"}}>
         <button type="button" className="btn btn-danger btn-cancel"onClick={props.onHide}> Cancel </button> 
          <button type="submit" className="btn btn-primary btn-col" onClick={handleLogout} >Confirm </button>
          </div>
        </Modal.Body>
        
      </Modal>
      );
    };

    useEffect(() => {
        //if(localStorage.getItem("usertoken")) {
        if (isLoggedIn === true) {
          if (login_datas !== null) {
            if (login_datas.user_type === "hospital_doctor") {
              const tokenString = sessionStorage.getItem("usertoken");
    
              let str = tokenString.replace(/["]+/g, "");
    
              axios
                .get(`${APIURL}/api/v1/account/profile`, {
                  headers: {
                    Authorization: "Token " + str,
                  },
                })
                .then((res) => {
                  if (res.data.status === "success") {
                    setImg(res.data.data.photo);
                  }
                })
                .catch((err) => {});
            } else if (
              login_datas.user_type === "hospital" &&
              login_datas.verification_status === "approved"
            ) {
              const tokenString = sessionStorage.getItem("usertoken");
    
              let str = tokenString.replace(/["]+/g, "");
    
              axios
                .get(`${APIURL}/api/v1/service-provider/hospital-profile/`, {
                  headers: {
                    Authorization: "Token " + str,
                  },
                })
                .then((res) => {
                  if (res.data.status === "success") {
                    setHospImg(res.data.hospital_profile.photo);
                    setLocation(res.data.hospital_profile.location);
                  }
                })
                .catch((err) => {});
            }
          }
        }
      }, [render, isLoggedIn === true]);
    

    return (
        <>
            <nav className="nav-bar">
                <div className="logo-div">
                    <Link to="/">
                    <img className="logo-img" src={logo} alt="Logo" />
                    </Link>
                </div>
                <div className="btns">
                    {isLoggedIn ? (
                      

                      login_datas.user_type === "hospital" &&
                      login_datas.verification_status === "approved" ? (
                      <>
                        <NavDropdown
                          ref={ref}
                          style={{ zIndex: "1001" }}
                          title={
                            <>
                              <h6 className="user-name">{login_datas.name}</h6>
                              <i className="fas fa-user-circle user-icon"></i>
                            </>
                          }
                          id="collasible-nav-dropdown"
                          renderMenuOnMount={true}
                          noCaret

                          onClick={handleOpen}
                          show={show}
                        >
                          <NavDropdown.Item href="#">
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "50px",
                                height: "50px",
                              }}
                              src={hospImg}
                              alt="Img"
                            ></img>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="#">
                            {login_datas.name}
                          </NavDropdown.Item>
                          <NavDropdown.Item href="#">{location}</NavDropdown.Item>
                          <NavDropdown.Item href="#">
                            {login_datas.hospital_username}
                          </NavDropdown.Item>
                          <NavDropdown.Item>
                            <Link to="/profile">Profile</Link>
                          </NavDropdown.Item>
                        </NavDropdown>
                        <div>
                        <button
                      className="btn login-btn logout-btn"
                      onClick={handleLogout1}
                    >
                      Logout
                    </button>
                  </div>{" "}
                </>
              ) : (
                <>
                  <h6 className="user-name">
                    {login_datas && login_datas.name}
                  </h6>
                  <i className="fas fa-user-circle user-icon"></i>
                  <button
                    className="btn login-btn logout-btn"
                    onClick={handleLogout1}
                  >
                    Logout
                  </button>
                </>
              )
            ) : (
                    <>

                         <div className="pageSwitcher">
                            <NavLink
                                to="/logIn"
                                className="pageSwitcherItem signin"
                            >
                                Sign In
                            </NavLink>
                            <NavLink
                                exact
                                to="/signup"
                                className="pageSwitcherItemactive signup"
                            >
                                Sign Up
                            </NavLink>
                        </div>
                       
                        </>
 )
                    }

                </div>

            </nav>
            {logoutConfirm === true ? (
        <LogoutConfirmPopup
          show={logoutConfirm}
          onHide={() => {
            setLogoutConfirm(false);
          }}
        />
      ) : null}
        </>
    );
}

export default Navbar;
