import React, { useState, useEffect } from 'react';
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL } from "../../Global";
import { useNavigate, Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import EditSelectedService from './EditSelectedService';
import "./selectedservices.css"
import LoadingOverlay from 'react-loading-overlay';

let SelectedServices = () => {

    const [deleteShow, setDeleteShow] = useState(false);
    const [activating, setActivating] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState();
    const [editShow, setEditShow] = useState(false)
    const [editIndex, setEditIndex] = useState('');
    const [amount, setAmount] = useState(0);
    const [vat, setVat] = useState(0);
    const [subServiceList, setSubServiceList] = useState([])
    const [isActive, setIsActive] = useState(true)
    const [serviceName,setServiceName]= useState("")
    let navigate = useNavigate();
    const loadData = () => {
        const tokenString = sessionStorage.getItem("usertoken");


        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        axios.get(`${APIURL}/api/v1/service-provider/hospital-services/`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.status === 200) {
                    const datas = res.data

                    setSubServiceList(datas)

                }
                else {
                    //  setErrorShow(true)
                }
            })
            .catch((err) => {
                // setErrorShow(true)
            })


    }
    useEffect(() => {
        loadData();

    }, [])
    const onSuccess = () => {
        loadData();
        setEditShow(false);
    }
    const EditPopup = (props) => {
        return (
            <Modal
                {...props}
                aria-labelledby="example-custom-modal-styling-title"
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-medium"
            >  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">


                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <EditSelectedService onSuccess={onSuccess} amount={amount} vat={vat} id={editIndex} />


                </Modal.Body>

            </Modal>
        )
    }

    const DeleteConfirmPopup = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false} >

                <Modal.Body >

                    <h4 className="title-of-page"> Delete Service!!  </h4>
                    <h5 className="title-of-page" > Please Confirm to Delete the Service  {serviceName}</h5><br />

                    <div className="form-group " style={{ marginLeft: "120px" }}>
                        <button type="button" className="btn btn-danger btn-cancel" onClick={props.onHide}> Cancel </button>
                        <button type="submit" className="btn btn-primary btn-col" onClick={() => { handleDelete(deleteIndex) }} >Confirm </button>
                    </div>
                </Modal.Body>

            </Modal>
        );
    }
    const handleDelete = (item) => {


        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '');
        axios.delete(`${APIURL}/api/v1/service-provider/hospital-service-detail/${deleteIndex}/`,
            {
                headers: {

                    "Authorization": 'Token ' + str,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {


                if (res.status === 200) {
                    loadData();

                }
                else {
                    console.log('else called ');
                }


                setDeleteIndex('');
                setDeleteShow(false);

            })
            .catch(err => {
                console.log("catch called");

            })

    }
    const handleActivation = (is_active, id) => {
        setActivating(true);
        setIsActive(!is_active)
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */

        const dataToSend = {
            is_active: isActive

        }

        axios
            .put(`${APIURL}/api/v1/service-provider/hospital-service-detail/${id}/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {

                    onSuccess();
                    //setRefresh(true)
                    //setCompleteShow(true)
                    //setSuccessShow(false)
                } else {

                }
                setActivating(false);
            })
            .catch((err) => {
                //setErrorShow(true)
                setActivating(false);
            })
        // setSuccessShow(false)



    }
    const handleDeletePopUp = (id,name) => {
        setDeleteIndex(id);
        setServiceName(name);
        setDeleteShow(true);
    }
    const handleEditPopUp = (id, amount, vat) => {
        setEditIndex(id);
        setAmount(amount);
        setVat(vat);
        setEditShow(true);

    }
    const columns = [


        {
            Header: 'Service',
            accessor: 'services',
            minWidth: 100


        },
        {
            Header: 'Main Service',
            accessor: 'mainservices',
            minWidth: 100
        },
        {
            Header: 'Amount',
            accessor: 'amount',
            minWidth: 40
        },
        {
            Header: 'VAT',
            accessor: 'vat',
            minWidth: 40
        },
        {
            Header: "",
            accessor: 'is_active',
            minWidth: 32
        },
    ]


    const getServiceCol = (subServiceList) => {
        return subServiceList.map(item => {
            return {


                services: <h5 style={{ color: item.is_active ? "#259BE9" : "silver" }}>{item.service_name}</h5>,
                mainservices: <h5 style={{ color: item.is_active ? "#259BE9" : "silver" }}>{item.main_service_name}</h5>,
                amount: <h5 style={{ color: item.is_active ? "#259BE9" : "silver" }}>{item.amount}</h5>,
                vat: <h5 style={{ color: item.is_active ? "#259BE9" : "silver" }}>{item.vat}</h5>,
                is_active: <div>
                    <Link  style={{ color: item.is_active ? "green" : "red" }} to="#" onClick={() => { handleActivation(item.is_active, item.id) }}>{item.is_active ? <i class="fa fa-toggle-on fa-lg" aria-hidden="true"></i> : <i class="fa fa-toggle-off fa-lg" aria-hidden="true"></i>}</Link>
                    <i class="fa fa-pencil" aria-hidden="true" style={{ padding: "25px", color: "blue", fontSize: "20px" }} onClick={() => handleEditPopUp(item.id, item.amount, item.vat)}></i><span>&nbsp;&nbsp;</span>
                    <i class="fa fa-trash-o" style={{  color: "red", fontSize: "20px" }} onClick={() => { handleDeletePopUp(item.id,item.service_name) }}></i>
                    </div>

            }
        })
    }

    const data = getServiceCol(subServiceList);

    return (
        <LoadingOverlay
            active={activating}
            spinner
            text='Loading please wait...'
        >
            <div id="patient-presc-page" className="appForm padding" style={{ width: '100%' }}>
                <div className="title-of-page"><h2 className="name">Services</h2></div>
                <button className="btn btn-primary btn-col" style={{ marginLeft: "80%" }} onClick={() => navigate("/master-services")}>
                    Add New Services <i className="fa fa-plus"></i>
                </button>
                <div className="patient-presc-sections patient-presc-sections2">
                    <div className="table-holder padding">
                        <div className='appForm'>
                            <ReactTable
                                style={{
                                    color:"#259BE9",
                                    fontSize: "18px"
                                }}
                                minRows={1}
                                data={data}
                                columns={columns}
                                showPaginationBottom={false}
                            />
                        </div>
                    </div>

                </div>

            </div>

            {
                deleteShow ?
                    <DeleteConfirmPopup
                        show={deleteShow}
                        onHide={() => {
                            setDeleteShow(false);
                            setDeleteIndex('');

                        }}

                    /> : ''
            }
            {
                editShow ?
                    <EditPopup
                        show={editShow}
                        onHide={() => {
                            setEditShow(false);
                            setEditIndex('');

                        }}

                    /> : ''
            }
        </LoadingOverlay>
    );
}


export default SelectedServices;