import React ,{useState, useContext} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import './hospprofile.css'
import {Button ,Modal } from 'react-bootstrap';
import {ProfileContext} from '../contexts/ProfileContext'

function DescriptionUpdate(props) {

    const[desc ,setDesc] =useState('')
    const [show, setShow]= useState(false);
    const [submitMsg,setSubmitMsg] = useState("");
    const [errorMsg,setErrorMsg]=useState("");
    const [errorSubmit,setErrorSubmit]=useState(false);
    const [refresh ,setRefresh] =useContext(ProfileContext)

    const [error,setError]=useState({
        description:"",
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='description'){
           setDesc(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
    
          if (desc==="" ) {
            isValid = false;
            errors["description"] = "Please enter a description";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async(e) => {
        e.preventDefault();
    
        if( validate() ){
          submitDesc()
        }
      }

        const submitDesc = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
               description : desc
             }
          
             axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
                
                setSubmitMsg("success");
                setShow(true)         
            
              }else{
                setErrorMsg(res.data.message);
                setErrorSubmit(true);
              
               
              }
            
            })
            .catch(err=>{
              setErrorMsg("error");
  
              setErrorSubmit(true);
            
            })
          
          }


          const ConfirmPopUp = ({show,onHide}) => {
            return(
            <Modal
                show
                onHide
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4 id="confirm-reject">Updated Successfully</h4>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={onHide}>Ok</Button>          
          
                </Modal.Footer>
              </Modal>
            )
          }
          
          
          const SubmitErrorPopup =(props)=>{
              
          
            return (
              <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                 backdrop="static"
                  keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                   
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5 style={{color: "red"}}>Error in Data Submission. Please try again! </h5>
                 
                </Modal.Body>
                <Modal.Footer>
                 
                   <Button variant="secondary" onClick={props.onHide}>Ok</Button>
          
                 
                 
          
                </Modal.Footer>
              </Modal>
            );
          }



    return (
        <div>
            <div className="item"><textarea  className="text-area"  cols="100" rows="4" maxLength="350" name="description" placeholder="Description" onChange={handleChange}/>

                {error.description ? <div className="error-validation-msg"> {error.description}</div> : null}

            </div>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update Description</button>
            </div>

            {
         submitMsg==="success" ?
        <ConfirmPopUp
            show={show}
            onHide= { ()=>{
              setShow(false)     
              setSubmitMsg('');
              props.handle();
            } } />
            : null
      }

{errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }


        </div>
    )
}

export default DescriptionUpdate
