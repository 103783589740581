import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL } from "../../../Global";


function AddSubservice({ onSuccess,id}) {
    const [consent, setConsent] = useState(0)
    const [serviceName, setServiceName] = useState("")
    const [consentLevel, setConsentLevel] = useState(0)
    const [error, setError] = useState("")
  

    

   
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
       
       
            setServiceName(val);
      
    };
    const addService = (e) => {
        e.preventDefault();


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */

        const dataToSend = {
            sub_service: serviceName,
            main_service: id

        }

        axios
            .post(`${APIURL}/api/v1/staff/sub-service-list/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {

                    onSuccess();
                    //setRefresh(true)
                    //setCompleteShow(true)
                    //setSuccessShow(false)
                } else {
                    setError(res.data.message)
                }
            })
            .catch((err) => {
                //setErrorShow(true)
            });
        // setSuccessShow(false)






    }

    return (
        <div className="appForm" >
            <div className="">

                <div className="formdata1"><h2 className="name">Add Subservice</h2></div>
                <form className="formFields" onSubmit={addService}>

                    <div className="formField">

                        <input
                            type="text"
                            className="formFieldInput"
                            name="Servicename"
                            placeholder={"Service Name"}
                            value={serviceName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                   

                    <div className="formField">
                        <button style={{marginLeft: "30%"}} className="btn formFieldButton">Add Service +</button>
                        {error ? (
                            <div className="error-validation-msg">
                                {error}
                            </div>
                        ) : null}




                    </div>


                </form>

            </div>


        </div>

    )
}
export default AddSubservice