import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";

function AddScan(props) {

    const[data, setData]= useState({

 //   [{
      //     "id": 2,
      //     "created_name": "admin",
      //     "scan_name": "CT-SPiralwe",
      //     "is_verified": false,
      //     "scan_type": "efd",
      //     "scan_method": "colur doppler",
      //     "scan_area": "",
      //     "generic_name": "",
      //     "group": "male",
      //     "cpt_code": null,
      //     "country": "India",
      //     "description": null,
      //     "created_at": "2021-12-30T15:47:13.657258Z",
      //     "modified_at": "2021-12-30T15:47:13.657258Z"
      // },]
  
      scanname:'',
      scantype:'',
      scanmethod:'',
      scansarea:'',
      group:{ value:'', label:'' },
      cptcode:'',   
      description:'',
      country : { value:'India', label:'India' }
    })

    const [error,setError]=useState({
      scanname:'',
      scantype:'',
     
     group:'',
   
    country : ''
        
       });
       const group3 = [{ value:'male', label:'MALE' },
       { value:'female', label:'FEMALE' },  { value:'kids', label:'KIDS' },
       { value:'pregnancy', label:'PREGNANCY' },{ value:'foetal', label:'FOETAL' },{ value:'others', label:'OTHERS' },
       { value:'na', label:'NA' }
   ]


   const country3 = [{ value:'India', label:'INDIA' },
   { value:'US', label:'USA' }
  
]
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)

//     const serviceOptions = [{ value:'general', label:'General' },
//     { value:'service_request', label:'Service Request' },
//     { value:'rental', label:'Equipment Request' }
// ]

const CompletePopUp=(props)=> {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-success'> Successfully Saved</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
       <Button className='btn btn-secondary' onClick={props.onHide}>Ok</Button> 
       
       
      </Modal.Footer>
    </Modal>
  );
}

    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'> Click Proceed to add scan.</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
             <Button className='btn btn-secondary' onClick={props.onHide}>Not Now</Button> 
             <Button className='btn btn-primary btn-col' onClick={saveChanges}>Proceed</Button> 
             
            </Modal.Footer>
          </Modal>
        );
      }

      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
    
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
      const ErorValidatePopup=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Please Enter all mandatory fields </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={handleError}>Ok</Button> 
             
             
            
            </Modal.Footer>
          </Modal>
        );
      }
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

      const handleSave=() => {
        if(validate()){ 
        setSuccessShow(true);
        } else {
          setErrorValidate(true)
        }
      }

      const handleServiceChange= (data) => {

        setData( current => ({
            ...current , group: data
        }))
   }

   const handleUnitChange= (data) => {

    setData( current => ({
        ...current , country: data
    }))
  }

  // const handleRequest = (e)=> {
  //  const val = e.target.value
  //  const key = e.target.name

  //  setData((current) => ({
  //      ...current , [key] : val==='true'? true : val==='false'? false : null
  //  }))

  // }

  // const handleYear = (date)=> {
  //   setYear(date);
      
  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();
      
  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
  //     /*let dateString= DateExtracted.toString(); */
  //     setYearData(DateExtracted);
      
  //     }
  // }

  // const handleExpiry = (date)=> {
  //   setExpiry(date);
      
  //     let dateUpdated= date;
  //       if(date!==null){
  //     const offset = date.getTimezoneOffset();
      
  //     dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
  //     let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
  //     /*let dateString= DateExtracted.toString(); */
  //     setExpiryData(DateExtracted);
      
  //     }
  // }

  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});
  // scanname:'',
  //     scantype:'',
  //     scanmethod:'',
  //     scansarea:'',
  //    group:{ value:'', label:'' },
  //    cptcode:'',   
  //   description:'',
  //   country : { value:'', label:'' }
        
    if (!input["scanname"].trim() || input["scanname"].trim() ==="" ) {
      isValid = false;
      errors["scanname"] = "Please enter Scan Name";
    }

    if (typeof input["scanname"] !== "undefined") {
      var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern3.test(input["scanname"])) {
        isValid = false;
        errors["scanname"] = "Special Characters are not allowed";
      }
    }

    if (!input["scantype"] || input["scantype"]==="" ) {
      isValid = false;
      errors["scantype"] = "Please enter Scan Type";
    }
  
    if (!input.group || (input.group && !input.group.value) ) {
      isValid = false;
      errors["group"] = "Please select group";
    }
    if (!input["country"] || input["country"]==="" ) {
      isValid = false;
      errors["country"] = "Please Select country";
    }
 
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
 ;
 console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
    //  scanname:'',
    //   scantype:'',
    //   scanmethod:'',
    //   scansarea:'',
    //  group:{ value:'', label:'' },
    //  cptcode:'',   
    // description:'',
    // country : { value:'', label:'' }
  const dataToSend = {
    scan_name : data.scanname,
    scan_type : data.scantype,
    scan_method : data.scanmethod,
    scan_area :  data.scansarea,                                
   cpt_code: data.cptcode,
    country :       data.country.value,           
    group : data.group.value,
    description:data.description

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-scans/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.status==200) {
          setCompleteShow(true);
          setData({
            scanname:'',
      scantype:'',
      scanmethod:'',
      scansarea:'',
     group:{ value:'', label:'' },
     cptcode:'',   
    description:'',
    country : { value:'', label:'' }
         });}
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
    }

    else {
      setErrorValidate(true)
   }

}

    return (
      <>
        <h2 style={{marginTop:'0'}} className="title-of-page">Add Scan</h2>
          <div style={{maxHeight:'1000px'}} className='config-box fit-content'> 
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                  <span className='align-rental'>* Scan Name: </span>
                  <input type='text' className='form-control title-flex' name='scanname' value={data.scanname} onChange={handleTitle} />
                  {error.scanname ? <div className="error-validation-msg error-feature-inventory">{error.scanname}</div> : null}
                </div>

                <div className='flex-col justify-feature'>
                  <span className='align-rental'>* Scan Type : </span>
                  <input type='text' className='form-control title-flex' name='scantype' value={data.scantype} onChange={handleTitle} />
                  {error.scantype ? <div className="error-validation-msg error-feature-inventory">{error.scantype}</div> : null}
                </div>
                <div className='flex-col justify-feature'>
                  <span className='align-rental'>Scan Method : </span>
                  <input type='text' className='form-control title-flex' name='scanmethod' value={data.scanmethod} onChange={handleTitle} />
                </div>
                <div className='flex-col justify-feature'>
                  <span className='align-rental'> Scan Area: </span>
                  <input type='text' className='form-control title-flex' name='scansarea' value={data.scansarea} onChange={handleTitle} />

                </div>
                

                <div className='flex-row justify-feature'>
                  <span className='align-rental'>* Group: </span>
                  <Select
                    value={data.group}
                    onChange={(data,e)=>{handleServiceChange(data,e)
                    }}
                    options={group3}
                    name='group'
                    className="select-currency select-flex title-flex"
                  />
                  {error.group ? <div className="error-validation-msg error-feature-inventory">{error.group}</div> : null}
                </div>
            </div>
            <div className='text-center'>
              <button onClick={handleSave} className='btn btn-primary btn-col mb-2'>Save</button>  
            </div>
            {successShow ? 
              <SuccessPopUp
                show={successShow}
                onProceed={() =>  {setSuccessShow(false); props.modalClose();}}
                onHide={() =>  {
                  setSuccessShow(false);
                  props.modalClose() 
                }}
              /> 
              : null}

            {errorShow ? 
              <ErrorPopUp
                show={errorShow}
                onHide={() =>  {setErrorShow(false)}}
              /> 
            : null}

            {errorValidate ? 
              <ErorValidatePopup
                show={errorValidate}
                onHide={() =>  {setErrorValidate(false)}}
              /> 
            : null}

            {completeShow ? 
              <CompletePopUp
                show={completeShow}
                onHide={() =>  {
                  setCompleteShow(false);
                  props.modalClose();
                }}
              /> 
            : null}
            
          </div>
      </>
  )
}

export default AddScan;
